import React, { useEffect, useState } from "react";
import Layout from "../comp/layout";
import { useNavigate } from "react-router-dom";

import {
  faAngleDoubleRight,
  faCartArrowDown,
  faChartLine,
  faMoneyBill1,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import social1 from "../img/social1.webp";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast, ToastContainer } from "react-toastify";

const Socialmedia = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/plan/singleplan/Social-Media-Marketing"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/query/addquery",
        {
          title: "Social-Media-Marketing",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  const card2 = [
    {
      icon: faFacebook,
      name: "FACEBOOK",
      p1: "Profile optimization",
      p2: "Content creation",
      p3: "Content promotion",
      p4: "Audience targeting",
      p5: "Messenger marketing",
      p6: "Group management",
      p7: "Analytics and reporting",
      color: "#4267B2",
    },
    {
      icon: faInstagram,
      name: "INSTAGRAM",
      p1: "Profile optimization",
      p2: "Targeted Instagram Followers",
      p3: "Hashtag strategy",
      p4: "Instagram Image Sharing",
      p5: "Influencer partnerships",
      p6: "Run Instagram ads",
      p7: "Analytics and reporting",
      color: "#FA7E1E",
    },
    {
      icon: faLinkedin,
      name: "LINKEDIN",
      p1: "Profile optimization",
      p2: "LinkedIn Post sharing",
      p3: "Company Page Creation",
      p4: "Company Page Follower Increase",
      p5: "Relevant Group Joining",
      p6: "Analytics and reporting",
      color: "#3874E2",
    },
    {
      icon: faTwitter,
      name: "TWITTER",
      p1: "Profile optimization",
      p2: "Tweets Posting",
      p3: "Targeted Twitter Followers Increase",
      p4: "Retweets",
      p5: "#Hashtag Trend Research",
      p6: "Twitter Analytics Monitoring",
      color: "#3CCAF2",
    },
  ];
  return (
    <Layout title={"Oshn Brand Social-Media-Marketing"}>
      <ToastContainer />
      <div
        className="container-fluid"
        style={{ backgroundColor: "#112D52", color: "white" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h4 className="repeath4">
              BEST SOCIAL MEDIA MARKETING PACKAGES IN INDIA
            </h4>
            <p className="repeatp1">
              Looking for affordable social media marketing price in India?
              Xtreme Ads can help. We offer a range of SMM packages that are the
              basic need of businesses of all sizes. Our packages include
              everything you need to create and execute a successful social
              media marketing strategy, including:
            </p>
            <ul>
              <li>Content creation and scheduling</li>
              <li>Social media advertising</li>
              <li>Community management</li>
              <li>Analytics and reporting</li>
            </ul>
            <div className="">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn "
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                High Quality UI App Design
                {/* <br /> */}
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Increase Customer Experience
              </button>
            </div>
            <div className="mt-4">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Increase Customer Engagement
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Manage Brand Reputation
              </button>
            </div>
            <button
              style={{
                width: "fit-content",
                color: "white",
                padding: "0 10px",
              }}
              className="svg2 navbuttonn mt-5"
              onClick={() => {
                navigate("/grow-with-us");
              }}
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12 py-2">
            {/* <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/8yJQ_pYEh_A?si=pgrtwIpneo2z_I3b"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe> */}
            <img
              src={social1}
              alt="img"
              style={{ height: "100%", width: "100%", borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <FontAwesomeIcon />
        <div className="row">
          <div className="col-md-4 col-sm-12 px-5 text-center">
            <FontAwesomeIcon
              icon={faChartLine}
              style={{ fontSize: "45px", color: "#BF8E2F" }}
            />
            <h4 className="repeath6">INCREASED WEBSITE TRAFFIC</h4>
            <p className="repeatp1">
              Sharing your website content on social media platforms can drive
              more traffic to your website quickly and professionally.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5 text-center socialborder">
            <FontAwesomeIcon
              icon={faCartArrowDown}
              style={{ fontSize: "45px", color: "#BF8E2F" }}
            />
            <h4 className="repeath6">COST-EFFECTIVE ADVERTISING</h4>
            <p className="repeatp1">
              Compared to traditional advertising methods, social media
              marketing is a cost-effective way to promote your website content
              to a large audience.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5 text-center">
            <FontAwesomeIcon
              icon={faMoneyBill1}
              style={{ fontSize: "45px", color: "#BF8E2F" }}
            />
            <h4 className="repeath6">BIG SAVING</h4>
            <p className="repeatp1">
              Social media marketing can help you promote your big savings
              benefits to a wider audience, increasing your chances of
              attracting new customers.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-3">
          <div className="col-sm-12">
            <h1 className="repeath1  text-center">
              {" "}
              SOCIAL MEDIA PACKAGES OR PLANS
            </h1>
            <p className="repeatp1 text-center">
              At XtremeAds, we are committed to helping our clients achieve
              positive results with their social media marketing campaigns. Our
              social media packages are designed to help you enhance your social
              media presence and reach your target audience.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="repeath1 text-center">Our Plans</h1>
          </div>
        </div>
        <div className="row">
          {plan?.map((e, index) => (
            <>
              <div
                key={index}
                className="col-lg-4 col-md-6 col-sm-12 text-center"
              >
                <div key={index} className="youtubeseo button-container">
                  <div>
                    {" "}
                    <h1 className="button2 repeath1">{e.type}</h1>
                    <div
                      style={{
                        backgroundColor: "#112D52",
                        padding: "10px 0",
                        color: "white",
                      }}
                    >
                      <h3>{e.price}</h3>
                      <p>Per Month</p>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <b>{e.p1}</b>
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p2} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p3} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p4} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p5} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p6} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p7} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p8} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p9} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p10} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p11} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p12} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p13} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p14} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p15} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p16} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p17} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p18} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p19} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p20} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>

                  <button onClick={() => handleShow(e)} className="ytbtn">
                    I want to grow
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="https://oshnbrand.com/static/media/logo.fbd43d01225aac439771.jpg"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6>Social-Media-Marketing</h6>
                </div>
                <form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <div className="row text-center">
          <div className="col-sm-12">
            <h6 className="repeath1">
              TOP SOCIAL MEDIA MARKETING AND MANAGEMENT PLATFORMS
            </h6>
          </div>
        </div>
        <div
          className="row my-5 p-4"
          style={{ boxShadow: "0px 0px 20px lightgray" }}
        >
          {card2.map((e) => (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FontAwesomeIcon
                  style={{ color: e.color, fontSize: "40px" }}
                  icon={e.icon}
                />
                <h6 style={{ color: e.color }} className="repeath6">
                  {e.name}
                </h6>
                <ul>
                  <li>{e.p1}</li>
                  <li>{e.p2}</li>
                  <li>{e.p3}</li>
                  <li>{e.p4}</li>
                  <li>{e.p5}</li>
                  <li>{e.p6}</li>
                  <li>{e.p7}</li>
                </ul>
              </div>
            </>
          ))}
        </div>
        <div className="row my-3">
          <h1 className="repeath1">
            WHY CHOOSE US FOR BEST SOCIAL MEDIA MARKETING SERVICES IN INDIA?
          </h1>
        </div>
        <div className="row px-5">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "20px", color: "#777777" }}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#BF8E2F" }}
              />{" "}
              Create Social Media Profiles/Pages.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "20px", color: "#777777" }}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#BF8E2F" }}
              />
              Increased Engagement On Social Media.
            </p>
          </div>
        </div>
        <div className="row  px-5">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "20px", color: "#777777" }}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#BF8E2F" }}
              />{" "}
              Monitor and report on social media campaigns.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "20px", color: "#777777" }}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#BF8E2F" }}
              />{" "}
              Improved Trust And Following On Social Media.
            </p>
          </div>
        </div>
        <div className="row px-5">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "20px", color: "#777777" }}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#BF8E2F" }}
              />{" "}
              Prefect social media marketing strategy.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "20px", color: "#777777" }}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#BF8E2F" }}
              />{" "}
              Heightened Earnings From Social Media.
            </p>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1">Frequently Asked Questions</h1>

            <div
              class="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is the cost of social media marketing in India?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    The cost of social media marketing in India varies depending
                    on factors such as the scope of the project, the platforms
                    used, and the level of expertise required. On average,
                    prices can range from $145(INR 12,000)- $550(INR 45,000) in
                    India.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What does a social media marketing package include?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    A social media marketing package typically includes services
                    such as strategy development, content creation, community
                    management, social media advertising, analytics tracking,
                    and reporting. The specific services and the level of
                    customization can vary based on the needs and budget of the
                    client.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Which social media is best for marketing in India?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    The most popular social media platforms for marketing in
                    India are Facebook, Instagram, Twitter, LinkedIn, and
                    YouTube. The choice of platform(s) depends on the target
                    audience and the nature of the business or industry.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    What is the minimum budget for social media marketing?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    The minimum budget for social media marketing varies based
                    on the goals, scope, and complexity of the project. Some
                    agencies or consultants may offer packages starting at a few
                    thousand rupees per month, while others may require a
                    minimum investment of lakhs of rupees. Most businesses spend
                    $150 to $350 per day on social media advertisements.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Socialmedia;
