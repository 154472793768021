import React, { useState } from "react";
import axios from "axios";
import Layout from "../comp/layout";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    businessNumber: "",
    country: "",
    service: "",
    marketingConsent: false,
  });

  const countries = [
    "Country *",
    // (list of countries)
  ];

  const services = [
    "Service *",
    "Web Development",
    "Mobile App Development",
    "UI/UX Design",
    "Digital Marketing",
    "SEO Services",
    "Content Creation",
    "Other",
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/landing/addData",
        formData
      );
      console.log(response.data);
      alert("Form submitted successfully!");
    } catch (error) {
      console.error(error);
      alert("There was an error submitting the form.");
    }
  };

  return (
    <Layout>
      <div className="container-fluid margin2 landingmain">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                GROW WITH US
              </Link>{" "}
            </p>
            <h1 className="repeath1">
              Ready to connect?{" "}
              <span style={{ fontWeight: "900", color: "#FBAD18" }}>
                Let's do it.
              </span>
            </h1>
            <p className="repeatp1 py-4">
              If you’re ready to turn marketing into a growth engine for your
              business—or you’re here to learn about our culture, our thought
              leadership in the ABM industry, or your next career—you’ve come to
              the right place.
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon icon={faPhone} style={{ color: "#FBAD18" }} />{" "}
              Tel: +91 9015-93-7199
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon icon={faClock} style={{ color: "#FBAD18" }} />{" "}
              Office Hours:
            </p>
            <p className="repeatp1">9AM-5PM, Mon-Fri</p>
            <p className="repeatp1"> Mailing Address:</p>
            <p className="repeatp1">
              925B Peachtree St NE <br /> #313 <br /> Atlanta, GA 30309
            </p>
            <p className="repeatp1">
              Looking for the{" "}
              <span style={{ color: "#FBAD18" }}>customer support center?</span>
            </p>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                backgroundColor: "white",
                padding: "10px 20px",
                width: "80%",
              }}
            >
              <input
                type="text"
                name="firstName"
                placeholder="First Name *"
                value={formData.firstName}
                onChange={handleChange}
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name *"
                value={formData.lastName}
                onChange={handleChange}
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="text"
                name="company"
                placeholder="Company *"
                value={formData.company}
                onChange={handleChange}
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="email"
                name="email"
                placeholder="Business Email"
                value={formData.email}
                onChange={handleChange}
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="number"
                name="businessNumber"
                placeholder="Business Number *"
                value={formData.businessNumber}
                onChange={handleChange}
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <select
                name="service"
                value={formData.service}
                onChange={handleChange}
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              >
                {services.map((service, index) => (
                  <option key={index} value={service}>
                    {service}
                  </option>
                ))}
              </select>
              <div className="row">
                <div className="col-sm-1">
                  <input
                    type="checkbox"
                    name="marketingConsent"
                    checked={formData.marketingConsent}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-11">
                  <p style={{ fontSize: "10px", color: "gray" }}>
                    Yes, I'd like to receive marketing communications. By opting
                    in, you also agree to the storing and processing of your
                    personal data as described in our Privacy Statement.
                  </p>
                </div>
              </div>
              <button className="landingbtn" type="submit">
                LET'S TALK
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
