import React, { useState } from "react";
import "./headfoot.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../img/logo.jpg";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "./auth/Auth";
import axios from "axios";

const Header2 = () => {
  const [auth, setAuth] = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://oshnbrand.com:8090/api/admin/login",
        {
          email,
          password,
        }
      );
      if (res?.data?.success) {
        alert(res?.data?.message);
        setAuth({
          ...auth,
          admin: res.data.admin,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/dashboard/admin");
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };
  return (
    <div>
      <div className="container-fluid" style={{ backgroundColor: "black" }}>
        <div className="row">
          <div className="col-sm-12 topnav d-flex flex-wrap">
            <span className="topnav1">Email : info.usa@oshnbrand.com</span>
            <span className="topnav1">Email : info.india@oshnbrand.com</span>
            <span className="topnav1">India : +91 9015937199</span>
            <span className="topnav1">Blog</span>
            <span className="topnav1">|</span>
            <span className="topnav1">help center</span>
            <span className="topnav1">|</span>
            <span className="topnav2">
              {!auth.admin ? (
                <li style={{ listStyle: "none" }} className="topnav1">
                  <div>
                    <div>
                      <p
                        type="button"
                        className="loginbtn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Login
                      </p>
                      {/* Modal */}
                      <div
                        className="modal fade text-center"
                        style={{ backgroundColor: "transparent" }}
                        id="exampleModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <div
                            className="modal-content"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <div
                              className="modal-body text-dark"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div
                                className="container-fluid"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <form action="" className="form">
                                  <input
                                    required
                                    className="input"
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="E-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                  <input
                                    required
                                    className="input"
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                  <span className="forgot-password">
                                    <a href="/">Forgot Password ?</a>
                                  </span>
                                  <input
                                    className="login-button"
                                    type="submit"
                                    value="Sign In"
                                    onClick={submit}
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <li
                  className="topnav1 dropdown"
                  style={{ listStyle: "none", padding: "0 10px" }}
                >
                  <div className="dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      role="button"
                      id="navbarDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {auth?.admin?.name}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style={{ zIndex: "100000" }}
                    >
                      <li>
                        <NavLink
                          to={"/dashboard/admin"}
                          className="dropdown-item my-3"
                        >
                          Dashboard
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item"
                          onClick={logout}
                          to="/"
                        >
                          Logout
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </span>
          </div>
        </div>
      </div>
      <nav
        className="navbar navbar-expand-lg "
        style={{ backgroundColor: "black", color: "white" }}
      >
        <div className="container-fluid" style={{ backgroundColor: "black" }}>
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            alt="logo"
            style={{ height: "80px", width: "200px", cursor: "pointer" }}
          />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div id="menuToggle">
              <input id="checkbox" type="checkbox" />
              <label class="toggle" for="checkbox">
                <div class="bar bar--top"></div>
                <div class="bar bar--middle"></div>
                <div class="bar bar--bottom"></div>
              </label>
            </div>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <NavLink
                  to="/"
                  style={{ color: "white" }}
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Why Oshn Brand
                </NavLink>
                <ul className="dropdown-menu">
                  <div className=" p-3">
                    <Link
                      style={{
                        fontSize: "24px",
                        lineHeight: "35px",
                        fontWeight: "700",
                      }}
                    >
                      Why Oshn Brand
                    </Link>
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/customer-experience");
                      }}
                    >
                      Customer Experience{" "}
                      <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/professional-experience");
                      }}
                    >
                      Professional Services{" "}
                      <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/data-service");
                      }}
                    >
                      Data Services <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  style={{ color: "white" }}
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solution & Pricing
                </NavLink>
                <ul className="dropdown-menu">
                  <div className="container">
                    <div className="row ">
                      <div className="col-md-3 col-sm-12 px-4 sol">
                        <h4 style={{ color: "black", fontWeight: "500" }}>
                          Organic Awareness
                        </h4>
                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/local-seo");
                          }}
                        >
                          Local SEO
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>

                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/enterprise-seo");
                          }}
                        >
                          Enterprise SEO
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>

                        <h6
                          onClick={() => {
                            navigate("/youtube-seo");
                          }}
                          className="topnavh3"
                        >
                          YouTube SEO
                        </h6>

                        <h6
                          onClick={() => {
                            navigate("/online-reputation-management");
                          }}
                          className="topnavh3"
                        >
                          ORM
                        </h6>
                      </div>
                      <div className="col-md-3  col-sm-12 px-4 sol">
                        <h4 style={{ color: "black", fontWeight: "500" }}>
                          Paid Media Marketing
                        </h4>
                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/meta-ads");
                          }}
                        >
                          Meta Ads
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>

                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/google-ppc-ads");
                          }}
                        >
                          Google PPC Ads
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>

                        <h6
                          onClick={() => {
                            navigate("/linkedin-ads");
                          }}
                          className="topnavh3"
                        >
                          LinkedIn Ads
                        </h6>

                        <h6
                          onClick={() => {
                            navigate("/social-media-marketing");
                          }}
                          className="topnavh3"
                        >
                          Social Media Marketing
                        </h6>
                      </div>
                      <div className="col-md-3 col-sm-12 px-4  sol">
                        <h4 style={{ color: "black", fontWeight: "500" }}>
                          Development
                        </h4>
                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/website-development");
                          }}
                        >
                          Website Development
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>

                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/app-development");
                          }}
                        >
                          App Development
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>

                        <h6
                          onClick={() => {
                            navigate("/uiux-design");
                          }}
                          className="topnavh3"
                        >
                          UI/UX
                        </h6>

                        <h6
                          onClick={() => {
                            navigate("/software-development");
                          }}
                          className="topnavh3"
                        >
                          Software Development
                        </h6>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              {/* <li className="nav-item dropdown">
                <NavLink
                  style={{ color: "white" }}
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Case Study
                </NavLink>
                <ul className="dropdown-menu">
                  <div className=" p-3">
                    <h1 className="topnavh3">
                      Resource Hub <FontAwesomeIcon icon={faCaretRight} />
                    </h1>

                    <h1 className="topnavh3">
                      Past Webinars <FontAwesomeIcon icon={faCaretRight} />
                    </h1>

                    <h1 className="topnavh3">
                      Oshn Brand Blog <FontAwesomeIcon icon={faCaretRight} />
                    </h1>

                    <h1 className="topnavh3">
                      Customer Stories <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                  </div>
                </ul>
              </li> */}
              <li className="nav-item dropdown">
                <NavLink
                  style={{ color: "white" }}
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Our Story
                </NavLink>
                <ul className="dropdown-menu">
                  <div className=" p-3">
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/history");
                      }}
                    >
                      History <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/history");
                      }}
                    >
                      Culture & Values <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/history");
                      }}
                    >
                      Careers <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      onClick={() => {
                        navigate("/leadership");
                      }}
                      className="topnavh3"
                    >
                      Leadership <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      onClick={() => {
                        navigate("/partner");
                      }}
                      className="topnavh3"
                    >
                      Partners <FontAwesomeIcon icon={faCaretRight} />
                    </h1>

                    <h1
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                      className="topnavh3"
                    >
                      Contact Us <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                  </div>
                </ul>
              </li>
              <li>
                <button style={{ border: "none" }} className="navbutton svg">
                  +91 9015-93-7199
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header2;
