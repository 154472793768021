import React, { useState } from "react";
import Layout from "../comp/layout";
import google1 from "../img/linkedinads.webp";
import link1 from "../img/link1.png";
import link2 from "../img/link2.png";
import link3 from "../img/link3.png";
import link4 from "../img/link4.png";
import link5 from "../img/link5.png";
import link6 from "../img/link6.png";
import link7 from "../img/link7.png";
import link8 from "../img/link8.png";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";

const Linkedin = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/query/addquery",
        {
          title: "LinkedIn-Ads",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };
  const packageDetails = {
    title: "Professional",
    price: 99,
    duration: "month per user",
    feature1: "Send from 400 invites per month",
    feature2: "Follow-up Messaging Sequences of up to 2,000 per month",
    feature3: "Send up to 800 Open inmails",
    feature4: "Groups & Events - up to 1,000 per month",
  };
  return (
    <Layout title={"Oshn Brand LinkedIn-Ads"}>
      <ToastContainer />
      <div
        className="container-fluid"
        style={{ backgroundColor: "#004AAD", color: "white" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                LINKEDIN ADS
              </Link>{" "}
            </p>
            <h4 className="repeath1 text-light">
              Scale Your Team's Outreach & Get Them To Close Deals Faster
            </h4>

            <p className="repeatp1">
              Help your sales team find more leads faster with a LinkedIn
              automation tool that boosts their productivity
            </p>
            <div className="">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn "
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                High Quality UI App Design
                {/* <br /> */}
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Increase Customer Experience
              </button>
            </div>
            <div className="">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Increase Customer Engagement
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Manage Brand Reputation
              </button>
            </div>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn mt-4  text-light"
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={google1}
              alt="img"
              style={{ height: "470px", width: "100%", borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
      <div className="my-4">
        <h1 className="repeath1 text-center">Trusted By 10,000+ Users</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "20px 0",
            flexWrap: "wrap",
          }}
        >
          <img
            alt="img"
            src={link1}
            style={{ height: "50px", width: "150px" }}
          />
          <img
            alt="img"
            src={link2}
            style={{ height: "50px", width: "50px" }}
          />
          <img
            alt="img"
            src={link3}
            style={{ height: "50px", width: "140px" }}
          />
          <img
            alt="img"
            src={link4}
            style={{ height: "50px", width: "160px" }}
          />
          <img
            alt="img"
            src={link5}
            style={{ height: "50px", width: "160px" }}
          />
        </div>
      </div>
      <div className="container my-4">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">
              Fast track your Lead Generation & Outbound Sales
            </h1>
            <p className="repeatp3">
              Grow beyond the size of your sales team by eliminating redundant
              tasks from their workflow. Your team is probably booking meetings
              on Linkedin. However but with the extra output without the extra
              effort will increase meetings booked.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <h6 className="repeath6 text-center">Safe prospectings</h6>
            <h6 className="repeath6 text-center my-5 py-5">
              Centralised activity monitoring
            </h6>
            <h6 className="repeath6 text-center">Easy CRM integration</h6>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              src={link6}
              alt="img"
              style={{ height: "100%", width: "100%", borderRadius: "20px" }}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h2>
              Turn your LinkedIn to{" "}
              <span style={{ color: "#005589" }}>
                a Lead Converting Machine
              </span>
            </h2>
            <div className="row mt-4">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>Save time </span>
                </h4>
                <p className="repeatp1">
                  Save up to 4-8h a week per sales rep. Save on average 6 min
                  per prospect (Invite, 3 follow ups + CRM Data Entry) (up to 40
                  hours a month saved)
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>
                    Sort leads with positive replies
                  </span>
                </h4>
                <p className="repeatp1">
                  Top sellers are more likely to use sales intelligence tools –
                  and more likely to use LinkedIn
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>Safe</span>
                </h4>
                <p className="repeatp1">
                  Salesflow is fully-compliant with LinkedIn’s rules, with a
                  near 0% ban rate.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>Monitor </span>
                </h4>
                <p className="repeatp1">
                  Control and monitor different campaigns and activities with a
                  single, intuitive dashboard.
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn mt-4"
            >
              I Want To Grow
            </button>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6 col-sm-12">
            <h2>
              Turn your LinkedIn to{" "}
              <span style={{ color: "#005589" }}>
                a Lead Converting Machine
              </span>
            </h2>
            <p className="repeatp1">
              With 10+ years outreach experience, we are your reliable
              technology partner that delivers.{" "}
              <span style={{ color: "#005589" }}>
                90% of customers stay on average of 18 months
              </span>
              .
            </p>
            <div className="row mt-4">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>
                    Customer Success & Support
                  </span>
                </h4>
                <p className="repeatp1">
                  Dedicated support team to guide & onboard you through your
                  automation journey for success. We’re an established team with
                  technical expertise in LinkedIn & Cloud-based Automation.
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>
                    Easy To Use Platform You Will Love
                  </span>
                </h4>
                <p className="repeatp1">
                  Build your campaign in minutes with our intuitive & easy to
                  use interface. It’s simple, reliable & comprehensive
                  navigation to get things done.
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>
                    Growing International Team At Your Service
                  </span>
                </h4>
                <p className="repeatp1">
                  We’re not just a SaaS but a service too with a global team
                  servicing 10,000+ users across 120+ countries. The proof is in
                  the pudding, check out our case studies, testimonials and
                  reviews!
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={link7}
              alt="img"
              style={{ height: "100%", width: "100%", borderRadius: "20px" }}
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6 col-sm-12">
            <img
              src={link8}
              alt="img"
              style={{ height: "100%", width: "100%", borderRadius: "20px" }}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h2>
              Accelerate your{" "}
              <span style={{ color: "#005589" }}>Prospecting Results</span>
            </h2>
            <p className="repeatp1">
              Keep your team busy with closing more deals. Automate your lead
              generation process to help your sales team focus on their real
              job: Closing more leads. Make sure your team is logging all the
              data into the CRM.
            </p>
            <div className="row mt-4">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>Advanced Automation</span>
                </h4>
                <p className="repeatp1">
                  Personalize your campaigns for that human touch. Capture
                  keywords that make each connection unique & more personal for
                  more unique messaging outreach.
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>Unlimited Follow-ups</span>
                </h4>
                <p className="repeatp1">
                  Set up an unlimited amount of follow-up messages in all of
                  your campaigns. Nurture every lead and never miss another
                  sales opportunity again.
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>
                    Analyse & Create Reports
                  </span>
                </h4>
                <p className="repeatp1">
                  Grow leads faster using data-driven strategies Through your
                  Dashboard, you will understand why your lead generation is or
                  isn’t working and can generate better leads, faster.
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-1">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "white",
                    backgroundColor: "#005589",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-sm-11">
                <h4>
                  <span style={{ color: "#005589" }}>Organise</span>
                </h4>
                <p className="repeatp1">
                  Manage your leads with multiple data organization options like
                  our customizable inbox filters, unlimited webhooks & native
                  integration with your CRM.
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn mt-4"
            >
              I Want To Grow
            </button>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 col-sm-12 p-2 text-center">
            <div
              style={{
                padding: "20px",
                backgroundColor: "#4093FF",
                color: "white",
                borderRadius: "20px",
              }}
            >
              <p>{packageDetails.title}</p>
              <h1 className="megah1">
                <FontAwesomeIcon
                  icon={faDollarSign}
                  style={{ fontSize: "18px" }}
                />{" "}
                {packageDetails.price}
              </h1>
              <p>{packageDetails.duration}</p>
              <h4 className="mt-5">Want a try?</h4>
              <p>No Commitment. Upgrade, downgrade and cancel anytime</p>
              <button
                onClick={() => handleShow(packageDetails)}
                className="why navbuttonn mt-4 text-light"
              >
                I Want To Grow
              </button>
            </div>

            {selectedPackage && (
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                  <div className="login-box">
                    <div className="d-flex justify-content-around align-items-center my-2">
                      {" "}
                      <img
                        src="https://oshnbrand.com/static/media/logo.fbd43d01225aac439771.jpg"
                        alt="logo"
                        style={{ height: "50px", width: "130px" }}
                      />
                      <h6>LinkedIn-Ads</h6>
                    </div>
                    <form>
                      <div className="user-box">
                        <input
                          type="text"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label>Name</label>
                      </div>
                      <div className="user-box">
                        <input
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label>Email</label>
                      </div>
                      <div className="user-box">
                        <input
                          type="text"
                          required
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <label>Phone</label>
                      </div>
                      <div className="user-box">
                        <input
                          type="text"
                          required
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                        <label>Company</label>
                      </div>
                      <div className="user-box">
                        <Form.Group controlId="formBudget" className="mt-3">
                          <Form.Control
                            as="select"
                            value={budget}
                            onChange={(e) => setBudget(e.target.value)}
                          >
                            <option value="">Select your budget</option>
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>
                            <option value="high">High</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <center>
                        <button className="querybtn" onClick={handleSubmit}>
                          SEND
                          <span></span>
                        </button>
                      </center>
                    </form>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </div>
          <div className="col-md-8 col-sm-12 p-5">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Send from 400 invites per month</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Follow-up Messaging Sequences of up 2,000 per month</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Send up to 800 Open inmails</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Groups & Events - up 1,000 per month</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Advanced Reporting and Statistics</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Basic & Sales Navigator Compatibility</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Real-time AI-driven Lead management Inbox</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Team Management</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Import CSV for Hyper Personalised Targeting</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Zapier integration (two-way)</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Export data to a CSV</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>API Access</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Blacklist</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{
                        color: "white",
                        backgroundColor: "#005589",
                        padding: "5px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p>Native Integrations (Coming Soon)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Linkedin;
