import React from "react";
import Layout from "../comp/layout";
import "./why.css";
import why1 from "../img/customerbg.png";
import why2 from "../img/why2.webp";
import why3 from "../img/why3.webp";
import why4 from "../img/why4.webp";
import why5 from "../img/why5.webp";
import why6 from "../img/why6.webp";
import why7 from "../img/why7.webp";
import why8 from "../img/why8.webp";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WhyOshnBrand = () => {
  const card = [
    {
      p: "Our customer experience team is available along the way—from launching your first campaign to hitting your stride with our industry-leading playbooks—we’re in your corner.",
    },
    {
      p: "We’ve built a team of experts to help you grow. If you need an assist in creative, strategy, or execution, we’re ready to help.",
    },
    {
      p: "Our team of digital marketers are programmatic advertising experts who will make you creative perform at the highest levels.",
    },
    {
      p: "Our commitment to transparency means that we’ll tell what isn’t going well and help you strategize around how to fix it.",
    },
  ];
  const navigate = useNavigate();
  return (
    <Layout title={"Customer-experience"}>
      <div
        className="container-fluid margin"
        style={{ backgroundColor: "#104DB3", color: "white" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              {/* <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                WHY Oshn Brand?
              </Link>{" "} */}
              CUSTOMER EXPERIENCE
            </p>
            <h1 style={{ fontSize: "48px" }}>
              The revenue gap is real. Our customer experience team helps you
              close it.
            </h1>
            <p style={{ fontSize: "18px", fontWeight: "300" }}>
              Our experts build strategic programs designed to achieve your
              business goals. And increasing ROI is always a goal.
            </p>
            <button
              style={{
                width: "fit-content",
                color: "white",
                padding: "0 10px",
              }}
              className="svg2 navbuttonn"
              onClick={() => {
                navigate("/grow-with-us");
              }}
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={why1}
              alt="img"
              style={{ height: "80%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              alt="img"
              src={why2}
              style={{ height: "80%", width: "100%" }}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1 className="whyh">Win as a team, grow as a business</h1>
            <p className="whyp">
              Cross functional collaboration is the DNA of the customer
              experience. Bring together insights from across the business to
              drive retention and expansion – propelling the revenue flywheel
              forward.
            </p>
            <button
              style={{
                width: "fit-content",
                color: "white",
                padding: "0 10px",
              }}
              className="svg2 navbuttonn"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="whyh">We built our team for your ROI.</h1>
            <p className="whyp">
              The Oshn Brand platform puts immense power at your fingertips. But
              it’s our expertise and experience that helps you tap into that
              power. We speak your language, so we can help you find solutions
              to your problems.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              alt="img"
              src={why3}
              style={{ height: "80%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              alt="img"
              src={why4}
              style={{ height: "80%", width: "100%" }}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1 className="whyh">
              If your revenue engine isn’t firing on all cylinders, targeted
              account strategy is a tune-up.
            </h1>
            <p className="whyp">
              Changing budgets and economic uncertainties are real, and we have
              built Oshn Brand to help you drive better efficiency with every
              resource.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-sm-12">
            <p
              style={{ fontSize: "22px", fontWeight: "500", color: "#005589" }}
            >
              We provide a 360-approach that was designed (and has been proven)
              to drive revenue and ensure success. Consider us your partner in
              ROI.
            </p>
          </div>
        </div>
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-md-6 col-sm-12 p-2">
                <div
                  style={{
                    backgroundColor: "#E9F5FF",
                    padding: "20px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "300",
                      textAlign: "justify",
                    }}
                  >
                    {e.p}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1
              style={{
                fontSize: "58px",
                lineHeight: "62px",
                fontWeight: "500",
              }}
            >
              Let’s get that revenue gap closed
            </h1>
            <p
              style={{
                fontSize: "24px",
                lineHeight: "34px",
                fontWeight: "300",
              }}
            >
              We would love to show you how.
            </p>
            <div className="chat">
              <button className="chatnav">Chat with us</button>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: "19px", fontWeight: "800" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="whyh">Advertising Benchmark Report</h1>
            <p className="whyp">
              The Oshn Brand Advertising Benchmark Report was created to help
              ensure budget efficiency, give you confidence in your ads, and put
              you on a path to seeing those coveted upward trending lines on
              your performance graphs. We have some of the best B2B marketers in
              the universe using our platform and we know what works.
            </p>
            <button
              style={{
                width: "fit-content",
                color: "white",
                padding: "0 10px",
              }}
              className="svg2 navbuttonn"
            >
              Read the full report
            </button>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              alt="img"
              src={why8}
              style={{ height: "80%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-sm-12">
            <h4
              style={{
                fontSize: "48px",
                lineHeight: "54px",
                fontWeight: "500",
                color: "#091B31",
                textAlign: "center",
              }}
            >
              Dig a little deeper
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 p-2">
            <div
              style={{
                backgroundColor: "#E9F5FF",
                padding: "20px",
                height: "315px",
                // width: "330px",
              }}
              className="text-center"
            >
              <img
                style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                alt="img"
                src={why5}
              />
              <h6 style={{ height: "120px", paddingTop: "40px" }}>
                Array Customer Story
              </h6>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 p-2">
            <div
              style={{
                backgroundColor: "#E9F5FF",
                padding: "20px",
                height: "315px",
              }}
              className="text-center"
            >
              <img
                style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                alt="img"
                src={why6}
              />
              <h6 style={{ height: "120px", paddingTop: "40px" }}>
                Multi-Channel ABM Starter Pack
              </h6>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
              >
                Get the E-Book
              </button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 p-2">
            <div
              style={{
                backgroundColor: "#E9F5FF",
                padding: "20px",
                height: "315px",
                // width: "330px",
              }}
              className="text-center"
            >
              <img
                style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                alt="img"
                src={why7}
              />
              <h6 style={{ height: "120px", paddingTop: "40px" }}>
                Autodesk Rocks A Multi-Product ABM Strategy With Oshn Brand
              </h6>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WhyOshnBrand;
