import React from "react";
import Layout from "../comp/layout";
import { Link, useNavigate } from "react-router-dom";
// import soft2 from "../img/soft2.webp";
// import soft3 from "../img/soft3.webp";
import soft4 from "../img/soft4.webp";
import soft5 from "../img/soft5.webp";
import soft6 from "../img/soft6.webp";
import soft7 from "../img/soft7.webp";
import ChatForm from "../comp/LetsTalk";

const Software = () => {
  const navigate = useNavigate();
  const card = [
    { img: "/img/soft2.png" },
    { img: "/img/soft3.png" },
    { img: "/img/soft4.png" },
    { img: "/img/soft5.png" },
    { img: "/img/soft6.png" },
    { img: "/img/soft7.png" },
    { img: "/img/soft8.png" },
    { img: "/img/soft9.png" },
    { img: "/img/soft10.png" },
    { img: "/img/soft11.png" },
    { img: "/img/soft12.png" },
    { img: "/img/soft13.png" },
  ];
  const card2 = [
    {
      img: "/img/sof8.webp",
      name: "Angular",
    },
    {
      img: "/img/sof9.webp",
      name: "React Native",
    },
    {
      img: "/img/sof9.webp",
      name: "React",
    },
    {
      img: "/img/sof10.webp",
      name: "HTML",
    },
    {
      img: "/img/sof11.webp",
      name: "CSS",
    },
    {
      img: "/img/sof12.webp",
      name: "Bootstrap",
    },
  ];
  const card4 = [
    {
      img: "/img/sof13.webp",
      name: "MySQL",
    },
    {
      img: "/img/sof14.webp",
      name: "MS SQL",
    },
    {
      img: "/img/sof15.webp",
      name: "MongoDB",
    },
    {
      img: "/img/sof16.webp",
      name: "Redis",
    },
    {
      img: "/img/sof17.webp",
      name: "Elastic Search",
    },
    {
      img: "/img/sof18.webp",
      name: "GraphQL",
    },
  ];
  const card5 = [
    {
      img: "/img/sof19.webp",
      name: "AWS",
    },
    {
      img: "/img/sof20.webp",
      name: "GCP",
    },
    {
      img: "/img/sof21.webp",
      name: "Azure",
    },
    {
      img: "/img/sof22.webp",
      name: "Kubernetes",
    },
    {
      img: "/img/sof23.webp",
      name: "Docker",
    },
    {
      img: "/img/sof24.webp",
      name: "Nginx",
    },
    {
      img: "/img/sof25.webp",
      name: "Apache",
    },
  ];
  const card3 = [
    {
      img: "/img/sof1.webp",
      name: "ASP. Net",
    },
    {
      img: "/img/sof2.webp",
      name: "Java Spring",
    },
    {
      img: "/img/sof3.webp",
      name: "Node",
    },
    {
      img: "/img/sof4.webp",
      name: "Golang",
    },
    {
      img: "/img/sof5.webp",
      name: "PHP Laravel",
    },
    {
      img: "/img/sof6.webp",
      name: "Kibana",
    },
    {
      img: "/img/sof7.webp",
      name: "Django",
    },
  ];

  return (
    <Layout title={"Oshn Brand Software-Development"}>
      <ChatForm name="Software-Development" />
      <div className="container-fluid software py-5">
        <div className="row">
          <div className="col-md-6 col-sm-12 text-light pt-4">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                SOFTWARE DEVELOPMENT
              </Link>{" "}
            </p>
            <h1 className="repath1">Software Development</h1>
            <p className="repeatp3 ">
              Develop scalable, innovative, and user-centered digital
              applications tailored to your business needs.
            </p>
            <div className="">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn "
              >
                High Quality UI App Design
                {/* <br /> */}
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
              >
                Increase Customer Experience
              </button>
            </div>
            <div className="mt-4">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
              >
                Increase Customer Engagement
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#112D52",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
              >
                Manage Brand Reputation
              </button>
            </div>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn mt-4  text-light"
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12"></div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <h1 className="repeath1">
              Enabling enterprise software, modernizing legacy systems, and
              consolidating app portfolios.
            </h1>
            <p className="repeatp1">
              Our Custom Software Development services enable a{" "}
              <span style={{ color: "#005589", fontWeight: "500" }}>
                robust digital transformation
              </span>{" "}
              for your business. We design and develop{" "}
              <span style={{ color: "#005589", fontWeight: "500" }}>
                tailored-fit solutions
              </span>{" "}
              to deliver maximal outcomes within your existing technology
              ecosystem.
            </p>
            <p className="repeatp1">
              Whether you're rethinking your customer interactions or optimizing
              operational efficiency, our solutions{" "}
              <span style={{ color: "#005589", fontWeight: "500" }}>
                deliver tangible benefits
              </span>
              that your business needs to grow.
            </p>
            <p className="repeatp1">
              Our unique methodology includes a perfect blend of leveraging new
              technologies and adopting industry-leading best practices, with an
              aim to{" "}
              <span style={{ color: "#005589", fontWeight: "500" }}>
                provide the BEST in class
              </span>
              , always.
            </p>
          </div>
          <div className="col-md-4 col-sm-12"></div>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1">Trusted by businesses worldwide</h1>
          </div>
        </div>
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-lg-2 col-md-3 col-sm-12 my-5 text-center">
                <img
                  alt="img"
                  src={e.img}
                  style={{ height: "auto", width: "auto" }}
                />
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-md-5 col-sm-12 ">
            <h1 className="repeath1 softsticky">
              Explore our Custom Development expertise
            </h1>
          </div>
          <div className="col-md-7 col-sm-12 px-5">
            <h2>Custom Software Development</h2>
            <p className="repeatp3">
              We can assist you in developing intelligent digital applications
              that enhance end-user experiences while increasing operational
              efficiency. Design, develop, and implement a future-ready digital
              strategy with us.
            </p>
            <hr className="my-5" />
            <h2>Legacy Software Modernization</h2>
            <p className="repeatp3">
              Whether you need to reduce downtime or transition from monolith to
              microservices, rely on our expertise to redesign and recreate your
              current systems.
            </p>
            <hr className="my-5" />
            <h2>Custom Web App Development</h2>
            <p className="repeatp3">
              We maintain an always-ON focus on scalability and security to
              construct responsive web platforms that drive greater engagement
              and increased adoption rates.
            </p>
            <hr className="my-5" />
            <h2>Mobile App Development</h2>
            <p className="repeatp3">
              Partner with us to develop Mobile Apps that prioritize
              straightforward user onboarding, minimalist design, and
              self-guided workflows. We provide personalized experiences that
              span across multiple devices.
            </p>
            <hr className="my-5" />
            <h2>Full-Stack Software Development</h2>
            <p className="repeatp3">
              Utilizing our extensive expertise in JavaScript frameworks,
              jQuery, PHP, Node, Angular, Python, and more, we can assist in
              constructing a flexible and scalable backend, as well as a
              responsive frontend.
            </p>
            <hr className="my-5" />
            <h2>Cloud-Native Development</h2>
            <p className="repeatp3">
              Partner with us to harness the scale, elasticity, resiliency, and
              flexibility Cloud-native applications offer.
            </p>
            <hr className="my-5" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1 text-center">
              We’re solution partners trusted by global leaders
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px 0",
              }}
            >
              <img
                src={soft4}
                alt="img"
                style={{ height: "50px", width: "200px" }}
              />
              <img
                src={soft5}
                alt="img"
                style={{ height: "50px", width: "200px" }}
              />
              <img
                src={soft6}
                alt="img"
                style={{ height: "50px", width: "200px" }}
              />
              <img
                src={soft7}
                alt="img"
                style={{ height: "50px", width: "200px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid software2 p-5">
        <h1 className="repeath1 text-light">Why choose Kellton</h1>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <hr className="hr" />
              </div>
              <div className="col-md-7 col-sm-12 text-light">
                <h6 className="repeath6">Agile & Adaptive</h6>
                <p className="repeatp1">
                  We can rapidly increase bandwidth or access additional
                  expertise by bringing in talent that can ramp up within days
                  instead of months.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <hr className="hr" />
              </div>
              <div className="col-md-7 col-sm-12 text-light">
                <h6 className="repeath6">Dedicated OSDC</h6>
                <p className="repeatp1">
                  Using our offshore, onshore, nearshore, and hybrid OSDC
                  locations worldwide, we have successfully delivered over 250
                  projects.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <hr className="hr" />
              </div>
              <div className="col-md-7 col-sm-12 text-light">
                <h6 className="repeath6">Rapid Development</h6>
                <p className="repeatp1">
                  Rapid Development We can facilitate digital innovation through
                  fast prototyping, iterative development, design thinking, and
                  agile DevSecOps.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <hr className="hr" />
              </div>
              <div className="col-md-7 col-sm-12 text-light">
                <h6 className="repeath6">Flexible Engagement</h6>
                <p className="repeatp1">
                  Flexible Engagement Whether it's co-development, fixed price,
                  or T&M, choose the optimal working model that suits your
                  business needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-5"
        style={{ backgroundColor: "#D6DFF9" }}
      >
        <div className="row">
          <div className="col-sm-12">
            <h1 className="megah1 text-dark">Technology stack</h1>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-4 col-sm-12">
            <h1 className="repeath4">frontend</h1>
          </div>
          <div className="col-md-8 col-sm-12 d-flex justify-content-around flex-wrap">
            {card2.map((e) => (
              <>
                <div className="text-center">
                  <img
                    src={e.img}
                    alt="img"
                    style={{ height: "48px", width: "48px" }}
                  />
                  <h6 className="repeath6">{e.name}</h6>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-4 col-sm-12">
            <h1 className="repeath4">Backend</h1>
          </div>
          <div className="col-md-8 col-sm-12 d-flex justify-content-around flex-wrap">
            {card3.map((e) => (
              <>
                <div className="text-center">
                  <img
                    src={e.img}
                    alt="img"
                    style={{ height: "48px", width: "48px" }}
                  />
                  <h6 className="repeath6">{e.name}</h6>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-4 col-sm-12">
            <h1 className="repeath4">Databsse</h1>
          </div>
          <div className="col-md-8 col-sm-12 d-flex justify-content-around flex-wrap">
            {card4.map((e) => (
              <>
                <div className="text-center">
                  <img
                    src={e.img}
                    alt="img"
                    style={{ height: "48px", width: "48px" }}
                  />
                  <h6 className="repeath6">{e.name}</h6>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-4 col-sm-12">
            <h1 className="repeath4">Infrastructure</h1>
          </div>
          <div className="col-md-8 col-sm-12 d-flex justify-content-around flex-wrap">
            {card5.map((e) => (
              <>
                <div className="text-center">
                  <img
                    src={e.img}
                    alt="img"
                    style={{ height: "48px", width: "48px" }}
                  />
                  <h6 className="repeath6">{e.name}</h6>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Software;
