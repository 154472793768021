import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../img/slide1.png";
import slide2 from "../img/slide2.png";
import slide3 from "../img/slide3.png";
import slide4 from "../img/slide4.png";
import slide5 from "../img/slide10.png";
import slide6 from "../img/slide6.png";
import slide7 from "../img/slide7.png";
import slide8 from "../img/slide8.png";
import slide9 from "../img/slide9.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./style.css";

// import required modules
import { Pagination } from "swiper/modules";

export default function App() {
  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={slide1} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide2} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide3} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide4} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide5} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide6} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide7} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide8} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide9} alt="img" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
