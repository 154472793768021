import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../comp/layout";
import { useNavigate, Link } from "react-router-dom";
import gif from "../../img/intseo1.gif";
// import local2 from "../../img/local2.png";
// import local3 from "../../img/local3.png";
import local4 from "../../img/home21.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import LetsTalk from "../../comp/LetsTalk";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { faCertificate } from "@fortawesome/free-solid-svg-icons";

const IntSEO = () => {
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const [plan, setPlan] = useState([]);
  const navigate = useNavigate();
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/plan/singleplan/Local-SEO"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/query/addquery",
        {
          title: "Enterprise-SEO",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [numVisibleImages, setNumVisibleImages] = useState(5);

  const images = [
    {
      image: "/img/logo1.jpg",
    },
    {
      image: "/img/logo2.jpg",
    },
    {
      image: "/img/logo3.jpg",
    },
    {
      image: "/img/logo4.jpg",
    },
    {
      image: "/img/logo5.jpg",
    },
    {
      image: "/img/logo6.jpg",
    },
    {
      image: "/img/logo7.jpg",
    },
  ];
  const card = [
    {
      img: "/img/sco1.png",
      head: "Comprehensive Enterprise Keyword Research",
      p: "We delve deep into your Enterprise market to identify the most relevant keywords for your business. By understanding what your potential customers are searching for, we optimize your content to ensure you appear in Enterprise search results.",
    },
    {
      img: "/img/sco2.png",
      head: "Enterpriseized Content Creation",
      p: "Crafting compelling content tailored to your target audience is our specialty. We create engaging blog posts, landing pages, and website copy infused with Enterprise flavor to resonate with both Enterprise and international audiences.",
    },
    {
      img: "/img/sco3.jpeg",
      head: "Google My Business Optimization",
      p: "Improve your presence on Google Maps and Enterprise search results with our meticulous optimization of your Google My Business profile. From accurate business information to captivating visuals, we ensure your listing stands out to potential customers.",
    },
    {
      img: "/img/sco4.png",
      head: "Citation Building And Management",
      p: " Establishing a strong online presence across Enterprise directories and platforms is vital for Enterprise SEO success. We handle citation building and management to ensure consistent and accurate information about your business across the web.",
    },
    {
      img: "/img/sco5.jpeg",
      head: "Enterpriseized Link Building Strategies",
      p: "Building quality backlinks from Enterprise and international sources boosts your website’s authority and relevance in Enterprise search results. Our strategic approach to link building enhances your visibility and drives targeted traffic to your site.",
    },
    {
      img: "/img/sco6.png",
      head: "Performance Tracking And Reporting",
      p: "Transparent and insightful reporting is at the core of our Enterprise SEO services. We provide regular updates on your website’s performance, including keyword rankings, traffic insights, and conversion metrics, empowering you to make informed decisions for your business’s growth.",
    },
  ];
  const card2 = [
    {
      icons: "",
      head: "12+",
      p: "Years Of Experience",
    },
    {
      icons: "",
      head: "400+",
      p: "Successful Project",
    },
    {
      icons: "",
      head: "250+",
      p: "Our Trusted Clients",
    },
    {
      icons: "",
      head: "50+",
      p: "SEO Team",
    },
  ];

  const nextSlide = useCallback(() => {
    if (currentImageIndex + numVisibleImages < images.length) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentImageIndex(0);
    }
  }, [currentImageIndex, images.length, numVisibleImages]);

  const prevSlide = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    } else {
      setCurrentImageIndex(images.length - numVisibleImages);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1000) {
        setNumVisibleImages(4);
      } else if (window.innerWidth >= 768) {
        setNumVisibleImages(3);
      } else {
        setNumVisibleImages(1);
      }
    };

    handleResize();

    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    window.addEventListener("resize", handleResize);
    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, [currentImageIndex, nextSlide]);
  const card3 = [
    {
      img: "/img/sco7.png",
      head: "Dominate Enterprise Search Results",
      p: "With Enterprise SEO services from SEO Scientist, your business can secure top rankings in Enterprise search results, ensuring maximum visibility to potential customers in your area.",
    },
    {
      img: "/img/sco1.png",
      head: "Attract Nearby Customers",
      p: "Target customers in your vicinity who are actively searching for products or services like yours. Our Enterprise SEO strategies will drive qualified traffic to your website and foot traffic to your physical location.",
    },
    {
      img: "/img/sco6.png",
      head: "Enhance Brand Recognition",
      p: "Establish your brand as a Enterprise authority with a strong presence in your community. Through targeted optimization, we’ll boost your brand’s visibility across Enterprise search platforms.",
    },
    {
      img: "/img/sco8.jpeg",
      head: "Optimize Google My Business",
      p: "Utilize the power of Google My Business to its full potential. Our Enterprise SEO experts will optimize your listing, ensuring accurate information and encouraging positive reviews to attract more customers.",
    },
    {
      img: "/img/sco9.png",
      head: "Stay Ahead In Enterprise Markets",
      p: "Outshine your competitors in Enterprise search results and become the go-to choice for customers in your area. Our tailored Enterprise SEO strategies will help you maintain a competitive edge",
    },
    {
      img: "/img/sco10.png",
      head: "Drive Offline Conversions",
      p: "Convert online searches into offline actions. With our Enterprise SEO services, you’ll see an increase in store visits, appointments, and phone calls from nearby customers ready to engage with your business.",
    },
  ];
  const card4 = [
    {
      img: "/img/sco1.png",
      head: "More Than 300 +Websites On The 1st-Page Of Organic Search Results",
      p: "With a wealth of expertise in both SEO and online marketing, we bring invaluable insights and strategies to the table",
    },
    {
      img: "/img/sco2.png",
      head: "Focus On ROI",
      p: "We prioritize delivering a high return on investment by focusing on strategies that not just increase traffic but increase revenue too.",
    },
    {
      img: "/img/sco3.jpeg",
      head: "Affordable Pricing",
      p: "We offer competitive pricing plans tailored to fit your budget, making professional SEO services accessible to all kinds of businesses.",
    },
    {
      img: "/img/sco4.png",
      head: "Online Reporting 24/7",
      p: "Access to a 24/7 online report system, that gives you real-time data. You can track SEO results by yourself.",
    },
    {
      img: "/img/sco5.jpeg",
      head: "5+ Years Of Trust & Experience",
      p: "We have started our SEO firm in 2019. Search Engines are changing their algorithms and formulas quite often. We have the unique techniques and methods to keep up with those changes and to help us provide uninterrupted results for a long time",
    },
    {
      img: "/img/sco6.png",
      head: "Dedicated Account Manager",
      p: "We provide you with a dedicated account manager who will work with you to maximize your communication between all our SEO and web development departments.",
    },
  ];
  const card5 = [
    {
      h6: "Silver",
      h1: "$600",
      p1: "Upto 50 Keyword tracked weekly",
      p2: "50 pages optimised",
      p3: "Senior Management Present in Bi-Annual reviews",
      p4: "24 X 7 Customer Support",
      bgc: "white",
      color: "black",
      span: "/month",
      spanc: "gray",
    },
    {
      h6: "Gold",
      h1: "$900",
      p1: "Upto 75 Keyword tracked weekly",
      p2: "75 pages optimised",
      p3: "Senior Management Present in Bi-Annual reviews",
      p4: "24 X 7 Customer Support",
      bgc: "white",
      color: "black",
      span: "/month",
      spanc: "gray",
    },
    {
      h6: "Platinum plan",
      h1: "$1200",
      p1: "upto 100 Keyword tracked weekly",
      p2: "100 pages optimised",
      p3: "Senior Management Present in Bi-Annual reviews",
      p4: "24 X 7 Customer Support",
      bgc: "#005589",
      color: "white",
      span: "/month",
      spanc: "white",
    },
    {
      h6: "Daimond",
      h1: "$1800",
      p1: "Upto 150 Keyword tracked weekly",
      p2: "150 pages optimised",
      p3: "Senior Management Present in Bi-Annual reviews",
      p4: "24 X 7 Customer Support",
      bgc: "white",
      color: "black",
      span: "/month",
      spanc: "gray",
    },
  ];

  const card6 = [
    {
      img: "/img/local5.png",
    },
    {
      img: "/img/local6.png",
    },
    {
      img: "/img/local7.png",
    },
    {
      img: "/img/local8.png",
    },
    {
      img: "/img/local9.png",
    },
    {
      img: "/img/local10.png",
    },
    {
      img: "/img/local11.png",
    },
    {
      img: "/img/local12.png",
    },
    {
      img: "/img/local13.png",
    },
    {
      img: "/img/local14.png",
    },
    {
      img: "/img/local15.png",
    },
    {
      img: "/img/local16.png",
    },
  ];
  const card7 = [
    {
      img: "/img/sco1.png",
      head: "Website Audit",
      p: `Website audit analyzes site performance, identifying strengths, weaknesses, and areas for improvement, optimizing online presence.`,
    },
    {
      img: "/img/sco2.png",
      head: "E-Commerce SEO",
      p: `E-commerce SEO optimizes online stores for higher visibility, traffic, and sales, enhancing overall business performance.`,
    },
    {
      img: "/img/sco3.jpeg",
      head: "Enterprise SEO",
      p: `Enterprise SEO optimizes large-scale websites to enhance visibility, traffic, and ranking for extensive online growth.`,
    },
    {
      img: "/img/sco4.png",
      head: "Local SEO",
      p: `Local SEO optimizes online visibility for businesses within specific geographic locations, enhancing local search rankings.`,
    },
    {
      img: "/img/sco5.jpeg",
      head: "Penalty Recovery",
      p: `Penalty recovery service helps websites regain rankings and traffic after being penalized by search engines.`,
    },
    {
      img: "/img/sco6.png",
      head: "Content Writting",
      p: `Content writing service crafting engaging, informative, and optimized content tailored to your audience and business goals.`,
    },
    {
      img: "/img/sco7.png",
      head: "ASO",
      p: `ASO (App Store Optimization) improves app visibility, downloads, and user engagement through strategic optimization techniques.`,
    },
    {
      img: "/img/sco8.jpeg",
      head: "CRO",
      p: `CRO optimizes websites to boost conversions, maximizing the effectiveness of online traffic and user interactions.`,
    },
    {
      img: "/img/sco9.png",
      head: "ADS",
      p: `Comprehensive advertising solutions tailored to boost brand visibility and engagement across various platforms for optimal results.`,
    },
  ];
  return (
    <Layout title={"Enterprise SEO"}>
      <ToastContainer />
      <div>
        <div>
          {" "}
          <div className="container-fluid localscecontainer">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <p style={{ fontSize: "12px" }}>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: "transparent",
                    }}
                  >
                    HOME
                  </Link>{" "}
                  -{" "}
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "white",
                      backgroundColor: "transparent",
                    }}
                  >
                    ENTERPRISE-SEO
                  </Link>{" "}
                </p>
                <h1 style={{ fontSize: "40px" }}>
                  Grow Your Enterprise Presence With Professional Enterprise SEO
                  Services
                </h1>
                <p
                  className="py-2"
                  style={{ fontSize: "24px", fontWeight: "500" }}
                >
                  We Help You to Maximize Local Visibility to Increase
                  Enterprise Conversions with Effective Enterprise SEO Tactics
                </p>
                <div className="">
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn "
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Enterprise SEO Strategy
                  </button>{" "}
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Competetor Research
                  </button>
                </div>
                <div className="mt-2">
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Increase Your ROI
                  </button>{" "}
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Keywords Research
                  </button>
                </div>
                <button
                  style={{
                    width: "fit-content",
                    color: "white",
                    padding: "0 10px",
                  }}
                  className="svg2 navbuttonn mt-5"
                  onClick={() => {
                    navigate("/grow-with-us");
                  }}
                >
                  I Want To Grow
                </button>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={gif} alt="img" className="localseotop" />
              </div>
            </div>
          </div>
        </div>
        <div className="py-5">
          <h1 className="megah1 text-center">Trusted By Global Brands</h1>
          <div
            className="slider"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="prev"
              onClick={prevSlide}
              disabled={currentImageIndex === 0}
              style={{ border: "none" }}
            >
              &#10094;
            </button>
            <div style={{ display: "flex", width: "100%" }}>
              {images
                .slice(currentImageIndex, currentImageIndex + numVisibleImages)
                .map((image, index) => (
                  <div
                    key={index}
                    className="slide"
                    style={{ width: `${100 / numVisibleImages}%` }}
                  >
                    <img
                      style={{ width: "100%", height: "150px" }}
                      src={image.image}
                      alt={`Slide ${index}`}
                    />
                  </div>
                ))}
            </div>
            <button
              className="next"
              onClick={nextSlide}
              disabled={currentImageIndex + numVisibleImages >= images.length}
              style={{ border: "none" }}
            >
              &#10095;
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="row px-5"
            style={{ backgroundColor: "#112D52", color: "white" }}
          >
            <div className="col-sm-12">
              <h1 className="repeath1 text-light">
                Importance Of Enterprise Business SEO
              </h1>
              <p className="repeatp1">
                As the digital landscape evolves, Enterprise SEO has emerged as
                a cornerstone of online visibility and success for businesses of
                all sizes. At SEO Scientist, we understand the intricate
                dynamics of local search engine optimization and its profound
                impact on driving targeted traffic, enhancing brand awareness,
                and ultimately, boosting conversions.
              </p>
              <button
                type="button"
                className="btn btn-primary why navbuttonn "
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                I want to grow
              </button>
            </div>
          </div>
          <div className="row mt-5 px-5">
            <div className="col-sm-12">
              <p className="repeatp2" style={{ textAlign: "center" }}>
                What We Provide
              </p>
              <h1 className="repeath4" style={{ textAlign: "center" }}>
                How SEO Scientist Can Help You?
              </h1>
            </div>
          </div>
          <div
            className="row mt-5 px-5"
            style={{ backgroundColor: "#112D52", color: "white" }}
          >
            {card.map((e) => (
              <>
                <div className="col-lg-4 col-md-6 col-sm-12 p-3">
                  <div className="d-flex gap-3">
                    <img
                      src={e.img}
                      alt="img"
                      style={{ height: "50px", width: "50px" }}
                    />
                    <h1 className="repeath6">{e.head}</h1>
                  </div>
                  <p className="repeatp1">{e.p}</p>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-md-6 col-sm-12">
              <div className="row">
                {card2.map((e) => (
                  <>
                    <div className="col-md-6 col-sm-12">
                      <FontAwesomeIcon icon={e.icons} />
                      <h2>{e.head}</h2>
                      <p>{e.p}</p>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <p className="repeatp2">Need Marketing Help?</p>
              <h1 className="repeath4">
                Proven News Publisher SEO Strategies That Drive Traffic
              </h1>
              <p className="repeatp2">
                With our proven SEO strategies, we’re your go-to experts! Let
                our experienced team help you crush your competition.
              </p>
              <button
                type="button"
                className="btn btn-primary why navbuttonn "
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                I want to grow
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 p-2">
              <div>
                {/* <img
          src={local2}
          alt="img"
          style={{
            width: "100%",
            height: "150px",
            borderRadius: "20px 20px 0px 0px",
          }}
        /> */}
                <h6 className="pt-5 repeath6">
                  150.93% Organic Growth And Visibility
                </h6>
                <p className="py-2">
                  Achieved an outstanding 150.93% surge in organic sessions and
                  an impressive 107.35% growth in new users.
                </p>
                <button
                  type="button"
                  className="btn btn-primary why navbuttonn "
                  onClick={() => {
                    navigate("/grow-with-us");
                  }}
                >
                  I want to grow
                </button>
                <hr />
                <p style={{ fontWeight: "500" }}>
                  <span className="localspan">150.93 %</span> Organic Growth{" "}
                  <span className="localspan">107.35 %</span> New Users
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 p-2">
              <div>
                {/* <img
          src={local3}
          alt="img"
          style={{
            width: "100%",
            height: "150px",
            borderRadius: "20px 20px 0px 0px",
          }}
        /> */}
              </div>
              <h6 className="pt-5 repeath6">
                CourseMonster's Journey To 1st Page SERP Visibility
              </h6>
              <p className="py-2">
                Increased sessions, first-page SERP visibility, robust
                backlinks, enhanced domain authority, and improved user
                engagement.
              </p>
              <button
                type="button"
                className="btn btn-primary why navbuttonn "
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                I want to grow
              </button>
              <hr />
              <p style={{ fontWeight: "500" }}>
                <span className="localspan">18 %</span> Conversion Rate{" "}
                <span className="localspan">93.72 %</span> New Users
              </p>
            </div>
          </div>
        </div>
        <div className="container my-5">
          <h1 className="repeath1 text-center">
            What Are The Benefits Of Enterprise Business SEO?
          </h1>
          <div className="row">
            {card3.map((e) => (
              <>
                <div className="col-md-6 col-sm-12 p-2 my-3">
                  <div className="card3">
                    <div className="d-flex gap-3 align-items-center">
                      {" "}
                      <img
                        src={e.img}
                        alt="img"
                        style={{ height: "50px", width: "50px" }}
                      />
                      <h1 className="repeath6 text-center">{e.head}</h1>
                    </div>
                    <p className="repeatp1">{e.p}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="container my-5">
          <p style={{ textAlign: "center" }} className="repeatp2">
            Our SEO Agency
          </p>
          <h1 className="repeath4 text-center">Why Choose Our SEO Agency</h1>
          <p style={{ textAlign: "center" }}>
            With our expert SEO services, we’ll improve your website’s
            visibility, drive targeted Traffic, and increase conversions.
          </p>
          <div className="row">
            {card4.map((e) => (
              <>
                <div className="col-md-6 col-sm-12 p-2 my-3">
                  <div className="card3">
                    <div className="d-flex gap-3 align-items-center">
                      {" "}
                      <img
                        src={e.img}
                        alt="img"
                        style={{ height: "50px", width: "50px" }}
                      />
                      <h1 className="repeath6 text-center">{e.head}</h1>
                    </div>
                    <p className="repeatp1">{e.p}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img
                src={local4}
                alt="img"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="row my-2">
                <div className="col-sm-2">
                  <div className="localspan2">1</div>
                </div>
                <div className="col-sm-10">
                  <h4 className="repeath6">Initial Website Assessment</h4>
                  <p>
                    We start by conducting a thorough evaluation of your
                    website’s current performance, identifying areas for
                    improvement to drive more traffic and sales.
                  </p>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-sm-2">
                  <div className="localspan2">2</div>
                </div>
                <div className="col-sm-10">
                  <h4 className="repeath6">Competitor Analysis</h4>
                  <p>
                    Our team analyzes your competitors to understand their
                    strategies and identify opportunities to outperform them in
                    search rankings.
                  </p>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-sm-2">
                  <div className="localspan2">3</div>
                </div>
                <div className="col-sm-10">
                  <h4 className="repeath6">Customized Strategy Development</h4>
                  <p>
                    Based on our assessment and competitor analysis, we develop
                    a tailored SEO strategy designed to meet your specific
                    business goals and drive results.
                  </p>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-sm-2">
                  <div className="localspan2">4</div>
                </div>
                <div className="col-sm-10">
                  <h4 className="repeath6">Content Optimization</h4>
                  <p>
                    We optimize your website’s content to ensure it is engaging,
                    relevant, and aligned with your target audience’s needs,
                    helping to improve search engine rankings and drive organic
                    traffic.
                  </p>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-sm-2">
                  <div className="localspan2">5</div>
                </div>
                <div className="col-sm-10">
                  <h4 className="repeath6">Technical SEO Implementation</h4>
                  <p>
                    Our experts work on technical aspects of your website, such
                    as site speed, mobile responsiveness, and URL structure, to
                    enhance user experience and improve search engine
                    visibility.
                  </p>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-sm-2">
                  <div className="localspan2">6</div>
                </div>
                <div className="col-sm-10">
                  <h4 className="repeath6">
                    Continuous Monitoring And Adjustments
                  </h4>
                  <p>
                    We continuously monitor your website’s performance, making
                    adjustments to our strategy as needed to ensure ongoing
                    improvement and drive long-term success in driving sales and
                    increasing revenue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid my-4">
          <div className="row my-4">
            <div className="col-sm-12">
              <h1 className="repeath1 text-center">Our Enterprise-SEO Plans</h1>
            </div>
          </div>
          <div className="row">
            {plan?.map((e, index) => (
              <>
                <div
                  key={index}
                  className="col-lg-3 col-md-6 col-sm-12 text-center"
                >
                  <div key={index} className="youtubeseo button-container">
                    <div>
                      {" "}
                      <h1 className="button2 repeath1">{e.type}</h1>
                      <div
                        style={{
                          backgroundColor: "#112D52",
                          padding: "10px 0",
                          color: "white",
                        }}
                      >
                        <h1>{e.price}</h1>
                        <p>Per Month</p>
                      </div>
                    </div>

                    <p
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        paddingTop: "20px",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faCheck} />
                      {"  "}
                      <b>
                        Upto {e.p1} Keyword tracked weekly {e.videos}
                      </b>
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      {" "}
                      <FontAwesomeIcon icon={faCheck} />
                      <b>{e.p2} pages optimised </b>
                      <FontAwesomeIcon
                        icon={e.icon}
                        style={{ color: e.color, fontSize: "18px" }}
                      />
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      {" "}
                      <FontAwesomeIcon icon={faCheck} /> <b>{e.p3} </b>
                      <FontAwesomeIcon
                        icon={e.icon}
                        style={{ color: e.color, fontSize: "18px" }}
                      />
                    </p>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      {" "}
                      <FontAwesomeIcon icon={faCheck} /> <b>{e.p4} </b>
                      {/* <FontAwesomeIcon
                        icon={e.icon}
                        style={{ color: e.color, fontSize: "18px" }}
                      /> */}
                    </p>

                    <button onClick={() => handleShow(e)} className="ytbtn">
                      I want to grow
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
          {selectedPackage && (
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Body>
                <div className="login-box">
                  <div className="d-flex justify-content-around align-items-center my-2">
                    {" "}
                    <img
                      src="https://oshnbrand.com/static/media/logo.fbd43d01225aac439771.jpg"
                      alt="logo"
                      style={{ height: "50px", width: "130px" }}
                    />
                    <h6>Enterprise-SEO</h6>
                  </div>
                  <form>
                    <div className="user-box">
                      <input
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <label>Name</label>
                    </div>
                    <div className="user-box">
                      <input
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label>Email</label>
                    </div>
                    <div className="user-box">
                      <input
                        type="text"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <label>Phone</label>
                    </div>
                    <div className="user-box">
                      <input
                        type="text"
                        required
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                      <label>Company</label>
                    </div>
                    <div className="user-box">
                      <Form.Group controlId="formBudget" className="mt-3">
                        <Form.Control
                          as="select"
                          value={budget}
                          onChange={(e) => setBudget(e.target.value)}
                        >
                          <option value="">Select your budget</option>
                          <option value="low">Low</option>
                          <option value="medium">Medium</option>
                          <option value="high">High</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <center>
                      <button className="querybtn" onClick={handleSubmit}>
                        SEND
                        <span></span>
                      </button>
                    </center>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
        <div className="container text-center">
          <h1>Platforms We Optimize</h1>
          <p>
            We Optimize 10+ Platefroms For SEO And Search Engine For Better
            Rankings.
          </p>
          <div className="row">
            {card6.map((e) => (
              <>
                <div className="col-lg-3 col-md-4 col-sm-12 p-5">
                  <img
                    style={{ height: "100%", width: "100%" }}
                    src={e.img}
                    alt="img"
                  />
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Our Solutions
              </h1>
            </div>
          </div>
          <div className="row text-center">
            {card7.map((e) => (
              <>
                <div className="col-lg-4 col-md-6 col-sm-12  p-3 d-flex justify-content-center">
                  <div
                    style={{
                      boxShadow: "0px 0px 10px #1a1a1a",
                      // height: "470px",
                      // width: "300px",
                      padding: "20px",
                      border: "none",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      style={{ height: "100px", width: "100px" }}
                      src={e.img}
                      alt="img"
                    />
                    <h3>{e.head}</h3>
                    <p className="repeatp2" style={{ fontWeight: "400" }}>
                      {e.p}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <LetsTalk />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="repeath1">Frequently Asked Questions</h1>
              <p className="repeath6 mb-5">
                We Always Ready To Answer Your Any Query
              </p>
              <div
                class="accordion accordion-flush"
                id="accordionFlushExample"
                style={{ border: "none" }}
              >
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    style={{ fontWeight: "700", border: "none" }}
                  >
                    <button
                      style={{ fontWeight: "700" }}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      What Is Enterprise SEO, And Why Do I Need It?{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Enterprise SEO is the process of optimizing your online
                      presence to attract more customers from local searches.
                      It’s crucial for businesses targeting local audiences as
                      it helps increase visibility in local search results.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    style={{ fontWeight: "700", border: "none" }}
                  >
                    <button
                      style={{ fontWeight: "700" }}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      How Does Enterprise SEO Differ From Traditional SEO?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      While traditional SEO focuses on improving a website’s
                      visibility on a global scale, Enterprise SEO targets
                      specific geographic areas to attract nearby customers.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    style={{ fontWeight: "700", border: "none" }}
                  >
                    <button
                      style={{ fontWeight: "700" }}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      What Services Does SEO Scientist Offer For Enterprise SEO?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      We offer a range of Enterprise SEO services including
                      local keyword research, Google My Business optimization,
                      local citation building, online review management, and
                      local content creation.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    style={{ fontWeight: "700", border: "none" }}
                  >
                    <button
                      style={{ fontWeight: "700" }}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      How Long Does It Take To See Results From Enterprise SEO
                      Efforts?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      The timeline for seeing results can vary depending on
                      factors such as competition, industry, and the
                      effectiveness of the implemented strategies. Typically,
                      you may start seeing noticeable improvements within a few
                      months.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    style={{ fontWeight: "700", border: "none" }}
                  >
                    <button
                      style={{ fontWeight: "700" }}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      Will Enterprise SEO Benefit My Brick-And-Mortar Store?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Absolutely! Enterprise SEO is especially beneficial for
                      brick-and-mortar stores as it helps drive foot traffic by
                      increasing visibility in local search results.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2
                    class="accordion-header"
                    style={{ fontWeight: "700", border: "none" }}
                  >
                    <button
                      style={{ fontWeight: "700" }}
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      Do I Need A Physical Location To Benefit From Enterprise
                      SEO?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      While having a physical location can enhance Enterprise
                      SEO efforts, businesses without a physical storefront can
                      still benefit from Enterprise SEO through techniques like
                      local content creation and online review management.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default IntSEO;
