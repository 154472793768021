// import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import WhyOshnBrand from "./WhyBrandwo/CustomerEx";

import Landingpage from "./pages/Landingpage";
import Personal from "./WhyBrandwo/Personal";
import History from "./ourstory/History";
import LeaderShip from "./ourstory/LeaderShip";
import Partners from "./ourstory/Partners";
// import ContactUs from "./ourstory/ContactUs";
import Dataservice from "./WhyBrandwo/Dataservice";
import Website from "./pages/Product&dev/Website";
import Appdev from "./pages/Product&dev/Appdev";
import LocalSEO from "./pages/organicawareness/LocalSEO";
import IntSEO from "./pages/organicawareness/IntSEO";
import ReputationManagement from "./pages/ORM/ReputationManagement";

import MetaAds from "./pages/paidAds/MetaAds";
import UIUX from "./pages/Product&dev/UIUX";
import Socialmedia from "./pages/Socialmedia";
import Googleads from "./pages/Googleads";
import Linkedin from "./pages/Linkedin";
import Software from "./pages/Software";
import Youtubeseo from "./pages/Youtubeseo";
import AdminRoute from "./comp/auth/AdminRoute";
import AdminDashboard from "./comp/Admin/AdminDashboard";

import FilterQuery from "./comp/Admin/Quries";
import Products from "./comp/Admin/Product";
import AllUsers from "./comp/Admin/AllUsers";
import Plans from "./comp/Admin/Plans";
import AllQueries from "./comp/Admin/AllQueries";
import UpdateProfile from "./comp/Admin/profile";
import UpdatePlan from "./comp/Admin/UpdatePlans";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import AllUsersRoute from "./comp/Admin/AllusersRoute";
import ContactUs from "./ourstory/ContactUs";
import PopupForm from "./comp/LetsTalk";
// import Test from "./pages/test";

function App() {
  return (
    <>
      <PopupForm />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/customer-experience" element={<WhyOshnBrand />} />
        <Route path="/professional-experience" element={<Personal />} />
        <Route path="/data-service" element={<Dataservice />} />
        <Route path="/grow-with-us" element={<ContactUs />} />
        <Route path="/history/" element={<History />} />
        {/* <Route path="/our-story/#culture" element={<History />} /> */}
        <Route path="/leadership" element={<LeaderShip />} />
        <Route path="/partner" element={<Partners />} />
        <Route path="/contact-us" element={<Landingpage />} />
        <Route path="/website-development" element={<Website />} />
        <Route path="/app-development" element={<Appdev />} />

        <Route path="/local-seo" element={<LocalSEO />} />

        <Route path="/enterprise-seo" element={<IntSEO />} />
        <Route
          path="/online-reputation-management"
          element={<ReputationManagement />}
        />
        {/* <Route path="/individual-reputation-management" element={<IRM />} /> */}

        <Route path="/social-media-marketing" element={<Socialmedia />} />
        <Route path="/meta-ads" element={<MetaAds />} />
        <Route path="/google-ppc-ads" element={<Googleads />} />
        <Route path="/uiux-design" element={<UIUX />} />
        <Route path="/linkedin-ads" element={<Linkedin />} />
        <Route path="/software-development" element={<Software />} />
        <Route path="/youtube-seo" element={<Youtubeseo />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms&condition" element={<Terms />} />

        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/profile/:_id" element={<UpdateProfile />} />

          <Route path="admin/product" element={<Products />} />
          <Route path="admin/allusersRoute" element={<AllUsersRoute />}>
            <Route path="users" element={<AllUsers />} />
          </Route>
          {/* <Route path="admin/users" element={<AllUsers />} /> */}
          <Route path="admin/allqueries" element={<AllQueries />} />
          <Route path="admin/plan" element={<Plans />} />
          <Route path="admin/updateplan/:_id" element={<UpdatePlan />} />
          <Route path="admin/allquery/:title" element={<FilterQuery />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
