import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
// import "./Carousel.css";

const Carousel = ({ titles, descriptions }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    const newIndex = currentIndex === titles.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? titles.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="carousel">
      <button className="carousel__button" onClick={prevSlide}>
        <FontAwesomeIcon style={{ color: "#FBAD18" }} icon={faArrowLeft} />
      </button>
      <div className="carousel__content">
        <h1 className="repeath2">{titles[currentIndex]}</h1>
        <p className="repeatp3">{descriptions[currentIndex]}</p>
      </div>
      <div>
        <p className="repeatp1 mt-5">
          Click through our timeline to see what the journey has looked like.
        </p>
      </div>
      <button className="carousel__button" onClick={nextSlide}>
        <FontAwesomeIcon style={{ color: "#FBAD18" }} icon={faArrowRight} />
      </button>
    </div>
  );
};

export default Carousel;
