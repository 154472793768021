import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AddCollege = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [queries, setQueries] = useState([]);
  const [landing, setLanding] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchQueries = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/query/getquery"
      );

      // Filter queries by selected date
      const today = moment().format("YYYY-MM-DD");
      const filteredLandings = data?.data.filter(
        (query) => moment(query.createdAt).format("YYYY-MM-DD") === today
      );
      setQueries(filteredLandings);
    } catch (error) {
      console.log("Error fetching queries:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLanding = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/landing/getData"
      );
      const today = moment().format("YYYY-MM-DD");
      const filteredLandings = data?.data.filter(
        (landing) => moment(landing.createdAt).format("YYYY-MM-DD") === today
      );
      setLanding(filteredLandings);
    } catch (error) {
      console.log("Error fetching landings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [youtube, setYoutube] = useState([]);
  const youtubeseo = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/plan/allplans"
      );
      setYoutube(data?.data);
      console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllUsers = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/admin/allusers"
      );
      setUsers(data.users);
      console.log(data.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQueries();
    fetchLanding();
    youtubeseo();
    getAllUsers();
    //eslint-disable-next-line
  }, [isLoading]);

  return (
    <>
      <div
        style={{
          textShadow: "2px 2px 3px #1a1a1a",
          fontSize: "30px",
          fontWeight: "bold",
          marginBottom: "40px",
        }}
      >
        DASHBOARD
      </div>
      <div className="d-flex justify-content-evenly flex-wrap">
        <div class="card23">
          <div class="card-details">
            <p class="text-title">Today's Queries</p>
            <p class="text-length">{queries.length}</p>
          </div>
          <button
            class="card-button"
            onClick={() => {
              navigate("/dashboard/admin/allqueries");
            }}
          >
            More info
          </button>
        </div>
        <div class="card23">
          <div class="card-details">
            <p class="text-title">Today's Order</p>
            <p class="text-length">{landing.length}</p>
          </div>
          <button
            class="card-button"
            onClick={() => {
              navigate("/dashboard/admin/allqueries");
            }}
          >
            More info
          </button>
        </div>
        <div class="card23">
          <div class="card-details">
            <p class="text-title">Active Plan's</p>
            <p class="text-length">{youtube.length}</p>
          </div>
          <button
            class="card-button"
            onClick={() => {
              navigate("/dashboard/admin/plan");
            }}
          >
            More info
          </button>
        </div>
        <div class="card23">
          <div class="card-details">
            <p class="text-title"> All Users</p>
            <p class="text-length">{users.length}</p>
          </div>
          <button
            class="card-button"
            onClick={() => {
              navigate("/dashboard/admin/allusersRoute/users");
            }}
          >
            More info
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCollege;
