import React from "react";
import Layout from "../../comp/layout";
import orm from "../../img/orm2.gif";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAssistiveListeningSystems,
  faChartArea,
  faChartPie,
  faCheck,
  faLaptop,
  faPen,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faRev } from "@fortawesome/free-brands-svg-icons";
import ChatForm from "../../comp/LetsTalk";

const ReputationManagement = () => {
  const navigate = useNavigate();
  const card = [
    {
      icon: faLaptop,
      text: "Online Reputation Management",
    },
    {
      icon: faChartArea,
      text: "Crisis Management",
    },
    {
      icon: faAssistiveListeningSystems,
      text: "Social Media Monitoring & Listening",
    },
    {
      icon: faRev,
      text: "Reverse SEO",
    },
    {
      icon: faStar,
      text: "Review & Rating Management",
    },
    {
      icon: faPen,
      text: "Content Marketing",
    },
    {
      icon: faChartPie,
      text: "Controlled Digital PR",
    },
  ];
  const card2 = [
    {
      head: "#1",
      text: "Asia’s Top Best ORM Company",
    },
    {
      head: "98%",
      text: "Customer Satisfaction",
    },
    {
      head: "20+",
      text: "Industries We Worked For",
    },
    {
      head: "15+",
      text: "More Than 15 Years of Experience",
    },
    {
      head: "50+",
      text: "Highly Qualified ORM Expert Team",
    },
    {
      head: "25k+",
      text: "Negative Links Removed / Suppressed",
    },
  ];
  const card3 = [
    {
      img: "/img/rep1.png",
      h: "Bad Press, Blogs & Content",
      p: "We help the brands to clean and control the defamatory content with the help of online reputation management services.",
    },
    {
      img: "/img/rep2.png",
      h: "Reviews & Ratings",
      p: "Reviews plays an important role in customer’s buying decision hence we help the brands to manage the reviews with the help of review management services.",
    },
    {
      img: "/img/rep3.png",
      h: "Cleaning of Negative Videos",
      p: "A negative video can be painful for any brand when it comes to their online presence, we at Oshn Brand can manage them as well.",
    },
  ];
  const card4 = [
    {
      img: "/img/rep4.png",
      h: "Clean & Control First 3 Pages of Google",
      p: "For every brand it is utmost important to manage their online presence as the customer is going online and making its decision about the brands selection. It is required that brands should control their google searches so that their customers can see what a brand wants. At Oshn Brand, our reputation management consultants will help in managing the same.      ",
    },
    {
      img: "/img/rep5.png",
      h: "Removal / Suppression of Negative Content",
      p: "A single negative content can damage the reputation and hamper the business of a brand. ORM Services are the need of the hour to clean the negative content from the google search. These content can be in any form such as video, blogs , reviews , complaints etc. We at Oshn Brand helps the brand to clean the same and help the brand to control the damage.",
    },
    {
      img: "/img/rep6.png",
      h: "Crisis Management",
      p: "Our crisis management services are the best and unparalleled. When unlikely situations hamper the performance and outcomes of your business, you’ll find us by your side to control and recover it from damages.",
    },
    {
      img: "/img/rep7.png",
      h: "Social Media Monitoring and Listening",
      p: "Monitoring every online mention of your business is another thing at which we excel. Our online monitoring services for reputation management will direct your audience to have greater trust in your business.",
    },
    {
      img: "/img/rep8.png",
      h: "Google Autofills",
      p: "An immensely useful attribute, Google Autofills can have a massive impact on the reputation of your business. This feature can increase the chances of your success by displaying positive suggestions only.",
    },
    {
      img: "/img/rep9.png",
      h: "Strategic PR",
      p: "We are the leading PR professionals who will give your business the limelight that it deserves. We will help you earn and extensively enhance the online reputation of your business before your target audience.      ",
    },
  ];
  const card6 = [
    {
      img: "/img/local5.png",
    },
    {
      img: "/img/local6.png",
    },
    {
      img: "/img/local7.png",
    },
    {
      img: "/img/local8.png",
    },
    {
      img: "/img/local9.png",
    },
    {
      img: "/img/local10.png",
    },
    {
      img: "/img/local11.png",
    },
    {
      img: "/img/local12.png",
    },
    {
      img: "/img/local13.png",
    },
    {
      img: "/img/local14.png",
    },
    {
      img: "/img/local15.png",
    },
    {
      img: "/img/local16.png",
    },
  ];
  return (
    <Layout title={"Best ORM Service in India"}>
      <ChatForm name="ORM" />
      <div>
        {" "}
        <div
          className="container-fluid"
          style={{ backgroundColor: "#112D52", color: "white" }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-12 ">
              <p style={{ fontSize: "10px" }}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    backgroundColor: "transparent",
                  }}
                >
                  HOME
                </Link>{" "}
                -{" "}
                <Link
                  style={{
                    textDecoration: "none",
                    color: "white",
                    backgroundColor: "transparent",
                  }}
                >
                  ONLINE-REPUTATION-MANAGEMENT
                </Link>{" "}
              </p>
              <h1 style={{ fontSize: "40px" }}>
                Online Reputation Management Company
              </h1>
              <p
                className="py-2"
                style={{ fontSize: "20px", fontWeight: "400" }}
              >
                Experience of 15+ Years in more than 20+ industries with clients
                in the Asia, UAE, South America, Nigeria, Australia and Europe
              </p>
              <ul style={{ listStyle: "none", margin: "0", padding: "0" }}>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Repair Online Reputation
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Clean Negative Search
                  Results From Google
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Social Media Monitoring &
                  Listening
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Removal / Suppression of
                  Defamatory & Negative Links
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Removal / Suppression of
                  Negative Content
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Removal / Suppression of
                  Negative YouTube Videos
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Removal / Suppression
                  Negative & Fake Reviews
                </li>
                {/* <li>
                  <FontAwesomeIcon icon={faCheck} /> Reputation Monitoring of
                  New Negative Links
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Fix Competitor Defamation
                  Removal
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Ranked as Asia's top 10
                  best ORM & Digital Marketing Company
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> 350+ Happy Clients across
                  the globe
                </li> */}
              </ul>

              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                I Want To Grow
              </button>
            </div>
            <div className="col-md-6 col-sm-12">
              <img
                src={orm}
                alt="img"
                style={{
                  height: "85%",
                  width: "100%",
                  borderRadius: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">About Us</h1>
            <p className="repeatp1">
              Oshn Brand is a leading Online Reputation Management and Digital
              Marketing company based in New Delhi, India. With the team of Best
              Digital Marketing professionals, Oshn Brand help the companies to
              build, repair, manage and promote the Client’s brand globally.
              Being the Best Online Reputation Defenders and profile Defenders
              firm, We provide tailor made solutions to the clients after
              analyzing their needs and requirements. Oshn Brand is the Best ORM
              agency in Delhi, India.
            </p>
            <div className="row">
              {card.map((e) => (
                <>
                  <div className="col-md-6 col-sm-12 my-3">
                    <div className="row">
                      <div className="col-sm-2" style={{ color: "#005589" }}>
                        <FontAwesomeIcon
                          style={{ fontSize: "30px" }}
                          icon={e.icon}
                        />
                      </div>
                      <div className="col-sm-10">
                        <h1 style={{ color: "#005589" }} className="repeath6">
                          {e.text}
                        </h1>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="col-md-6 col-sm-12 text-center">
            <div className="row">
              {card2.map((e) => (
                <>
                  <div className="col-md-6 col-sm-12 text-center my-3">
                    <h1 style={{ color: "#005589" }} className="repeath1 py-1">
                      {e.head}
                    </h1>
                    <p>{e.text}</p>
                  </div>
                </>
              ))}
            </div>
            <button
              type="button"
              className="btn btn-primary why navbuttonn bg-light"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              I want to grow
            </button>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <h1 className="repeath1 my-4" style={{ color: "#005589" }}>
          We are specialized in managing
        </h1>
        <div className="row">
          {card3.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                <img
                  src={e.img}
                  alt="img"
                  style={{ height: "250px", width: "250px" }}
                />
                <h1 style={{ textAlign: "center" }} className="repeath4">
                  {e.h}
                </h1>
                <p>{e.p}</p>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container my-5">
        <h1 className="repeath1 text-center my-4" style={{ color: "#005589" }}>
          Online Reputation Management Services
        </h1>
        <p className="text-center">
          We are proud to announce ourselves as the top Reputation Management
          Consultants in the industry. Our cutting-edge Online Reputation
          Management Services cover all the aspects that can help in keeping you
          ahead of the curve.
        </p>
        <div className="row">
          {card4.map((e) => (
            <>
              <div className="col-md-6 col-sm-12 my-3">
                <div className="row">
                  <div className="col-sm-3">
                    <img
                      src={e.img}
                      style={{ height: "150px", width: "100%" }}
                      alt="img"
                    />
                  </div>
                  <div className="col-sm-9">
                    <h1 className="repeath6">{e.h}</h1>
                    <p className="repeatp2">{e.p}</p>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">Our Approachs</h1>
            <h1 className="repeath4" style={{ color: "#005589" }}>
              Step 1: Analysis
            </h1>
            <p className="repeatp2" style={{ fontWeight: "400" }}>
              The first step is to analyse the pain point of the client to
              understand the problem to plan accordingly
            </p>
            <h1 className="repeath4" style={{ color: "#005589" }}>
              Step 2: Strategy
            </h1>
            <p className="repeatp2" style={{ fontWeight: "400" }}>
              Once the pain point is analysed we will devise the strategy to
              provide the customized solutions.
            </p>
            <h1 className="repeath4" style={{ color: "#005589" }}>
              Step 3: Implementation
            </h1>
            <p className="repeatp2" style={{ fontWeight: "400" }}>
              Final step is to implement the plan to manage & control the online
              reputation of the client.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">FAQ’s</h1>
            <div
              class="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700", color: "#005589" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    How do you control the search results of Google?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    We use the reverse seo technique where we will be pushing
                    the positive content about the company/client in the form of
                    blogs, article etc. We would be creating the assets and
                    optimizing them to control the first 3 pages of google.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700", color: "#005589" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Are there any legal risks involved with this process?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    No, there are no legal risk involved in this process as we
                    do not cross any legal boundaries. We follow all the
                    guidelines given by google and the other platforms where the
                    content is hosted.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700", color: "#005589" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How do you remove any negative content or video from google?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    We use different acts like Copyright Act, Trademark
                    Infringement Act, Impersonation Act & many more depending
                    upon the content type and request the portals to remove the
                    same. Else, we suppress the link, content, videos from the
                    required keywords from the first 3 pages of Google search
                    results.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700", color: "#005589" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    What if the link which is removed comes back again and how
                    do you help?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    There are very less chances that once the link is removed
                    will come back again, as long as someone is doing
                    intentionally. As our model is a monthly retainer ship, so
                    in that case we will take care of the same by working on it
                    again but as the removals are not guaranteed so we will try
                    our best to resolve by suppressing the link beyond the first
                    3 pages of Google search results.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700", color: "#005589" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    What kind of content you guys remove?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    The removals are always a hit and trial method but we try
                    all the strategy to clean the same. We have worked on
                    videos, blogs, articles, pr’s , reviews & many more
                    platforms to get the negative out from the search results.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <h1>Clients</h1>

        <div className="row">
          {card6.map((e) => (
            <>
              <div className="col-lg-3 col-md-4 col-sm-12 p-5">
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={e.img}
                  alt="img"
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ReputationManagement;
