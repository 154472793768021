import React from "react";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  // faFacebook,
  faFacebookF,
  faInstagram,
  // faLinkedin,
  faLinkedinIn,
  faTwitter,
  // faYoutube,
} from "@fortawesome/free-brands-svg-icons";

// import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid">
        <div className="row text-center bg-primary text-light py-4">
          <div className="col-md-6 col-sm-12">
            <h1>Ready to get started</h1>
          </div>
          <div className="col-md-6 col-sm-12">
            {/* <button className="why navbuttonn">LET'S GO!</button> */}
            <button
              style={{
                width: "fit-content",
                color: "white",
              }}
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="svg2 navbuttonn"
            >
              Why Oshn Brand?
            </button>
          </div>
        </div>
        <div
          className="row pt-5"
          style={{ backgroundColor: "#122141", color: "white" }}
        >
          <div className="col-md-4 col-sm-12 text-center">
            <h1
              style={{
                fontSize: "33px",
                fontWeight: "500",
                fontFamily: "fantasy",
              }}
            >
              Oshn Brand
            </h1>
            <p style={{ fontSize: "18px", fontWeight: "700" }}>
              Subscribe to the Newsletter
            </p>
            <div className="my-5">
              <input
                type="email"
                placeholder="Email *"
                required
                style={{
                  padding: "20px",
                  border: "none",
                  borderRadius: "5px 0 0 5px",
                  width: "150px",
                }}
              />
              <button className="footerbtn">SUBMIT</button>
            </div>
            <div className="my-5">
              <button className="mx-2 footbtn">
                Lets Talk <FontAwesomeIcon icon={faCaretRight} />
              </button>
              <button className="mx-2 footbtn">
                Login <FontAwesomeIcon icon={faCaretRight} />{" "}
              </button>
            </div>
          </div>
          <div className="col-md-2 col-sm-12">
            <h3 className="footerhelp">Organic Awareness</h3>
            <ul className="footerul">
              <li
                onClick={() => {
                  navigate("/local-seo");
                }}
              >
                Local SEO
              </li>
              <li
                onClick={() => {
                  navigate("/enterprise-seo");
                }}
              >
                Enterprise SEO
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                YouTube SEO
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                E-commerce SEO
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-12">
            <h3 className="footerhelp"> Media Marketing</h3>
            <ul className="footerul">
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                Meta Ads
              </li>

              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                Google PPC Ads
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                LinkedIn Ads
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                YouTube Ads
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-12">
            <h3 className="footerhelp">Development</h3>
            <ul className="footerul">
              <li
                onClick={() => {
                  navigate("/website-development");
                }}
              >
                Website Development{" "}
              </li>
              <li
                onClick={() => {
                  navigate("/app-development");
                }}
              >
                App Development
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                Software Development
              </li>
              <li
                onClick={() => {
                  navigate("/uiux-design");
                }}
              >
                UI/UX
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-12">
            <h3 className="footerhelp">Social Media</h3>
            <ul className="footerul">
              {/* <li>
                <FontAwesomeIcon icon={faYoutube} /> YouTube
              </li> */}
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faLinkedinIn} /> LinkedIn
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faInstagram} /> Instagram
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faFacebookF} /> FaceBook
              </li>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                <FontAwesomeIcon icon={faTwitter} /> Twitter
              </li>
            </ul>
          </div>
        </div>
        <div
          className="row bg-dark"
          style={{ backgroundColor: "#122141", color: "white" }}
        >
          <div className="col-md-3 col-sm-12">
            {/* <p
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <FontAwesomeIcon icon={faLinkedin} style={{ height: "30px" }} />{" "}
              Join the ABM conversation
            </p> */}
            <p style={{ fontSize: "12px" }}>© 2024 Oshn Brand Software, Inc.</p>
          </div>
          <div className="col-md-9 col-sm-12">
            <ul className="footerlinks">
              <li
                onClick={() => {
                  navigate("/terms&condition");
                }}
              >
                Terms of Service
              </li>
              <li>|</li>
              <li
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </li>
              <li>|</li>
              <li>GDPR</li>
              <li>|</li>
              <li>CCPA</li>
              <li>|</li>
              <li>Security</li>
              <li>|</li>
              <li>Sitemap</li>
              <li>|</li>
              <li>Do Not Sell or Share My Personal Info</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
