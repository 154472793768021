import React from "react";
import Layout from "../../comp/layout";
import { useNavigate } from "react-router-dom";
import uiux1 from "../../img/uiux1.webp";
import uiux2 from "../../img/uiux2.webp";
import uiux3 from "../../img/uiux3.gif";
// import LetsTalk from "../../comp/LetsTalk";
import ChatForm from "../../comp/LetsTalk";

const UIUX = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/uiux2.webp",
      h: "Web App design",
      p: "Experience exceptional UI and UX design services for Web Apps. Our online application interface designs are meticulously honed to captivate and engage target audiences while ingeniously solving critical business challenges.",
    },
    {
      img: "/img/uiux3.webp",
      h: "Mobile App design",
      p: "Our team is well-versed in designing an intuitive, clean, and seamlessly navigable UI/UX design suitable for mobile applications on prime mobile OS platforms. With our designs, you can rest assured about amplified engagement and extraordinary end-user experience.",
    },
    {
      img: "/img/uiux4.webp",
      h: "Website Design",
      p: "Elevate web experiences with our UI/UX Design & Consulting services. We’re masters of visual storytelling, weaving narratives into websites that educate and motivate. We craft websites fine-tuned to meet the ever-changing expectations of modern web users.",
    },
    {
      img: "/img/uiux5.webp",
      h: "UX for Startups",
      p: "Visual branding is vital for startups’ success. Our UX & UI design expertise helps build remarkable brands. Exploring new digital products? Count on us for MVP designs and high-fidelity prototypes.",
    },
    {
      img: "/img/uiux6.webp",
      h: "Enterprise UX",
      p: "Experience the power of Innovative UI UX Design Services. Our Enterprise UX solutions excel in efficiency for administrators and end-users alike. Moreover, we’re experts in making cross-platform compatible UI designs.",
    },
    {
      img: "/img/uiux7.webp",
      h: "UX Research",
      p: "Get out-of-the-box data-driven UI/UX design services with us at Design Studio. Our data analysis approach helps uncover end-user needs and objectives. The obtained insights from our extensive UI/UX research constantly empower our services.",
    },
    {
      img: "/img/uiux8.webp",
      h: "UX Audit",
      p: "Our design team conducts comprehensive design audits using A/B testing, back-office operations, and analyzing target audiences, among others. These audit findings drive the evolution of customer journey maps for our clients’ products.",
    },
    {
      img: "/img/uiux9.webp",
      h: "Branding Design",
      p: "Take your brand identity to another level with our UI/UX design services. Our branding and graphic design expertise crafts distinctive visual representations for your business, encompassing captivating marketing materials.",
    },
    {
      img: "/img/uiux10.webp",
      h: "Web Redesign",
      p: "By collaborating with us, you can harness the art of visual storytelling in UI design services. Our custom website designs are an amalgamation of information and inspiration strategically tailored to meet the evolving needs of new-age internet users.",
    },
  ];
  const card2 = [
    {
      img: "/img/uiux11.webp",
      h: "Research & Analysis",
    },
    {
      img: "/img/uiux12.webp",
      h: "Ideation",
    },
    {
      img: "/img/uiux13.webp",
      h: "Wireframe",
    },
    {
      img: "/img/uiux14.webp",
      h: "Visual Style/Prototype",
    },
    {
      img: "/img/uiux15.webp",
      h: "Usability Testing",
    },
    {
      img: "/img/uiux16.webp",
      h: "Design",
    },
    {
      img: "/img/uiux-17.webp",
      h: "Test and Deliver",
    },
  ];
  const card3 = [
    {
      img: "/img/uiux18.webp",
      head: "Healthcare",
      text: "Our UI/UX design services can be customized to provide specialized and engaging web designs benefitting your needs. Our indispensable tools are suitable for patients and physicians alike.",
      bgc: "#4E55B4",
    },
    {
      img: "/img/uiux19.webp",
      head: "Fintech",
      text: "Our user-centered design (UCD) approach empowers numerous banking and finance firms to stand out in the competitive landscape, delivering digital products that utilize cutting-edge technology. Stay ahead in financial services with our innovative UI UX web design services.",
      bgc: "#225AAF",
    },
    {
      img: "/img/uiux20.webp",
      head: "Health & Fitness",
      text: "Navigate the digital health revolution with us backing your services. We can design a responsive and engaging web design for your health and fitness brand to make it easily reachable for your customers and avail of your services.",
      bgc: "#754EB4",
    },
    {
      img: "/img/uiux21.webp",
      head: "EdTech",
      text: "We create engaging e-learning interfaces, facilitating interactive learning experiences, personalized content delivery, and student-teacher collaboration, revolutionizing education.",
      bgc: "#A04EB4",
    },
    {
      img: "/img/uiux22.webp",
      head: "Fashion & Design",
      text: "Design Studio designs digital solutions in terms of UI/UX design services to help you captivate audiences, showcasing products beautifully and enabling effortless online shopping experiences.",
      bgc: "#B44E97",
    },
    {
      img: "/img/uiux23.webp",
      head: "Food & Restaurant",
      text: "Deliver unmatched convenience to your food business with premium UI design solutions. Enhance your food-based startup’s standing by providing seamless digital experiences through our expert mobile and web app design team.",
      bgc: "#D04B93",
    },
    {
      img: "/img/uiux24.webp",
      head: "Lawyers & Attorneys",
      text: "Fuel your practice with conversion-centric UI UX web design services. Invest in digital products that generate leads and craft unforgettable user experiences. Let us transform your online presence for unparalleled success.",
      bgc: "#1D97B1",
    },
    {
      img: "/img/uiux25.webp",
      head: "Retail & Ecommerce",
      text: "Your e-commerce excellence can be amplified with our UX/UI design expertise. Dive into a world of visually captivating and user-friendly online retail experiences. Take advantage of our specialized services to boost conversions and foster customer loyalty.",
      bgc: "#4E9CB4",
    },
    {
      img: "/img/uiux26.webp",
      head: "Non-Profit Organizations",
      text: "Forge the future of your NGO with our innovative mobile app UI UX design services. Connect and mobilize your NGO’s target audience, promoting a lasting impact and global reach.",
      bgc: "#3CA781",
    },
  ];
  return (
    <Layout title={"Oshn Brand UI/UX-Design"}>
      <ChatForm name="UIUX" />
      <div
        className="container-fluid"
        style={{ backgroundColor: "#A837C0", color: "white" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12 pt-4">
            <h1 className="repeath1 text-light">
              UX/UI Design & Consulting Services
            </h1>
            <p className="repeatp1 py-4">
              Step into the world of design excellence with Design Studio! With
              years of experience in designing and consulting and a successful
              track record, we can be your trusted partner in crafting
              remarkable UI and UX designs. We specialize in translating your
              brand’s vision into tangible outcomes through state-of-the-art UI
              and UX design services. Our expertise spans across diverse
              industries, including healthcare, travel, and hospitality,
              allowing us to deliver tailored digital solutions relatable to
              your audience.
            </p>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn  mt-5 text-light"
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={uiux3}
              alt="img"
              style={{ height: "100%", width: "100%", borderRadius: "20px" }}
            />
          </div>
        </div>
      </div>
      <div
        className="container-fluid margin"
        style={{ backgroundColor: "#112D52", color: "white" }}
      >
        <div className="row ">
          <div className="col-md-6 col-sm-12 pt-5">
            <h1 className="whyh text-light pt-5">
              Covering All Phase of UX/UI
            </h1>
            <p className="whyp">
              Covering All Phase of UX/UI Design Studio is a UX and UI design
              services company that helps you embark on a transformative design
              journey. We don’t just collaborate with clients; we co-design
              excellence from start to finish with precision and perfection. We
              optimize every phase of your project’s life cycle with passion, as
              reflected in our holistic UI/UX design consulting services.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              alt="img"
              src={uiux1}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row text-center">
          {card.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                <img
                  src={e.img}
                  alt="img"
                  style={{ height: "157px", width: "207px" }}
                />
                <h1 className="repeath4 text-center">{e.h}</h1>
                <p className="repeatp1">{e.p}</p>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container-fluid">
        <div className="row" style={{ backgroundColor: "#005589" }}>
          <div className="col-md-6 col-sm-12">
            <img
              src={uiux2}
              style={{ height: "100%", width: "100%" }}
              alt="img"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1
              className="repeath1 pt-5"
              style={{ fontSize: "33px", color: "white" }}
            >
              Need Dedicated designer or a Team?
            </h1>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn  mt-3 text-light"
            >
              Know More
            </button>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-sm-12 text-center">
            <h1 className="megah1">Our UI and UX Design Service Process</h1>
            <p className="repeatp3 text-center">
              Our process begins with understanding your business goals and user
              needs. We then create wireframes, mockups and prototypes to
              visually communicate the design solution. We work closely with you
              through the design process, testing and making adjustments to
              ensure the final product exceeds your expectations.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          backgroundColor: "#005589",
        }}
      >
        {card2.map((e) => (
          <>
            <div
              className=" text-center"
              style={{ height: "273px", width: "277px" }}
            >
              <img
                src={e.img}
                alt="img"
                style={{
                  height: "162px",
                  width: "162px",
                }}
              />
              <h1 className="repeath4 text-center text-light">{e.h}</h1>
            </div>
          </>
        ))}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1">Industries Where We Create Excellence</h1>
            <p className="repeatp3">
              The digital ecosystem is a playground where companies from all
              types of industries can co-exist. We aim to be the UI/UX design
              agency that helps your company thrive in this ecosystem and build
              a brand identity that ensures maximum engagement.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {card3.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 p-3">
                <div
                  className="p-3 py-5"
                  style={{
                    backgroundColor: e.bgc,
                    borderRadius: "20px",
                    color: "white",
                  }}
                >
                  <img
                    style={{
                      height: "91px",
                      width: "82px",
                      padding: "10px",
                    }}
                    src={e.img}
                    alt="img"
                  />
                  <h1 className="py-4">{e.head}</h1>
                  <p>{e.text}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      {/* <LetsTalk /> */}
      <div>
        <div className="container-fluid">
          <div className="row my-5">
            <div className="col-md-6 col-sm-12">
              <h1 className="megah1">Quality in Every Pixel: Why Choose Us?</h1>
            </div>
            <div className="col-md-6 col-sm-12">
              <p className="repeatp3">
                We have a multi-disciplinary approach to designing digital
                products. Teaming up with Design Studio’s UI/UX design team
                means gaining access to a remarkable team of success-driven
                design engineers.
              </p>
            </div>
          </div>
          <div className="row px-5 py-5 bg">
            <div className="col-md-5 col-sm-12">
              <h1 className="repeath1 text-light">Personalized Approach</h1>
            </div>
            <div className="col-md-7 col-sm-12">
              <p className="repeatp3">
                Our team does not believe in one-size-fits-all enterprise UX
                design services. They proactively assess and customize solutions
                to match the needs of each client perfectly.
              </p>
            </div>
          </div>
          <div className="row px-5 py-5 bg">
            <div className="col-md-5 col-sm-12">
              <h1 className="repeath1 text-light">Flexible Team Size</h1>
            </div>
            <div className="col-md-7 col-sm-12">
              <p className="repeatp3">
                Adapting to design trends demands technical mastery is our
                fortey. We have versatile front-end and back-end development
                teams delivering innovative designs. They push their boundaries
                and build dream designs swiftly and continuously.
              </p>
            </div>
          </div>
          <div className="row px-5 py-5 bg">
            <div className="col-md-5 col-sm-12">
              <h1 className="repeath1 text-light">Date Meets Design</h1>
            </div>
            <div className="col-md-7 col-sm-12">
              <p className="repeatp3">
                At Design Studio, customer data drives excellence. We tirelessly
                analyze end-user pain points by infusing objectivity into every
                design and digital solution.
              </p>
            </div>
          </div>
          <div className="row px-5 py-5 bg">
            <div className="col-md-5 col-sm-12">
              <h1 className="repeath1 text-light">Complete Collaboration</h1>
            </div>
            <div className="col-md-7 col-sm-12">
              <p className="repeatp3">
                Whatever we do, we do collaboratively. We co-create projects,
                comprehending clients’ needs and tailoring services to their
                unique requirements. From ideation and design to wireframes,
                everything is shaped as per your objectives and brand
                requirements.
              </p>
            </div>
          </div>
          <div className="row px-5 py-5 bg">
            <div className="col-md-5 col-sm-12">
              <h1 className="repeath1 text-light">Create Functional Art</h1>
            </div>
            <div className="col-md-7 col-sm-12">
              <p className="repeatp3">
                With our UI/UX design services, your imagination is designed to
                reality. By combining expert technical skills and artistic
                innovation, we design digital solutions for brands like never
                before.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UIUX;
