import React, { useState, useEffect } from "react";
import Layout from "../comp/layout";
import { Link } from "react-router-dom";
import data1 from "../img/dataservice.png";
import data2 from "../img/data2.png";
import data3 from "../img/data3.png";
import why5 from "../img/why5.webp";
import why6 from "../img/why6.webp";
import why7 from "../img/why7.webp";
import data7 from "../img/data7.png";
import data4 from "../img/data4.png";
import data5 from "../img/data5.png";
import data6 from "../img/data6.png";
// import home10 from "../img/home10.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";

const Dataservice = () => {
  const [comp1, setComp1] = useState(true);
  const [comp2, setComp2] = useState(false);
  const [comp3, setComp3] = useState(false);
  const [comp4, setComp4] = useState(false);
  const [comp5, setComp5] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const Comp1 = () => {
    return (
      <>
        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="render">
                <div
                  className={`renderdiv2 ${comp1 ? "active2" : ""}`}
                  onClick={() => {
                    setComp1(true);
                    setComp2(false);
                    setComp3(false);
                    setComp4(false);
                    setComp5(false);
                  }}
                >
                  Brand Awareness
                </div>

                <div
                  className={`renderdiv2 ${comp2 ? "active2" : ""}`}
                  onClick={() => {
                    setComp1(false);
                    setComp2(true);
                    setComp3(false);
                    setComp4(false);
                    setComp5(false);
                  }}
                >
                  Brand Pipeline
                </div>
                <div
                  className={`renderdiv2 ${comp3 ? "active2" : ""}`}
                  onClick={() => {
                    setComp1(false);
                    setComp2(false);
                    setComp3(true);
                    setComp4(false);
                    setComp5(false);
                  }}
                >
                  {" "}
                  Accelerate Pipeline
                </div>
                <div
                  className={`renderdiv2 ${comp4 ? "active2" : ""}`}
                  onClick={() => {
                    setComp2(false);
                    setComp3(false);
                    setComp1(false);
                    setComp4(true);
                    setComp5(false);
                  }}
                >
                  Retain Customer
                </div>
                <div
                  className={`renderdiv2 ${comp5 ? "active2" : ""}`}
                  onClick={() => {
                    setComp2(false);
                    setComp3(false);
                    setComp4(false);
                    setComp1(false);
                    setComp5(true);
                  }}
                >
                  Expand Customers
                </div>
              </div>
              {comp1 && <BrandAwareness />}
              {comp2 && <BuildPipeline />}
              {comp3 && <AcceleratePipeline />}
              {comp5 && <ExpandPipeline />}
              {comp4 && <RetainPipeline />}
            </div>
          </div>
        </div>
      </>
    );
  };

  const Comp2 = () => {
    return (
      <>
        <div className="container-fluid mx-0">
          <div
            className="row mx-0"
            style={{
              border: "1px solid gray",
              margin: "10px 20px",
              borderRadius: "10px",
            }}
          >
            <div className="col-sm-12 text-center mx-0">
              <div className="render2 mx-0 px-0">
                <div
                  className={`renderdiv ${comp1 ? "active" : ""}`}
                  onClick={() => {
                    setComp1(true);
                    setComp2(false);
                    setComp3(false);
                    setComp4(false);
                    setComp5(false);
                  }}
                >
                  Brand Awareness
                </div>
                {comp1 && <BrandAwareness />}

                <div
                  className={`renderdiv ${comp2 ? "active" : ""}`}
                  onClick={() => {
                    setComp1(false);
                    setComp2(true);
                    setComp3(false);
                    setComp4(false);
                    setComp5(false);
                  }}
                >
                  Brand Pipeline
                </div>
                {comp2 && <BuildPipeline />}
                <div
                  className={`renderdiv ${comp3 ? "active" : ""}`}
                  onClick={() => {
                    setComp1(false);
                    setComp2(false);
                    setComp3(true);
                    setComp4(false);
                    setComp5(false);
                  }}
                >
                  {" "}
                  Accelerate Pipeline
                </div>
                {comp3 && <AcceleratePipeline />}
                <div
                  className={`renderdiv ${comp4 ? "active" : ""}`}
                  onClick={() => {
                    setComp2(false);
                    setComp3(false);
                    setComp1(false);
                    setComp4(true);
                    setComp5(false);
                  }}
                >
                  Retain Customer
                </div>
                {comp4 && <RetainPipeline />}
                <div
                  className={`renderdiv ${comp5 ? "active" : ""}`}
                  onClick={() => {
                    setComp2(false);
                    setComp3(false);
                    setComp4(false);
                    setComp1(false);
                    setComp5(true);
                  }}
                >
                  Expand Customers
                </div>
                {comp5 && <ExpandPipeline />}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <Layout title={"Data-Services"}>
      <div
        className="container-fluid margin"
        style={{ backgroundColor: "#104DB3", color: "white" }}
      >
        <div className="row pb-5">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME{" "}
              </Link>
              - DATA-SERVICE
            </p>
            <h1 style={{ fontSize: "48px" }} className="py-3">
              {" "}
              We make your data accurate and actionable
            </h1>
            <p className="repeatp2">Audit. Enrich. Discover. Manage.</p>
            <button className="why navbuttonn  mt-5">I WANT TO GROW</button>
          </div>
          <div className="col-md-4 col-sm-12">
            <img
              src={data1}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              src={data2}
              style={{ width: "100%", height: "100%" }}
              alt="img"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1 mt-5">
              Data doesn’t have to be a dirty word.
            </h1>
            <p className="repeatp1">
              Every marketer struggles with data, both in their CRM and with
              outside vendors who specialize in data. Data accuracy is a
              long-term play with hundreds of short-term implications. With
              Terminus Data Services, we make your data work for you, and it
              gets better over time.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12 p-5">
            <h1 className="repeath6" style={{ fontWeight: "700" }}>
              Would you sleep better if your data was more reliable?
            </h1>
            <p className="repeatp2">
              We believe that actionable data is the hub of your GTM machine. So
              we give your data a white-glove service.
            </p>
          </div>
          <div className="col-md-6 col-sm-12 p-5">
            <ul className="repeatp2">
              <li>
                <span style={{ fontWeight: "500" }}>Audit.</span> Establish and
                regularly test the health and baseline of data.
              </li>
              <li>
                <span style={{ fontWeight: "500" }}>Enrich.</span> CRM data
                reviewed and verified to correct and fix any gaps for the best
                quality data.
              </li>
              <li>
                <span style={{ fontWeight: "500" }}>Discover.</span> Meet
                personas that you need to reach and add new ICPs when necessary.
              </li>
              <li>
                <span style={{ fontWeight: "500" }}>Manage.</span> From contact
                information to promotions and job changes, we keep data fresh.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1 mt-5">Data services customers have seen</h1>
            <p className="repeatp1">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#FBAD18" }} />{" "}
              180-300% increase in conversions
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#FBAD18" }} />{" "}
              Nearly 20% reduction in campaign error rates
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#FBAD18" }} /> 97%
              Accuracy in contact and lead data
            </p>
          </div>{" "}
          <div className="col-md-6 col-sm-12">
            <img
              src={data3}
              style={{ width: "100%", height: "100%" }}
              alt="img"
            />
          </div>
        </div>
      </div>
      <div
        className="container-fluid margin  text-center"
        style={{ backgroundColor: "#112D52", color: "white" }}
      >
        <p className="repeatp1">
          Terminus was built by marketers, for marketers. Whatever your role, we
          have the tools and team to help you succeed, and the data you need to
          prove it.
        </p>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              alt="img"
              src={data7}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <div
              style={{
                backgroundColor: "#274164",
                display: "flex",
                alignItems: "center",
                color: "white",
                width: "80%",
              }}
              className="row  fonthover"
            >
              <div className="col-sm-2">
                <img
                  src={data4}
                  alt="img"
                  style={{ height: "63px", width: "60px" }}
                />
              </div>
              <div className="col-sm-10 d-flex">
                <p className="repeath4"> Revenue Marketing</p>{" "}
                <FontAwesomeIcon icon={faArrowRight} className="font" />
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#274164",
                display: "flex",
                alignItems: "center",
                color: "white",
                width: "80%",
              }}
              className="row my-5  fonthover"
            >
              <div className="col-sm-2">
                {" "}
                <img
                  src={data5}
                  alt="img"
                  style={{ height: "63px", width: "60px" }}
                />
              </div>
              <div className="col-sm-10 d-flex">
                {" "}
                <p className="repeath4"> Customer Marketing</p>{" "}
                <FontAwesomeIcon icon={faArrowRight} className="font" />
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#274164",
                display: "flex",
                alignItems: "center",
                color: "white",
                width: "80%",
              }}
              className="row fonthover"
            >
              <div className="col-sm-2">
                <img
                  src={data6}
                  alt="img"
                  style={{ height: "63px", width: "60px" }}
                />
              </div>
              <div className="col-sm-10 d-flex">
                {" "}
                <p className="repeath4"> Digital Marketing</p>{" "}
                <FontAwesomeIcon icon={faArrowRight} className="font" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row text-center py-5">
          <div className="col-sm-12">
            <h1 className="repeath1">Optimize go-to-market at every stage</h1>
            <p className="repeatp3">
              Companies’ disproportionate focus on new business is
              understandable, but it’s not enough to hit revenue goals on its
              own. Let us show you what you can do across the entire revenue
              flywheel with the right resources and impeccable timing.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p
              style={{
                fontSize: "54px",
                fontWeight: "700",
                color: "#091B31",
              }}
            >
              Revenue Flywheel
            </p>
            {isMobile ? <Comp2 /> : <Comp1 />}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-sm-12 text-center john">
            <p className="johnp1">
              In less than six weeks, we launched our first campaign on Oshn Brand
              after integrating it in our toolset, building our plan, creating
              assets, and planning media. This speaks to the ease of use of the
              platform. It also, as importantly, highlights the Oshn Brand team we
              work with and their level of knowledge, commitment, and skill.
            </p>
            <img alt="img" src={home10} />
            <p
              style={{
                fontSize: "13px",
                lineHeight: "13px",
                fontWeight: "700",
              }}
            >
              John Bonapace
            </p>
            <p
              style={{
                fontSize: "13px",
                lineHeight: "13px",
                // fontWeight: "700",
              }}
            >
              John Bonapace, Chief Marketing Officer, Secure-24
            </p>
          </div>
        </div> */}
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-sm-12">
            <h4
              style={{
                fontSize: "48px",
                lineHeight: "54px",
                fontWeight: "500",
                color: "#091B31",
                textAlign: "center",
              }}
            >
              Dig a little deeper
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-12 p-2">
            <div
              style={{
                backgroundColor: "#E9F5FF",
                padding: "20px",
                height: "315px",
                // width: "330px",
              }}
              className="text-center"
            >
              <img
                style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                alt="img"
                src={why5}
              />
              <h6 style={{ height: "120px", paddingTop: "40px" }}>
                Third-party cookies are out and first-party data is in.
              </h6>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 p-2">
            <div
              style={{
                backgroundColor: "#E9F5FF",
                padding: "20px",
                height: "315px",
              }}
              className="text-center"
            >
              <img
                style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                alt="img"
                src={why6}
              />
              <h6 style={{ height: "120px", paddingTop: "40px" }}>
                Multi-Channel Campaigns and ABM Success
              </h6>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
              >
                Get the E-Book
              </button>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 p-2">
            <div
              style={{
                backgroundColor: "#E9F5FF",
                padding: "20px",
                height: "315px",
                // width: "330px",
              }}
              className="text-center"
            >
              <img
                style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                alt="img"
                src={why7}
              />
              <h6 style={{ height: "120px", paddingTop: "40px" }}>
                ABM Benchmark Report
              </h6>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dataservice;
const AcceleratePipeline = () => {
  return (
    <>
      <div
        style={{ borderBottom: "1px solid gray" }}
        className="container text-center"
      >
        <div className="row">
          <div className="col-sm-12">
            <h1>Accelerate Pipeline</h1>
            <p>
              The right messaging at the right time can get a deal unstuck. You
              can multi-thread into prospect accounts by engaging the entire
              buying committee. You can even support high-priority and
              late-stage deals with 1:1 campaigns. Over time, you’ll see deal
              velocity and conversion rate between opportunity stages improve as
              you grow in your ability to move prospects to a decision.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
const BuildPipeline = () => {
  return (
    <>
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-12">
            <h1>Build Pipeline</h1>
            <p>
              Once familiar with your brand, buyers can enter pre-opportunity
              nurture programs that can retarget based on interactions with your
              website and email marketing. You can build a base of knowledge
              with your prospects, and even include closed-lost opportunities to
              build pipeline with those most likely to buy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
const BrandAwareness = () => {
  return (
    <>
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-12">
            <h1>Brand Awareness</h1>
            <p>
              Whether entering new markets or going after a new ICP, a
              brand-awareness play gets buyers familiar with your brand so that
              when the time arrives to pursue a decision on a solution, you have
              a place in that discussion. You can focus attention on cold
              accounts that would be ideal for your solution, or accounts that
              are showing intent but haven’t engaged with your brand.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
const ExpandPipeline = () => {
  return (
    <>
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-12">
            <h1>Expand Customers</h1>
            <p>
              Never hear your customers say “Oh, I didn’t know your company did
              that” again. Educating your customers exponentially increases your
              opportunities for cross selling and upselling. Iff you need a way
              to get ahead of new divisions or business units, Oshn Brand makes
              it simple.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
const RetainPipeline = () => {
  return (
    <>
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-12">
            <h1>Retain Customers</h1>
            <p>
              Customer retention isn’t a single conversation at the end of your
              contract—it’s the entire journey with your company. Get ahead of
              your renewal conversations with proactive messaging on ROI,
              product adoption and best practices throughout the entire
              lifecycle. As your renewal inches closer, get insight into the
              competitive research they may be doing so you can have more
              meaningful conversations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
