import React from "react";
import Layout from "../comp/layout";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const LeaderShip = () => {
  const card = [
    {
      img: "/img/home22.jpg",
      head: "Pawan Kaushik",
      p: "CHIEF EXECUTIVE OFFICER",
    },
    {
      img: "/img/blank.jpg",
      head: "Abhishek Shukla",
      p: "CHIEF REVENUE OFFICER",
    },
    {
      img: "/img/blank.jpg",
      head: "Vijay Raghuvanshi",
      p: "Head of Sales",
    },
    { img: "/img/blankf.jpg", head: "Monika", p: "Head of Marketing" },
  ];
  return (
    <Layout title={"Leadership Oshn Brand"}>
      <div
        className="container-fluid margin"
        style={{ backgroundColor: "#104DB3", color: "white" }}
      >
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>
              - LEADERSHIP
            </p>
            <h1 style={{ fontSize: "48px" }}>
              Oshn Brand is led by some of the{" "}
              <span style={{ fontWeight: "900", color: "#6ABDFD" }}>
                best and brightest
              </span>{" "}
              executives in SaaS.
            </h1>
          </div>
          <div className="col-md-4 col-sm-12"></div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row text-center">
          {card.map((e) => (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  style={{ height: "246px", width: "246px" }}
                  alt="img"
                  src={e.img}
                />
                <h6 className="repeath6" style={{ color: "#005589" }}>
                  {e.head}
                </h6>
                <p className="repeatp2">{e.p}</p>
                <FontAwesomeIcon className="fontawe" icon={faLinkedin} />
              </div>
            </>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default LeaderShip;
