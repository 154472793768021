import React, { useState, useEffect } from "react";
import Layout from "../../comp/layout";
import { Link, useNavigate } from "react-router-dom";
import google1 from "../../img/metaads.png";
import meta1 from "../../img/meta1.jpg";
import meta2 from "../../img/meta2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MetaAds = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/plan/singleplan/Meta-Ads"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/query/addquery",
        {
          title: "Meta-Ads",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  return (
    <Layout title={"Oshn Brand Meta-Ads"}>
      <ToastContainer />
      <div
        className="container-fluid"
        style={{ backgroundColor: "#4285F4", color: "white" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                META ADS
              </Link>{" "}
            </p>
            <h4 className="repeath1 text-light">
              #1 FACEBOOK ADS AGENCY INDIA
            </h4>
            <h6 className="repeath6">
              FACEBOOK ADS AGENCY FOR ALL INDUSTRIES LEAD GENERATION
            </h6>
            <p className="repeatp1">
              Reach to more users, sell more products and generate low-cost
              leads from Facebook Marketing services. Facebook Ads help small to
              large businesses to set up their business page, and start
              generating leads even without a website. For a retailer, it offers
              one of the best solutions to sell products from their e-commerce
              websites. Many Shopify, woo commerce website owners are doing
              pretty well with Facebook ads.
            </p>

            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn  mt-5 text-light"
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={google1}
              alt="img"
              style={{ height: "470px", width: "100%", borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundColor: "black" }}>
        <div className="row py-4">
          <div className="col-sm-12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "200px",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "2px solid white",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: "25px", color: "white" }}
                    icon={faCheck}
                  />
                </div>
                <p
                  className="repeatp1 text-light"
                  style={{ textAlign: "left" }}
                >
                  Affordable Pricing
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "200px",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "2px solid white",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: "25px", color: "white" }}
                    icon={faCheck}
                  />
                </div>
                <p
                  className="repeatp1 text-light"
                  style={{ textAlign: "left" }}
                >
                  Facebook Ads services since 2010
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "200px",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "2px solid white",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: "25px", color: "white" }}
                    icon={faCheck}
                  />
                </div>
                <p
                  className="repeatp1 text-light"
                  style={{ textAlign: "left" }}
                >
                  500+ PPC Ads Clients Served
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "200px",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "2px solid white",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: "25px", color: "white" }}
                    icon={faCheck}
                  />
                </div>
                <p
                  className="repeatp1 text-light"
                  style={{ textAlign: "left" }}
                >
                  100% transparent, Nothing is hidden
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // gap: "10px",
                  width: "200px",
                }}
              >
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "2px solid white",
                    borderRadius: "50%",
                  }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: "25px", color: "white" }}
                    icon={faCheck}
                  />
                </div>
                <p
                  className="repeatp1 text-light"
                  style={{ textAlign: "left" }}
                >
                  Weekly reports, Dedicated support
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="repeath1 text-center">
              FACEBOOK MARKETING SERVICES AGENCY FOR SMALL BUSINESSES
            </h1>
            <h6 className="repeath6 text-center">
              LEAD GENERATION | CATALOG SALES | VIDEO ADS | BRANDING | APPS
              PROMOTION
            </h6>
            <p className="repeatp1 text-center">
              Do you want to generate leads or want to sell products on Facebook
              & Instagram? Our Facebook ad agency has the team to setup and
              manage profitable ads on Facebook. All we need is access to your
              Facebook business page, and your product/service details. Our
              agency will guide you step by step to advertise your business on
              Facebook. Our Facebook ads pricing in India is also very
              competitive. Contact our agency to get more details about our
              Facebook marketing services & packages.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h4 className="repeath4">WHY FACEBOOK ADVERTISING?</h4>
            <ul className="repeatp1" style={{ lineHeight: "35px" }}>
              <li>Micro Target your exact audiences</li>
              <li>It offers the best ROAS for retailers</li>
              <li>Wide range of engaging Ad types</li>
              <li>Access to the largest audience database in the world</li>
              <li>Lower cost per click compared to Google ads</li>
              <li>Get the lowest cost per lead</li>
              <li>Advertise on Instagram</li>
              <li>Read more audience on the audience networks</li>
              <li>Start with any budget and anytime</li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <img
              style={{ height: "100%", width: "100%" }}
              alt="img"
              src={meta1}
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-5 col-sm-12">
            <img
              style={{ height: "100%", width: "100%" }}
              alt="img"
              src={meta2}
            />
          </div>
          <div className="col-md-7 col-sm-12">
            <p className="repeatp1">
              <b>Image Ads: </b>These are the most popular ads where you can
              select one or multiple images to design your Ads or carousel Ads.
              Facebook offers you the option to write a description and title of
              Ads along with the option to add your website link or lead form.
            </p>
            <p className="repeatp1">
              <b>Video Ads: </b> If you have video then you can create video ads
              just like image ads. even you can create your video ads with
              multiple images (slideshow Ads). The process of creating most of
              these ads is similar except for ads like product ads, leads forms
              etc.
            </p>
            <p className="repeatp1">
              <b>Instant Experience: </b>These are a full-screen experience that
              shows on mobile. It opens after someone taps ad on a smartphone.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>
              <b>Collection Ads: </b>If you are selling products, then these ads
              are for you to promote on mobile devices. It allows 5 products in
              ads. Your customers can discover, browse and purchase products
              from their phones in a visual and immersive way.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="repeath4">HOW MUCH TO SPEND ON FACEBOOK ADS?</h4>
            <p className="repeatp1">
              Like any other advertising, you can decide your daily or lifetime
              budget to advertise on Facebook. If are well experienced with a
              Facebook ads, then you can start with any good budget, but if you
              are new and uncertain about the outcome, then start with a small
              budget and see the result. Gradually you can start increasing the
              budget with the result. There is no minimum or maximum limit. You
              can start with one dollar too.
            </p>
            <h4 className="repeath4">
              HOW FACEBOOK ADVERTISING AGENCY CAN HELP?
            </h4>
            <p className="repeatp1">
              Most of business owners don't have time to learn and do
              everything. So it's wise to outsource facebook ads management to
              some competent agency like us. The benefit of agency is{" "}
              <ul className="my-2" style={{ lineHeight: "30px" }}>
                <li>No need to hire a permanent employee</li>
                <li>Low Cost compared to an employee's salary</li>
                <li>The expertise of many years</li>
                <li>
                  Experience of working with many projects in the industry which
                  they can utilize
                </li>
                <li>No Long term contract. Start End in any month</li>
                <li>
                  Free consultations on new opportunities to grow business.
                </li>
                <li>
                  Agencies have teams and many minds can work on your project to
                  bring better results.
                </li>
              </ul>
              Get in touch with us to learn more.
            </p>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1">Frequently Asked Questions</h1>

            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is Facebook Marketing?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Facebook marketing refers to the process of using Facebook's
                    various tools and features to promote a business or
                    organization's products, services, or brand to Facebook's
                    massive user base. This type of marketing can take many
                    forms, including paid advertisements, sponsored content, and
                    organic posts.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Why is Facebook the best for marketing?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Facebook is one of the best platforms for marketing because
                    of its massive user base and the extensive tools it offers
                    to advertisers. Here are a few reasons why Facebook is a top
                    choice for businesses looking to market their products or
                    services:
                    <ul>
                      <li>Large and diverse user base</li>
                      <li>Precise targeting options</li>
                      <li>Cost-effective</li>
                      <li>Measurable results</li>
                      <li>Facebook users are highly engaged.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How can I promote my business on Facebook?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Promoting your business on Facebook can be done through a
                    variety of methods. Here are a few steps you can take to get
                    started:
                    <ul>
                      <li>Create a Facebook page</li>
                      <li>Post regularly</li>
                      <li>Use Facebook Ads</li>
                      <li>Join Facebook Groups</li>
                      <li>Offer promotions or discounts</li>
                      <li>Collaborate with influencers</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    How do I sell my product on Facebook ads?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    To sell your product on Facebook ads, you will need to
                    create an ad campaign that highlights your product's
                    features and benefits and targets the right audience. Here
                    are some steps you can take to create a successful Facebook
                    ads campaign:
                    <ol>
                      <li>Set your campaign objectives</li>
                      <li>Choose your ad format</li>
                      <li>Create compelling ad copy</li>
                      <li>Use high-quality images</li>
                      <li>Target the right audience</li>
                      <li>Set your budget</li>
                      <li>Test and optimize</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    What is Facebook ads cost in India?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    The cost of Facebook ads in India varies depending on a
                    variety of factors, including the target audience, ad
                    placement, ad format, and bidding strategy. Generally, the
                    cost per click (CPC) for Facebook ads in India ranges from
                    around Rs. 10 to Rs. 50, while the cost per thousand
                    impressions (CPM) ranges from around Rs. 50 to Rs. 300.
                    However, these are just general estimates, and the actual
                    cost can vary widely depending on the specific campaign and
                    targeting parameters. It's important to set a budget and bid
                    strategically to ensure that you get the best return on
                    investment for your advertising spend.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    Can I run ads on platforms other than Facebook using
                    Facebook Ads Manager?
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes, Facebook Ads Manager allows you to run ads not only on
                    Facebook but also on other platforms owned by Facebook, such
                    as Instagram and Audience Network. When creating your
                    campaigns, you have the option to choose where you want your
                    ads to be displayed and optimize them accordingly.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSeven"
                    aria-expanded="false"
                    aria-controls="flush-collapseSeven"
                  >
                    How do I create Instagram ads?
                  </button>
                </h2>
                <div
                  id="flush-collapseSeven"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    To create Instagram ads, you need a Facebook Ads Manager
                    account and an Instagram business account linked to your
                    Facebook Page. In Facebook Ads Manager, select Instagram as
                    the placement option while creating a new campaign. Upload
                    your ad creative, including images or videos, write engaging
                    ad copy, and add relevant links or call-to-action buttons.
                    Review your campaign settings and publish your Instagram ad.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-4">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="repeath1 text-center">Our Meta-Ads Plans</h1>
          </div>
        </div>
        <div className="row">
          {plan?.map((e, index) => (
            <>
              <div
                key={index}
                className="col-lg-3 col-md-6 col-sm-12 text-center"
              >
                <div key={index} className="youtubeseo button-container">
                  <div>
                    {" "}
                    <h1 className="button2 repeath1">{e.type}</h1>
                    <div
                      style={{
                        backgroundColor: "#112D52",
                        padding: "10px 0",
                        color: "white",
                      }}
                    >
                      <h4>
                        {/* <FontAwesomeIcon
                          icon={faDollarSign}
                          style={{ fontSize: "15px" }}
                        /> */}
                        {e.price}
                      </h4>
                      <p>Per Month</p>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <b>
                      Upto {e.p1} Keyword tracked weekly {e.videos}
                    </b>
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p2} pages optimised </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p3} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p4} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p5} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p6} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p7} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p8} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p9} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>

                  <button onClick={() => handleShow(e)} className="ytbtn">
                    I want to grow
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="https://oshnbrand.com/static/media/logo.fbd43d01225aac439771.jpg"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6>Meta-Ads</h6>
                </div>
                <form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default MetaAds;
