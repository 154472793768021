import React from "react";
// import { Slide } from "react-slideshow-image";
// import "react-slideshow-image/dist/styles.css";
import "./home.css";
import home1 from "../img/home13.gif";
import home2 from "../img/home2.webp";
// import home10 from "../img/home10.jpeg";
// import home3 from "../img/home3.jpeg";
import home4 from "../img/home4.webp";
import home11 from "../img/home11.webp";
import home7 from "../img/home7.webp";
import home8 from "../img/home8.webp";
import home9 from "../img/home9.webp";
import home20 from "../img/home22.jpg";
import home22 from "../img/home21.jpg";
import google from "../img/google.jpg";
import google2 from "../img/meta.jpg";
import google3 from "../img/youtube.jpg";
import google4 from "../img/semrush.png";
// import { NavLink } from "react-router-dom";

import Layout from "../comp/layout";
import { useNavigate } from "react-router-dom";

const Homepage = () => {
  // const [showItem, setShowItem] = useState(null);
  // const [slidesToShow, setSlidesToShow] = useState(3);

  // useEffect(() => {
  //   const updateSlidesToShow = (props) => {
  //     if (window.innerWidth < 768) {
  //       setSlidesToShow(1);
  //     } else if (window.innerWidth < 992) {
  //       setSlidesToShow(2);
  //     } else {
  //       setSlidesToShow(3);
  //     }
  //   };

  //   updateSlidesToShow();

  //   window.addEventListener("resize", updateSlidesToShow);

  //   return () => {
  //     window.removeEventListener("resize", updateSlidesToShow);
  //   };
  // }, []);
  const navigate = useNavigate();

  const card = [
    {
      img: "./img/card1.PNG",
      head: "Consolidated Reporting",
      p: "Bring 1st, 3rd and CRM Data together for a unified look at program health.",
    },
    {
      img: "./img/card2.svg",
      head: "Buyer Intent",
      p: "Identify in-market accounts, both new to your business and existing in your CRM.",
    },
    {
      img: "./img/card3.svg",
      head: "B2B advertising",
      p: "Reach the entire buying committee, without risking marketing budget dollars.",
    },
    {
      img: "./img/card4.svg",
      head: "Chat Experiences",
      p: "Empower website visitors to interact directly with your team and content.",
    },
    {
      img: "./img/card5.svg",
      head: "Website personalization",
      p: "Activate bespoke user experiences, all on your existing web property.",
    },
    {
      img: "./img/card6.svg",
      head: "Corporate Signature",
      p: "Offer individualized experiences directly in your customers and prospects inbox.",
    },
  ];

  return (
    <>
      <Layout title={"Oshn Brand"}>
        <div style={{ backgroundColor: "#112D52", color: "white" }}>
          <div className="container-fluid px-3 py-4 home1">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h1>#1 Digital Marketing Agency From ASIA & USA</h1>
                <p style={{ fontFamily: "sans-serif", fontSize: "16px" }}>
                  Unlock revenue potential by engaging ready to buy prospects
                  and customers with precision. It’s not a crystal ball or a
                  black box. It’s a real practical tool to smash your company
                  targets.
                </p>
                <div className="">
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn "
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Get High Quality
                    <br /> Leads
                  </button>{" "}
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Increase Your <br />
                    ROI
                  </button>
                </div>
                <div className="mt-4">
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Increase Customer
                    <br /> Engagement
                  </button>{" "}
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#005589",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                  >
                    Manage Brand
                    <br /> Reputation
                  </button>
                </div>
                <div className="mt-4">
                  <button
                    style={{ width: "fit-content", color: "white" }}
                    className="svg2 navbuttonn mt-3"
                  >
                    Why Oshn Brand?
                  </button>
                  <button
                    onClick={() => {
                      navigate("/grow-with-us");
                    }}
                    className="why navbuttonn  mt-3 "
                    style={{ marginLeft: "60px" }}
                  >
                    I Want To Grow
                  </button>
                </div>
                <div className="d-flex flex-wrap gap-5 mt-5">
                  <img
                    src={google}
                    alt="img"
                    style={{
                      height: "77px",
                      width: "250px",
                      borderRadius: "10px",
                    }}
                  />
                  <img
                    src={google2}
                    alt="img"
                    style={{
                      height: "77px",
                      width: "250px",
                      borderRadius: "10px",
                    }}
                  />
                  <img
                    src={google3}
                    alt="img"
                    style={{
                      height: "77px",
                      width: "250px",
                      borderRadius: "10px",
                    }}
                  />
                  <img
                    src={google4}
                    alt="img"
                    style={{
                      height: "77px",
                      width: "250px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    // boxShadow: "0px 0px 20px #FBAD18",
                  }}
                  src={home20}
                  alt="img"
                />
              </div>
            </div>
          </div>
          <div className="container-fluid margin  bg-light text-dark">
            <div className="row text-center home2">
              <div className="col-sm-12">
                <h1>Unify Go-To-Market Success</h1>
                <p className="justify">
                  Collaboration is key to achieve lasting success. When Sales,
                  Marketing, and Operations align their efforts, growth becomes
                  not just a forecast but a model that fuels all areas of the
                  business. Together with Oshn Brand your GTM team can integrate
                  and enrich first and third party data to make informed
                  decisions and optimize strategies for success.
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid margin">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img src={home1} style={{ width: "100%" }} alt="img" />
              </div>
              <div className="col-md-6 col-sm-12">
                <h1
                  style={{
                    fontSize: "48px",
                    lineHeight: "54px",
                    fontWeight: "500",
                  }}
                >
                  Get High Quality Leads
                </h1>
                <p className="justify" style={{ fontSize: "20px" }}>
                  Targeting and timing are everything, right? Richer data, a
                  team of experts, and a 360-degree, multi-channel experience
                  for your top accounts is how you build pipeline and close
                  deals.
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid margin bg-light text-dark">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h1
                  style={{
                    fontSize: "48px",
                    lineHeight: "54px",
                    fontWeight: "500",
                  }}
                >
                  INCREASE YOUR ROI
                </h1>
                <p className="justify" style={{ fontSize: "20px" }}>
                  The often-tried and over-done traditional B2B growth
                  strategies aren’t cutting it anymore and the industry is
                  learning that intent data alone isn’t a silver bullet. To hit
                  your revenue target, and to keep your seat at the GTM
                  leadership table, you need to deliver sustainable growth.
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={home2} style={{ width: "100%" }} alt="img" />
              </div>
            </div>
          </div>
          <div className="container-fluid margin">
            <div className="row text-center home2">
              <div className="col-sm-12">
                <h1>A G2 leader for 20 consecutive quarters</h1>
                <p className="justify">
                  Whether it’s our proven track record as a pioneer in
                  account-based marketing, our best-in-class digital channels,
                  or our commitment to help marketers close their pipeline gap,
                  Oshn Brand customers agree that we’re the choice they’re most
                  likely to recommend.
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid  px-5 py-4">
            <div className="row">
              {card.map((e) => (
                <>
                  <div className="col-lg-2 col-md-6 col-sm-12 cardd">
                    <img
                      src={e.img}
                      alt="img"
                      style={{ width: "50%", height: "30%" }}
                    />
                    <h2 className="py-3">{e.head}</h2>
                    <p mb-5>{e.p}</p>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="container-fluid margin  bg-light text-dark">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img src={home4} style={{ width: "100%" }} alt="img" />
              </div>
              <div className="col-md-6 col-sm-12">
                <h1
                  style={{
                    fontSize: "48px",
                    lineHeight: "54px",
                    fontWeight: "500",
                  }}
                >
                  No buzzwords or academic theories here.
                </h1>
                <p
                  style={{
                    fontSize: "18px",
                    lineHeight: "25px",
                    fontWeight: "500",
                    color: "rgb(4, 143, 197)",
                  }}
                >
                  We’ve grounded ourselves in a few principles:
                </p>
                <ul>
                  <li> Data you trust is non-negotiable.</li>
                  <li>
                    Buyers demand a personalized, relevant buying experience.
                  </li>
                  <li> Your GTM team has to work together.</li>
                  <li>
                    {" "}
                    Everything you do needs to have provable revenue impact.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container margin">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h1
                  style={{
                    fontSize: "48px",
                    lineHeight: "54px",
                    fontWeight: "500",
                  }}
                >
                  INCREASE CUSTOMER ENGAGEMENT
                </h1>
                <p className="justify" style={{ fontSize: "20px" }}>
                  We’ll bring your disparate first- and third-party data
                  together into a single, trustworthy source. We’ll deliver the
                  most native engagement channels of any ABM platform, founded
                  on industry-leading B2B advertising that is prepared for the
                  post-cookie future. We’ll help you turn insight into action
                  across your entire GTM team. And we’ll tie it all back to the
                  numbers that matter most: pipeline and revenue.
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={home7} style={{ width: "100%" }} alt="img" />
              </div>
            </div>
          </div>
          <div className="container-fluid  margin  bg-light text-dark">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 col-sm-12">
                <img src={home8} style={{ width: "100%" }} alt="img" />
              </div>
              <div className="col-md-6 col-sm-12">
                <h1
                  style={{
                    fontSize: "48px",
                    lineHeight: "54px",
                    fontWeight: "500",
                    color: "#091B31",
                  }}
                >
                  MANAGE BRAND REPUTATION
                </h1>
                <p
                  style={{
                    fontSize: "18px",
                    lineHeight: "25px",
                    fontWeight: "300",
                  }}
                  className="py-5 justify"
                >
                  We are the only ABM platform in 2022 to be recognized as a
                  leader in Forrester’s B2B Advertising Wave, Forrester’s ABM
                  Platform Wave, and Gartner’s ABM Magic Quadrant. But this is
                  no fluke, Oshn Brand helped create the ABM category and we’ve
                  been a G2 Leader in ABM for 17 consecutive quarters.
                </p>
                <button
                  style={{ width: "fit-content", color: "white" }}
                  className="svg2 navbuttonn"
                >
                  Get the Report
                </button>
              </div>
            </div>
          </div>
          {/* <div className="container-fluid margin  bg-light text-dark">
          <div className="row text-center px-5">
            <div className="col-sm-12">
              <h1>
                Efficiency is the name of the game. When time and budgets are
                limited, optimizing the entire revenue flywheel is more
                important than ever.
              </h1>
              <p style={{ fontSize: "18px" }}>
                No matter where an account is in the buying cycle, delivering a
                personalized, timely brand experience matters. When you do it
                right, you’ll set your business up with the opportunity to grow
                and expand relationships, build loyalty, and maximize revenue
                potential.
              </p>
              <p style={{ fontSize: "18px" }}>
                And once your flywheel starts spinning, it’s hard to slow it
                down.
              </p>
            </div>
          </div>
        </div> */}
          {/* <div className="container-fluid margin">
            <div className="row">
              <div className="col-sm-12">
                <p
                  style={{
                    fontSize: "54px",
                    fontWeight: "700",
                    color: "#fff",
                  }}
                >
                  Revenue Flywheel
                </p>
                {isMobile ? <Comp2 /> : <Comp1 />}
              </div>
            </div>
          </div> */}
          <div className="container-fluid margin bg-light text-dark">
            <div className="row ">
              <div className="col-sm-12 text-center john">
                <p
                  className="johnp1 text-dark"
                  style={{ fontSize: "24px", fontFamily: "serif" }}
                >
                  In less than six weeks, we launched our first campaign on Oshn
                  Brand after integrating it in our toolset, building our plan,
                  creating assets, and planning media. This speaks to the ease
                  of use of the platform. It also, as importantly, highlights
                  the Oshn Brand team we work with and their level of knowledge,
                  commitment, and skill.
                </p>
                <img alt="img" src={home22} />
                <p
                  className=" text-dark"
                  style={{
                    fontSize: "13px",
                    lineHeight: "13px",
                    fontWeight: "700",
                  }}
                >
                  Pawan Kaushik
                </p>
                <p
                  className=" text-dark"
                  style={{
                    fontSize: "13px",
                    lineHeight: "13px",
                    // fontWeight: "700",
                  }}
                >
                  Marketing Chief Officer
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid margin bg-light text-dark">
            <div className="row text-center">
              <div className="col-sm-12">
                <h1>We're more than a platform</h1>
              </div>
            </div>
            <div className="row px-5">
              <div className="col-md-6 col-sm-12">
                <p>
                  A platform alone won’t get you to your goals. We’ve got a team
                  of onboarding, strategy, media, and programmatic experts that
                  will help guide you on your path to value. And, they’ll do it
                  fast. Picture it: a world
                </p>
              </div>
              <div className="col-md-6 col-sm-12">
                <p>
                  where your technology maps to your strategy, with tactical
                  playbooks to support your execution so hitting your goals is
                  no longer a question mark. It’s a beautiful thing.
                </p>
              </div>
            </div>
            <div
              className="row px-5 py-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                alt="img"
                src={home9}
                style={{ height: "100%", width: "80%" }}
              />
            </div>
            <div
              className="row text-center py-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="servicebtn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Oshn Brand Professional Service
              </button>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center john">
                <p style={{ fontSize: "24px" }} className="johnp1">
                  This platform has been really useful in shortening sales
                  cycles via precision target market reach and engagement. Has
                  enabled us to stay ahead of the pack in terms of reaching and
                  converting prospects.
                </p>
                <img alt="img" src={home11} />
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "13px",
                    fontWeight: "700",
                  }}
                >
                  Hardik Chug
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "13px",
                    // fontWeight: "700",
                  }}
                >
                  Sales Coordinator
                </p>
              </div>
            </div>{" "}
          </div>

          {/* <div className="container">
          <div className="slide-container">
            <Slide
              slidesToShow={slidesToShow}
              defaultIndex={0}
              transitionDuration={500}
            >
              {slideImages.map((slideImage) => (
                <div
                  key={slideImage.url}
                  className={`col-lg-${12 / slidesToShow}  mb-4 `}
                >
                  <div>
                    <img src={slideImage.url} alt="img" />
                  </div>
                </div>
              ))}
            </Slide>
          </div>
        </div> */}
        </div>
      </Layout>
    </>
  );
};

export default Homepage;
