import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

const FilterQuery = () => {
  const [allQueries, setAllQueries] = useState([]);
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);

  const fetchAllQueries = async () => {
    try {
      const response = await axios.get(
        `https://oshnbrand.com:8090/api/category/singlecategory/${params.title}`
      );
      if (response?.data?.success) {
        console.log("success");
        // Ensure response.data.data.category is an array before setting it
        const fetchedQueries = Array.isArray(response?.data?.data)
          ? response?.data?.data
          : [];
        setAllQueries(fetchedQueries);
        console.log(fetchedQueries);
      } else {
        console.error("Failed to fetch queries:", response?.data?.error);
      }
    } catch (error) {
      console.error("Error fetching queries:", error);
    }
  };

  useEffect(() => {
    fetchAllQueries();
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://oshnbrand.com:8090/api/category/delete/${id}`
      );
      if (response?.data?.success) {
        // If deletion is successful, fetch the updated list of queries
        fetchAllQueries();
      } else {
        console.error("Failed to delete query:", response?.data?.error);
      }
    } catch (error) {
      console.error("Error deleting query:", error);
    }
  };
  const handleClickOpen = (query) => {
    setSelectedQuery(query);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `https://oshnbrand.com:8090/api/category/update/${selectedQuery._id}`,
        selectedQuery
      );
      if (response?.data?.success) {
        fetchAllQueries();
        handleClose();
      } else {
        console.error("Failed to update query:", response?.data?.error);
      }
    } catch (error) {
      console.error("Error updating query:", error);
    }
  };

  const handleChange = (e) => {
    setSelectedQuery({ ...selectedQuery, [e.target.name]: e.target.value });
  };

  return (
    <div className="pt-4 bg-primary text-light py-5">
      <h1 className="text-center bg-primary text-light">All Queries</h1>
      <TableContainer
        component={Paper}
        style={{ margin: "auto", maxWidth: 1200 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Package</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Phone</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Company</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Budget</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Update Data</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Delete Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allQueries.map((e, index) => (
              <TableRow key={index}>
                <TableCell>{e.title}</TableCell>
                <TableCell>{e.price}</TableCell>
                <TableCell>{e.name}</TableCell>
                <TableCell>{e.email}</TableCell>
                <TableCell>{e.phone}</TableCell>
                <TableCell>{e.company}</TableCell>
                <TableCell>{e.budget}</TableCell>
                <TableCell>{e.date}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickOpen(e)}
                  >
                    Update
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(e._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedQuery && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Update Query</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To update this query, please modify the details below.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="title"
              label="Package"
              type="text"
              fullWidth
              value={selectedQuery.title}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="price"
              label="Price"
              type="text"
              fullWidth
              value={selectedQuery.price}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={selectedQuery.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={selectedQuery.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="phone"
              label="Phone"
              type="text"
              fullWidth
              value={selectedQuery.phone}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="company"
              label="Company"
              type="text"
              fullWidth
              value={selectedQuery.company}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="budget"
              label="Budget"
              type="text"
              fullWidth
              value={selectedQuery.budget}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="date"
              label="Date"
              type="text"
              fullWidth
              value={selectedQuery.date}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default FilterQuery;
