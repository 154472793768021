import React, { useEffect, useState } from "react";
import Layout from "../comp/layout";
import googleppc from "../img/googleppc.jpg";
import google2 from "../img/googleads2.webp";
import google3 from "../img/google3.png";
import google4 from "../img/google4.png";
import google5 from "../img/google5.jpg";
import google6 from "../img/google6.png";
import google7 from "../img/google7.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import {
  faAngleDoubleRight,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import LetsTalk from "../comp/LetsTalk";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";

const Googleads = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/plan/singleplan/Google-PPC-Ads"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/query/addquery",
        {
          title: "Google-PPC-Ads",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  const card = [
    {
      head: "PLAN A",
      p1: "Google Ads",
      p2: "1",
      p3: "₹20K-₹50K ($500-$1000)",
      p4: "2",
      p5: "50-60",
      p6: "Search, Remarketing",
      p7: "Weekly",
      p8: "Email",
      p9: "Contact Us",
      p10: "Save 10% on Quarterly fee",
    },
    {
      head: "PLAN B",
      p1: "Google Ads",
      p2: "1",
      p3: "Up to Rs.1.5Lac ($3000)",
      p4: "2-4",
      p5: "upto 150",
      p6: "Search, Shopping, Remarketing & Display",
      p7: "Weekly",
      p8: "Email",
      p9: "Contact Us",
      p10: "Save 10% on Quarterly fee",
    },
    {
      head: "PLAN C",
      p1: "Google Ads",
      p2: "1",
      p3: "Up to Rs.5Lac ($7000)",
      p4: "4-6",
      p5: "Upto 250",
      p6: "All",
      p7: "Weekly",
      p8: "Email",
      p9: "Contact Us",
      p10: "Save 10% on Quarterly fee",
    },
    {
      head: "PLAN D",
      p1: "Google Ads",
      p2: "1",
      p3: "Up to Rs.10Lac ($15000)",
      p4: "6-8",
      p5: "Upto 300",
      p6: "All",
      p7: "Weekly",
      p8: "Email",
      p9: "Contact Us",
      p10: "Save 10% on Quarterly fee",
    },
  ];
  return (
    <Layout title={"Oshn Brand Google-Ads"}>
      <ToastContainer />
      <div
        className="container-fluid"
        style={{ backgroundColor: "#004AAD", color: "white" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                GOOGLE PPC ADS
              </Link>{" "}
            </p>
            <h4 className="repeath1 text-light">Google Ads Agency India</h4>
            <p className="repeatp1">
              Google Ads is only platform to generate <strong>hot leads</strong>{" "}
              for business or to reach customer who are searching for your
              business. <strong>Google ads are high in demand</strong> &amp;
              require <strong>expertise in Google Ads</strong> to get
              cost-effective conversion in your competitive market.
            </p>
            <p className="repeatp1">
              So, you can Choose the right campaign types depends on your
              business and objectives.
            </p>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn "
                  >
                    Google Search Ads
                    {/* <br /> */}
                  </button>{" "}
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                  >
                    Shopping Ads
                  </button>
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                  >
                    Performance Max
                  </button>
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                  >
                    Discovery Ads
                  </button>
                </div>
                {/* <ul>
                  <li>Google Search Ads</li>
                  <li>Shopping Ads</li>
                  <li>Performance Max</li>
                  <li>Discovery Ads</li>
                </ul> */}
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="">
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn "
                  >
                    Google Display Ads
                    {/* <br /> */}
                  </button>{" "}
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                  >
                    Remarketing Ads
                  </button>
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                  >
                    Apps Installation Ads
                  </button>
                  <button
                    style={{
                      width: "280px",
                      color: "white",
                      fontSize: "11px",
                      padding: "5px 0px",
                      backgroundColor: "#112D52",
                      height: "40px",
                    }}
                    className="svg2 navbuttonn"
                  >
                    Youtube advertising
                  </button>
                </div>
                {/* <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul> */}
              </div>
            </div>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn  mt-5 text-light"
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12 py-5">
            <img
              src={googleppc}
              alt="img"
              style={{ height: "370px", width: "100%", borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
      <div className="container my-4">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1 text-center">
              WHY HIRE A GOOGLE ADS AGENCY?
            </h1>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-6 col-sm-12">
            <h4 className="repeath4">1. CHALLENGES TO BUSINESS OWNER</h4>
            <p className="repeatp1">
              Google ads is most advance performance marketing that keep
              evolving with new technologies and market trends. Each month
              Google launch new updates, Integrating AI & machine learning. It
              make Google ads more complex than before to learn it. A business
              owners find it difficult to-
            </p>
            <p className="repeatp1">
              {" "}
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              Learn & gain expertise on Google Ads.
            </p>
            <p className="repeatp1">
              {" "}
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              Keep himself updated with new technologies & updates.
            </p>
            <p className="repeatp1">
              {" "}
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              Lack of time to manage & monitors ad’s campaign.
            </p>
            <p className="repeatp1">
              {" "}
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              High cost of hiring in-house Google Ads Expert
            </p>
            <p className="repeatp1">
              {" "}
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              No support if result are not coming & money is being wasted Many
              other challenges by different businesses.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <h4 className="repeath4">2. HELP FROM GOOGLE ADS AGENCIES</h4>
            <p className="repeatp1">
              A Google Ads agency like XtremeAds, addresses the concerns of
              business owners seeking efficient Google Ads campaigns while
              maintaining cost-effectiveness. The advantages of partnering with
              a Google Ads agency in India include:
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              <b>Significant Cost Savings: </b>
              Save up to 70% on hiring a Google Ad Expert.
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              <b>Expert Team Support: </b>
              Benefit from the expertise of a Google Ads team.
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              <b>Continuous Monitoring:</b>
              Ads receive round-the-clock monitoring to ensure optimal
              performance.
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              <b>Timely Reports and Consultation:</b>
              Access on-time reports, free consultations, and engage in
              productive video meetings.
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              <b>Real-time Updates:</b>
              Keep your campaigns and ads up-to-date with the latest Google
              updates for sustained effectiveness.
            </p>
            <p className="repeatp1">
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#FBAD18" }}
              />{" "}
              <b>Flexibility:</b>
              No long-term commitments needed. There are many other benefits of
              hiring a professional Google ads agency.
            </p>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-6 col-sm-12 pt-5">
            <h4 className="repeath4">
              WHICH TYPES OF GOOGLE ADS WILL WORK FOR YOU?
            </h4>
            <p className="repeatp1">
              For different goals: pick Search Ads for direct searches, Display
              Ads to show your brand widely, Video Ads for engaging content, and
              Shopping Ads for products you sell.
            </p>
            <div
              class="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    For Lead Generation
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Google ads are best of lead generation. For highly qualified
                    leads, Search Ads are best. Other Ads that can be used are
                    YouTube search ads, dynamic search ads etc.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    For eCommerce Sites
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Shopping ads are most preferred type of Google ads. Now they
                    changed to performance max that use Google smart bidding &
                    automation to bring more sales
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Mobile App Promotion
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    If your business has a mobile app on Android or iOS, then
                    you can try Google ads universal mobile ads which can help
                    you get more installation as well as increase engagement.
                    This is cheap and completely measurable.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    YouTube Video Ads
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    You can use YouTube Video ads to get more views on your
                    videos, subscribers and awareness. Multiple types of are ads
                    used by advertisers depends upon goal, budget, targeting
                    location, number of services or products.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={google2}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath4">WHO SHOULD USE IT & REQUIREMENTS?</h1>
            <p className="repeatp1">
              Any type of business that are legal & ethical can use Google ads.
              If your customer spend time online or search in Google, then you
              can advertise with Google ads. Individual, small, medium or Large
              company use Google ads to generate leads, branding, selling
              products or promoting your YouTube channels. Google ads is a
              costly affair and so you need to keep everything perfectly. Here
              are few basic requirements & important suggestions.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="repeatp3">
              <span style={{ color: "#FBAD18" }}>01</span> You need to have a
              website.
            </p>
            <p className="repeatp3">
              <span style={{ color: "#FBAD18" }}>02</span> Ethical & legal
              business. Google do not allow un-ethical business.
            </p>
            <p className="repeatp3">
              <span style={{ color: "#FBAD18" }}>03</span> Your website/business
              must adhere to its policies
            </p>
            <p className="repeatp3">
              <span style={{ color: "#FBAD18" }}>04</span> A monthly marketing
              budget.
            </p>
            <p className="repeatp3">
              <span style={{ color: "#FBAD18" }}>05</span> A Google ads account
              (free to signup)
            </p>
          </div>
        </div>
        <div className="row my-4">
          <div className="col--sm-12">
            <h1 className="repeath1 text-center">SUCCESS STORIES</h1>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-lg-4 col-md-6 col-sm-12 py-4 ">
            <img
              src={google3}
              alt="img"
              style={{ height: "40px", width: "77px" }}
            />
            <h4 className="repeath4 text-center">STOREMYGOODS</h4>
            <p className="repeatp1 text-center">
              Valued client received funding from SHARK TANK India. By managing
              their advertising campaign, we have secured an impressive 85% lead
              at an exceptional CPA.
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 py-4  socialborder  ">
            <img
              src={google4}
              alt="img"
              style={{ height: "40px", width: "77px" }}
            />
            <h4 className="repeath4 text-center">FLYINGCOLOUR</h4>
            <p className="repeatp1 text-center">
              The number of conversions increased by 98%, meaning we had almost
              twice as many successful outcomes. Additionally, CPA decreased by
              25%.
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 py-4 ">
            <img
              src={google5}
              alt="img"
              style={{ height: "40px", width: "77px" }}
            />
            <h4 className="repeath4 text-center">DUSTIN PITTS</h4>
            <p className="repeatp1 text-center">
              This one is our real estate project from the USA & we are running
              their ads. We have got 110% leads or conversions in two weeks
              itself with quite good CPA.
            </p>
          </div>
        </div>
      </div>
      <LetsTalk />
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="repeath1">FAQ</h1>
            <p className="repeatp1 text-center">
              Answers to common inquiries about our Google Ad management
              services in India.
            </p>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-4 col-sm-12 text-center d-flex align-items-center">
            <img
              src={google6}
              alt="img"
              style={{ height: "60px", width: "60px" }}
            />
            <h1 className="repeath6 mx-3">GOOGLE ADS SERVICES</h1>
          </div>
          <div className="col-md-8 col-sm-12">
            <div
              class="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    How can Google Ads benefit my business in India?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Google Ads can help increase your online visibility, drive
                    targeted traffic to your website, and generate potential
                    leads and conversions from the Indian audience.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What if I need to change my shipping address?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Certainly, we can share case studies highlighting how we’ve
                    helped businesses in India achieve higher click-through
                    rates (CTRs), improved conversion rates, and increased ROI
                    through Google Ads. Read our Google ads case studies.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    What reporting and analytics do you provide for Google Ads
                    in India?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    We offer detailed performance reports showcasing key metrics
                    such as impressions, clicks, conversions, and return on
                    investment (ROI) to provide clear insights into your
                    campaign’s success.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row my-4">
          <div className="col-md-4 col-sm-12 text-center d-flex align-items-center">
            <img
              src={google7}
              alt="img"
              style={{ height: "60px", width: "60px" }}
            />
            <h1 className="repeath6 mx-3">GOOGLE ADS COST</h1>
          </div>
          <div className="col-md-8 col-sm-12">
            <div
              class="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseONE"
                    aria-expanded="false"
                    aria-controls="flush-collapseONE"
                  >
                    What budget range should I consider for Google Ads services
                    in India?
                  </button>
                </h2>
                <div
                  id="flush-collapseONE"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Budgets vary based on your business goals, competition, and
                    target audience. We’ll work closely with you to determine a
                    budget that aligns with your objectives.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTWO"
                    aria-expanded="false"
                    aria-controls="flush-collapseTWO"
                  >
                    Can I choose how much I want to spend on Google Ads in
                    India?
                  </button>
                </h2>
                <div
                  id="flush-collapseTWO"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Yes, you can set a daily or monthly budget that suits your
                    financial goals. Google will try to stay within this budget
                    while showing your ads.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTHREE"
                    aria-expanded="false"
                    aria-controls="flush-collapseTHREE"
                  >
                    Are there any hidden costs or fees for using Google Ads in
                    India?
                  </button>
                </h2>
                <div
                  id="flush-collapseTHREE"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Google Ads operates on a pay-per-click model, so you’re
                    charged only when someone interacts with your ad. There are
                    no hidden fees.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-4">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="repeath1 text-center">Our Google-PPC-ads Package</h1>
          </div>
        </div>
        <div className="row">
          {plan?.map((e, index) => (
            <>
              <div
                key={index}
                className="col-lg-3 col-md-6 col-sm-12 text-center"
              >
                <div key={index} className="youtubeseo button-container">
                  <div>
                    {" "}
                    <h1 className="button2 repeath1">{e.type}</h1>
                    <div
                      style={{
                        backgroundColor: "#112D52",
                        padding: "10px 0",
                        color: "white",
                      }}
                    >
                      <h2>
                        {/* <FontAwesomeIcon
                          icon={faDollarSign}
                          style={{ fontSize: "15px" }}
                        /> */}
                        {e.price}
                      </h2>
                      <p>Per Month</p>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <b>
                      Upto {e.p1} Keyword tracked weekly {e.videos}
                    </b>
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p2} pages optimised </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p3} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p4} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p5} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p6} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p7} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p8} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p9} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>

                  <button onClick={() => handleShow(e)} className="ytbtn">
                    I want to grow
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="https://oshnbrand.com/static/media/logo.fbd43d01225aac439771.jpg"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6>Google-PPC-Ads</h6>
                </div>
                <form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Googleads;
