import React, { useState, useEffect } from "react";
import Layout from "../../comp/layout";
import { Link, useNavigate } from "react-router-dom";
import website2 from "../../img/websitedev3.webp";
import website3 from "../../img/websitedev3.webp";
import websitedev1 from "../../img/websitedev1.webp";
import website4 from "../../img/website4.1.jpg";
import website5 from "../../img/website5.webp";
import website6 from "../../img/website6.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarChart,
  faCog,
  faCreditCard,
  // faCross,
  faFemale,
  faLocationDot,
  faLock,
  faMessage,
  faPieChart,
  faPlus,
  faShield,
  faShoppingCart,
  faThumbsUp,
  // faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import LetsTalk from "../../comp/LetsTalk";

const Website = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/plan/singleplan/Website-Development"
      );
      setPlan(data?.data);
      // console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/query/addquery",
        {
          title: "Website-Development",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };

  return (
    <Layout title={"Oshn Brand Website-Development"}>
      <ToastContainer />

      <div
        className="container-fluid "
        style={{ backgroundColor: "#112D52", color: "white" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                WEBSITE-DEVELOPMENT
              </Link>{" "}
            </p>
            <h1 style={{ fontSize: "38px" }}>
              #1 Website Development Agency From ASIA & USA
            </h1>
            {/* <p style={{ fontSize: "24px", fontWeight: "500" }} className="mt-4">
              Add Value to Your Brand
            </p> */}
            <button
              style={{
                width: "fit-content",
                color: "white",
                padding: "0 10px",
              }}
              className="svg2 navbuttonn mt-5"
              onClick={() => {
                navigate("/grow-with-us");
              }}
            >
              I Want To Grow
            </button>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={websitedev1}
              alt="img"
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "20px",
                // boxShadow: "0px 0px 20px #FBAD18",
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            {/* <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/6v3Ny8bqGSM?si=1LQkINj-JffqHDzi"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe> */}
            <img
              src={website2}
              alt="img"
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "20px",
                // boxShadow: "0px 0px 20px #FBAD18",
              }}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1 className="whyh">Great Websites to Elevate Your Brand</h1>
            <p className="whyp">
              We are web designers. Our job is to add value to your brand with
              professional web design services. Through quality design, we will
              transform your website into an elegant sales machine that
              communicates your message and converts visitors into customers.
            </p>
            <button
              style={{
                width: "fit-content",
                color: "white",
                padding: "0 10px",
              }}
              className="svg2 navbuttonn"
            >
              Free Consultant
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="whyh">
              Get a Professional Design – Not Just a Pretty Face
            </h1>
            <p className="whyp">
              Having a nice-looking website is important, but that’s not the
              be-all and end-all of professional website design.
            </p>
            <p className="whyp">
              Your competitors are just a click away. That makes it crucial for
              your business to have a professional website whose design is more
              than just skin deep. We can do that.
            </p>
            <p className="whyp">
              Half of the web users expect the website to load in less than two
              seconds.
            </p>
            <p className="whyp">
              Designing websites is a craft. They have to look good, load
              quickly and possess all the technical features your small business
              demands. Our designs engage visitors and encourage them to buy
              from you and come back to shop more. The results, as our clients
              tell us, are outstanding.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              alt="img"
              src={website3}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin ">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img
              style={{ height: "100%", width: "100%" }}
              src={website4}
              alt="img"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1
              className="repeath1"
              style={{ fontSize: "36px", fontWeight: "700" }}
            >
              Benefits of a Professionally Designed Responsive Website
            </h1>
            <p
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "300",
                textAlign: "justify",
              }}
            >
              A well-designed site is more than eye-candy; it has the technical
              infrastructure to be functional, and it solves a problem which can
              be educating your clients or increasing sales or both.
            </p>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ paddingTop: "5px", margin: "0 10px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",

                      paddingLeft: "5px",
                    }}
                  >
                    Improved SEO and Site Performance
                  </p>
                </div>
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ paddingTop: "5px", margin: "0 10px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Simplify the User Experience
                  </p>
                </div>{" "}
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ paddingTop: "5px", margin: "0 10px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",

                      paddingLeft: "5px",
                    }}
                  >
                    Refocus Your Content Strategy
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ paddingTop: "5px", margin: "0 10px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Give Your Site A Facelift
                  </p>
                </div>
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ paddingTop: "5px", margin: "0 10px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Offer Faster Load Speeds
                  </p>
                </div>
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ paddingTop: "5px", margin: "0 10px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Provide Up to Date Security
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row text-center bg-primary text-light py-4">
          <div className="col-md-6 col-sm-12 px-5">
            <h1>Is Your Website SEO Friendly?</h1>
            <p className="repeatp1">
              Get a Free Full Website Performance Audit Today!
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            {/* <button className="why navbuttonn">LET'S GO!</button> */}
            {/* <button
              style={{
                width: "fit-content",
                color: "white",
              }}
              className="svg2 navbuttonn"
            >
              AUDIT MY WEBSITE
            </button> */}

            <div>
              <button
                type="button"
                className="btn btn-primary why navbuttonn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Audit My Website
              </button>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" style={{ width: "380px" }}>
                  <div className="modal-content">
                    <div className="modal-header bg-dark">
                      <h1 className="modal-title" id="exampleModalLabel">
                        Modal title
                      </h1>
                      <button
                        type="button"
                        className="btn-close bg-light"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body" style={{ width: "100%" }}>
                      <form className="form">
                        <label>
                          <input
                            required
                            placeholder
                            type="text"
                            className="input"
                          />
                          <span>Firstname</span>
                        </label>
                        <label>
                          <input
                            required
                            placeholder
                            type="text"
                            className="input"
                          />
                          <span>Lastname</span>
                        </label>

                        <label>
                          <input
                            required
                            placeholder
                            type="email"
                            className="input"
                          />
                          <span>Email</span>
                        </label>

                        <button className="submit">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <LetsTalk /> */}
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faShoppingCart} />
              <div>
                <h3 className="repeath4">It Helps Your Customers Shop</h3>
                <p className="repeatp1">
                  A website is like a traditional brick-and-mortar store. You
                  have to have your products arranged in neatly defined
                  sections—books, electronics, apparel, and accessories. If your
                  customers can’t find it, they will not buy it. Professional
                  website design focuses on navigation; making it easier for
                  buyers to shop.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faPieChart} />
              <div>
                <h3 className="repeath4">It’s Scalable, Like Your Business</h3>
                <p className="repeatp1">
                  Businesses are not static. They grow. Professional web design
                  leaves a scope for growth. A new category of products or
                  services can be easily added without reprogramming the entire
                  website.
                </p>
              </div>
            </div>{" "}
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faLocationDot} />
              <div>
                <h3 className="repeath4">
                  It Sells Your Products Anywhere, Anytime
                </h3>
                <p className="repeatp1">
                  Imagine catering to a client using her smartphone to shop from
                  your online store at 2 a.m. A professionally designed website
                  can handle that. It makes money for you even when you sleep.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faShield} />
              <div>
                <h3 className="repeath4">It Builds Trust</h3>
                <p className="repeatp1">
                  Trust is a significant factor in online selling. A functional
                  website that is easy to navigate and is pleasing to look at
                  goes a long way in inspiring customers to trust you and take
                  out their wallets to buy from you.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faLock} />
              <div>
                <h3 className="repeath4">It’s Secure</h3>
                <p className="repeatp1">
                  A poorly designed website relying on outdated practices to
                  achieve some visual effects can be hacked; costing you
                  anywhere from a few dollars to a few thousand. A well-designed
                  website uses best industry practices to safeguard you and your
                  customers’ privacy.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  marginLeft: "100px",
                }}
                className="svg2 navbuttonn"
              >
                Why Oshn Brand?
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div
          className="row"
          style={{ backgroundColor: "#225D89", color: "white" }}
        >
          <div className="col-md-5 col-sm-12 close">
            <img
              style={{ height: "100%", width: "100%" }}
              src={website5}
              alt="img"
            />
          </div>
          <div className="col-md-7 col-sm-12 pt-5">
            <h1
              style={{ color: "white", paddingTop: "100px" }}
              className="repeath1"
            >
              Get a Great Website Design at an Affordable Price!
            </h1>
            <p
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: "300",
                padding: "30px 0",
                textAlign: "justify",
              }}
            >
              We specialize in web design and development that represents your
              brand and conveys your message in the strongest way possible. The
              websites we build are customized to fit your business model and
              maximize sales.
            </p>
            <p
              style={{
                fontSize: "24px",
                lineHeight: "24px",
                fontWeight: "300",
                textAlign: "justify",
              }}
            >
              At Foxxr Digital Marketing, we understand how vital budget
              considerations can be for organizations. We offer several
              affordable packages to help your organization get a website up and
              running without breaking your bank. For local businesses, there is
              no better web design agency in St. Petersburg than Foxxr Digital
              Marketing!
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <h1 className="text-center repeath1">
          Advantages of Collaborating With Us
        </h1>
        <p className="text-center repeatp1">
          Team up with Foxxr Digital Marketing to get your business website up
          and running. There are many benefits of partnering with us for your
          next web designing project.
        </p>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faFemale} />
              <div>
                <h3 className="repeath4">Creative Designers</h3>
                <p className="repeatp1">
                  Foxxr Digital Marketing’s designers have experience of working
                  in many industry verticals. They can draw on their long
                  experience to create uniquely powerful websites for you.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faCog} />
              <div>
                <h3 className="repeath4">Quick Development</h3>
                <p className="repeatp1">
                  Compared to 100% hand-coded websites, WordPress website design
                  and development takes remarkably lesser time. Once the design
                  has been finalized, the road to fruition is short.
                </p>
              </div>
            </div>{" "}
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faBarChart} />
              <div>
                <h3 className="repeath4">Experience in Online Marketing</h3>
                <p className="repeatp1">
                  We create websites from a marketer’s perspective. That means
                  they are not just pretty, but incredibly easy to optimize for
                  search engines and quickly adapt to other methods of online
                  marketing.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faThumbsUp} />
              <div>
                <h3 className="repeath4">Low Costs</h3>
                <p className="repeatp1">
                  Low Costs WordPress is open source. Many WordPress plugins and
                  themes are available for use at no cost. Even the ones that
                  are available for a price are affordable.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faCreditCard} />
              <div>
                <h3 className="repeath4">Total Control to You</h3>
                <p className="repeatp1">
                  We understand that you will most likely want to update your
                  website and post to your blog. WordPress is great for
                  businesses, providing an easy-to-use interface for making
                  website additions and changes.
                </p>
              </div>
            </div>
            <div className="d-flex">
              <FontAwesomeIcon className="fonticon" icon={faMessage} />
              <div>
                <h3 className="repeath4">Reliable Support</h3>
                <p className="repeatp1">
                  We are confident of our technical capabilities and stand
                  behind our work. Should you face an issue or want an upgrade,
                  you can call us or email us, and we will be glad to assist
                  you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid margin ">
        <div className="row">
          <div className="col-md-7 col-sm-12">
            <img
              style={{ height: "100%", width: "100%" }}
              src={website6}
              alt="img"
            />
          </div>
          <div className="col-md-5 col-sm-12">
            <h1 className="repeath1">Get a Website and Grow Your Business</h1>
            <p
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                fontWeight: "300",
              }}
            >
              Get a professionally designed website. Call us at (727) 379-2207
              for a no-cost consultation and professional web services.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid my-4">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="repeath1 text-center">
              Our Website-Development Package
            </h1>
          </div>
        </div>
        <div className="row">
          {plan?.map((e, index) => (
            <>
              <div
                key={index}
                className="col-lg-4 col-md-6 col-sm-12 text-center"
              >
                <div key={index} className="youtubeseo button-container">
                  <div>
                    {" "}
                    <h1 className="button2 repeath1">{e.type}</h1>
                    <div
                      style={{
                        backgroundColor: "#112D52",
                        padding: "10px 0",
                        color: "white",
                      }}
                    >
                      <h3>{e.price}</h3>
                      <p>Per Month</p>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <b>{e.p1}</b>
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p2} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p3} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p4} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p5} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p6} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p7} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p8} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p9} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p10} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p11} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p12} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p13} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p14} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p15} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p16} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p17} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p18} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p19} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p20} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>{e.p} </b>
                    <FontAwesomeIcon
                      icon={e.icon}
                      style={{ color: e.color, fontSize: "18px" }}
                    />
                  </p>

                  <button onClick={() => handleShow(e)} className="ytbtn">
                    I want to grow
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="https://oshnbrand.com/static/media/logo.fbd43d01225aac439771.jpg"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6>Website-Development</h6>
                </div>
                <form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default Website;
