import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../img/logo.jpg";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [product, setProduct] = useState([]);
  const [isloading, setIsLoading] = useState(false);

  const [showSideNav2, setShowSideNav2] = useState(false);

  const fetchProducts = async () => {
    try {
      const { data } = await axios.get(
        `https://oshnbrand.com:8090/api/category/view`
      );
      if (data?.success) {
        setProduct(data?.data);
        // console.log(product);
      } else {
        console.error("Failed to fetch products:", data?.error);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true); // Show SideNav2 when the window width is less than or equal to 768px
        setDrawerOpen(false); // Close the main drawer when showing SideNav2
      } else {
        setShowSideNav2(false); // Hide SideNav2 when the window width is greater than 768px
        setDrawerOpen(true); // Open the main drawer when hiding SideNav2
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [isloading]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img src={logo} alt="logo" className="dashlogo" />
          </Typography>
          <div className="ms-auto">
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faPerson}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <div>
                    {" "}
                    ADMIN
                    <br />
                    {auth?.admin?.name}
                  </div>
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem
                  onClick={() => {
                    navigate("/dashboard/admin/profile");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPerson}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Profile
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    navigate(`/dashboard/admin/profile/${auth?.admin?._id}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Profile
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Sign Out
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>Something else here</MenuItem>
                <MenuItem onClick={handleClose}>Separated link</MenuItem> */}
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? 250 : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 250 : 50,
            boxSizing: "border-box",
            transition: "width 0.3s",
            paddingTop: "100px",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {drawerOpen && !showSideNav2 ? <SideNav /> : <SideNav2 />}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerOpen ? 250 : 50}px)`,
          transition: "width 0.3s",
        }}
      >
        <Toolbar />
        <Typography variant="p" component="div" sx={{ flexGrow: 1, mb: 3 }}>
          Dashboard / Products
        </Typography>

        <Box mt={3}>
          <Grid container spacing={2}>
            {product.map((e, index) => (
              <Grid item xs={12} sm={6} md={4} padding={4} key={index}>
                <Box
                  sx={{
                    backgroundColor: "green",
                    padding: 1,
                    borderRadius: 1,
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <Typography variant="h6">{e.name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Products;
