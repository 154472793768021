import React, { useState } from "react";
import axios from "axios";
import Layout from "../comp/layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Landingpage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    businessNumber: "",
    country: "",
    services: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      const updatedServices = checked
        ? [...prevData.services, value]
        : prevData.services.filter((service) => service !== value);
      return {
        ...prevData,
        services: updatedServices,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/landing/addData",
        formData
      );
      console.log("Data sent successfully:", response.data);
      toast.success(
        "Your request is very important to us we will contact you back soon"
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("something went wrong");
    }
  };
  const countries = [
    "Country *",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  const img = [
    { img: "./img/landing2.webp" },
    { img: "./img/landing3.webp" },
    { img: "./img/landing4.webp" },
    { img: "./img/landing5.png" },
  ];
  return (
    <Layout title={"Contact-Us-Oshn Brand"}>
      <ToastContainer />
      <div className="container-fluid landingmain ">
        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <div className="row">
              <div className="col-sm-12 p-3">
                <p
                  className="abthomeh"
                  style={{ fontSize: "28px", fontWeight: "500" }}
                >
                  OFFICE ADDRESS
                </p>
                <p className="abthomep"></p>
                <p className="abthomeh">Working Address - Delhi [NCR] </p>
                <p className="abthomep">
                  Sco-27 second floor, near Mother dairy, Huda Market, Sector
                  7a, Block E, Sector 7, Faridabad, Haryana 121006
                </p>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12 p-3">
                  <p
                    className="abthomeh"
                    style={{ fontSize: "28px", fontWeight: "500" }}
                  >
                    <FontAwesomeIcon icon={faPhone} /> CALL
                  </p>
                  <p className="abthomeh">+91 90159 37199</p>
                  {/* <p className="abthomeh">+91 90159 37199</p> */}
                </div>
                <div className="col-md-6 col-sm-12 p-3">
                  <p
                    className="abthomeh"
                    style={{ fontSize: "28px", fontWeight: "500" }}
                  >
                    <FontAwesomeIcon icon={faMailBulk} /> EMAIL
                  </p>
                  <p className="abthomeh">sales@oshnbrand.com</p>
                  <p className="abthomep">info.india@oshnbrand.com</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <div className="container2">
              <form className="form2" onSubmit={handleSubmit}>
                <div className="descr">Contact us</div>

                <div className="input">
                  <input
                    required
                    autoComplete="off"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <label>First Name *</label>
                </div>

                <div className="input">
                  <input
                    required
                    autoComplete="off"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  <label>Last Name *</label>
                </div>

                <div className="input">
                  <input
                    required
                    autoComplete="off"
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                  <label>Company *</label>
                </div>

                <div className="input">
                  <input
                    required
                    autoComplete="off"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <label>Business Email</label>
                </div>

                <div className="input">
                  <input
                    required
                    autoComplete="off"
                    type="number"
                    name="businessNumber"
                    value={formData.businessNumber}
                    onChange={handleChange}
                  />
                  <label>Business Number *</label>
                </div>

                <div className="input">
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    style={{
                      border: "none",
                      borderBottom: "2px solid skyblue",
                      borderRadius: "5px",
                      width: "100%",
                      margin: "5px 0",
                      padding: "8px",
                      outline: "none",
                      color: "gray",
                    }}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {/* <label>Country</label> */}
                </div>

                <label className="text-secondary text-light">
                  Select a service
                </label>
                <div className="d-flex gap-3 my-3 flex-wrap text-dark">
                  {[
                    "Local-Seo",
                    "Enterprise-Seo",
                    "Youtube-Seo",
                    "ORM",
                    "Meta Ads",
                    "LinkedIn Ads",
                    "Google PPC Ads",
                    "Social Media Marketing",
                    "Website Development",
                    "App Development",
                    "Software Development",
                    "UI/UX Design",
                  ].map((service) => (
                    <label className="text-secondary" key={service}>
                      <input
                        type="checkbox"
                        className="mx-1"
                        value={service}
                        checked={formData.services.includes("abc")}
                        onChange={handleServiceChange}
                      />
                      {service}
                    </label>
                  ))}
                </div>

                <div className="row">
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      name="consent"
                      checked={formData.consent}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-sm-11">
                    <p style={{ fontSize: "10px", color: "gray" }}>
                      Yes, I'd like to receive marketing communications. By
                      opting in, you also agree to the storing and processing of
                      your personal data as described in our Privacy Statement.
                    </p>
                  </div>
                </div>

                <button
                  style={{
                    boxShadow: "0px 0px 10px gray",
                    borderRadius: "5px",
                  }}
                  type="submit"
                >
                  Send message →
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ height: "400px", width: "100%", margin: "30px 0" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.851321564779!2d77.32353657415669!3d28.36334429625639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd8a3acaef43%3A0xff5d5fabf55d2e19!2sdevFox%20Technology!5e0!3m2!1sen!2sin!4v1720506615563!5m2!1sen!2sin"
          style={{ height: "100%", width: "100%" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        />
      </div> */}

      {/* <div className="container-fluid margin landing2">
        <div className="row my-5">
          <div className="col-md-6 col-sm-12">
            <h1>
              How companies{" "}
              <span style={{ fontWeight: "800" }}>drive success</span> with Oshn
              Brand
            </h1>
            <h6>
              // <FontAwesomeIcon
              //   icon={faPlus}
              //   style={{
              //     color: "#FBAD18",
              //     margin: "0 10px",
              //     fontSize: "20px",
              //     fontWeight: "bolder",
              //   }}
              // />
              Prospect Engine
            </h6>
            <p>
              Uncover the best-fit, highest-intent accounts and contacts for
              your sales team. Pipeline problem solved.
            </p>
            <h6>
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  color: "#FBAD18",
                  margin: "0 10px",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
              />
              Best in Class Advertising
            </h6>
            <p>
              Drive higher ROAS with leading account-based and programmatic ads.
            </p>
            <h6>
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  color: "#FBAD18",
                  margin: "0 10px",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
              />
              Data Studio
            </h6>
            <p>
              Target and segment your ICP with firmographic, intent, and more.
              Build a best-in-class first party data asset that doesn’t rely on
              cookies.
            </p>
            <h6>
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  color: "#FBAD18",
                  margin: "0 10px",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
              />
              Engagement Channels
            </h6>
            <p>
              Multiple channels of engagement native to Oshn Brand allows you to
              create connected account experiences that will increase intent,
              engagement and drive pipeline.
            </p>
            <h6>
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  color: "#FBAD18",
                  margin: "0 10px",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
              />
              Measurement Studio
            </h6>
            <p>
              Analyze the impact of all your go-to-market activities from
              campaign-specific reporting all the way up to board-level
              dashboards with a user-friendly analytics and attribution engine.
            </p>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              alt="img"
              src={landing1}
              style={{ height: "70%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin landing3">
        <div className="row">
          <div className="col-sm-12">
            <p>
              Thousands of companies have already put Oshn Brand at the center
              of their revenue strategy
            </p>
            {img.map((e, index) => (
              <>
                <div
                  key={index}
                  style={{
                    display: "inline",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <img
                    alt="img"
                    src={e.img}
                    style={{ height: "40px", width: "160px", margin: "20px" }}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      </div> */}
    </Layout>
  );
};

export default Landingpage;
