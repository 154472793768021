import React from "react";
import Layout from "../../comp/layout";
import { useNavigate, Link } from "react-router-dom";
import "../sol.css";
import app1 from "../../img/app1.webp";
import app12 from "../../img/app12.webp";
import app13 from "../../img/app13.png";
import app14 from "../../img/app14.png";
import app15 from "../../img/app15.webp";
import app16 from "../../img/app16.png";
import app17 from "../../img/app17.webp";
import app18 from "../../img/app18.webp";
import app19 from "../../img/app19.png";
import app20 from "../../img/app20.png";
import app21 from "../../img/app21.png";
import app22 from "../../img/app22.png";
import app23 from "../../img/app23.webp";
import app24 from "../../img/app24.webp";
import app25 from "../../img/app25.png";
import app26 from "../../img/app26.png";
import app27 from "../../img/app27.webp";
import app2 from "../../img/app2.png";
import app3 from "../../img/app3.png";
import app4 from "../../img/app4.png";
import app5 from "../../img/app5.png";
import app6 from "../../img/app6.png";
import app7 from "../../img/app7.png";
import app8 from "../../img/app8.png";
import app9 from "../../img/app9.png";
import appbg from "../../img/appdev.avif";
import ChatForm from "../../comp/LetsTalk";

const Appdev = () => {
  const navigate = useNavigate();
  // const countries = [
  //   "Country *",
  //   "Afghanistan",
  //   "Albania",
  //   "Algeria",
  //   "Andorra",
  //   "Angola",
  //   "Antigua and Barbuda",
  //   "Argentina",
  //   "Armenia",
  //   "Australia",
  //   "Austria",
  //   "Azerbaijan",
  //   "Bahamas",
  //   "Bahrain",
  //   "Bangladesh",
  //   "Barbados",
  //   "Belarus",
  //   "Belgium",
  //   "Belize",
  //   "Benin",
  //   "Bhutan",
  //   "Bolivia",
  //   "Bosnia and Herzegovina",
  //   "Botswana",
  //   "Brazil",
  //   "Brunei",
  //   "Bulgaria",
  //   "Burkina Faso",
  //   "Burundi",
  //   "Côte d'Ivoire",
  //   "Cabo Verde",
  //   "Cambodia",
  //   "Cameroon",
  //   "Canada",
  //   "Central African Republic",
  //   "Chad",
  //   "Chile",
  //   "China",
  //   "Colombia",
  //   "Comoros",
  //   "Congo (Congo-Brazzaville)",
  //   "Costa Rica",
  //   "Croatia",
  //   "Cuba",
  //   "Cyprus",
  //   "Czechia (Czech Republic)",
  //   "Democratic Republic of the Congo",
  //   "Denmark",
  //   "Djibouti",
  //   "Dominica",
  //   "Dominican Republic",
  //   "Ecuador",
  //   "Egypt",
  //   "El Salvador",
  //   "Equatorial Guinea",
  //   "Eritrea",
  //   "Estonia",
  //   "Eswatini (fmr. 'Swaziland')",
  //   "Ethiopia",
  //   "Fiji",
  //   "Finland",
  //   "France",
  //   "Gabon",
  //   "Gambia",
  //   "Georgia",
  //   "Germany",
  //   "Ghana",
  //   "Greece",
  //   "Grenada",
  //   "Guatemala",
  //   "Guinea",
  //   "Guinea-Bissau",
  //   "Guyana",
  //   "Haiti",
  //   "Holy See",
  //   "Honduras",
  //   "Hungary",
  //   "Iceland",
  //   "India",
  //   "Indonesia",
  //   "Iran",
  //   "Iraq",
  //   "Ireland",
  //   "Israel",
  //   "Italy",
  //   "Jamaica",
  //   "Japan",
  //   "Jordan",
  //   "Kazakhstan",
  //   "Kenya",
  //   "Kiribati",
  //   "Kuwait",
  //   "Kyrgyzstan",
  //   "Laos",
  //   "Latvia",
  //   "Lebanon",
  //   "Lesotho",
  //   "Liberia",
  //   "Libya",
  //   "Liechtenstein",
  //   "Lithuania",
  //   "Luxembourg",
  //   "Madagascar",
  //   "Malawi",
  //   "Malaysia",
  //   "Maldives",
  //   "Mali",
  //   "Malta",
  //   "Marshall Islands",
  //   "Mauritania",
  //   "Mauritius",
  //   "Mexico",
  //   "Micronesia",
  //   "Moldova",
  //   "Monaco",
  //   "Mongolia",
  //   "Montenegro",
  //   "Morocco",
  //   "Mozambique",
  //   "Myanmar (formerly Burma)",
  //   "Namibia",
  //   "Nauru",
  //   "Nepal",
  //   "Netherlands",
  //   "New Zealand",
  //   "Nicaragua",
  //   "Niger",
  //   "Nigeria",
  //   "North Korea",
  //   "North Macedonia",
  //   "Norway",
  //   "Oman",
  //   "Pakistan",
  //   "Palau",
  //   "Palestine State",
  //   "Panama",
  //   "Papua New Guinea",
  //   "Paraguay",
  //   "Peru",
  //   "Philippines",
  //   "Poland",
  //   "Portugal",
  //   "Qatar",
  //   "Romania",
  //   "Russia",
  //   "Rwanda",
  //   "Saint Kitts and Nevis",
  //   "Saint Lucia",
  //   "Saint Vincent and the Grenadines",
  //   "Samoa",
  //   "San Marino",
  //   "Sao Tome and Principe",
  //   "Saudi Arabia",
  //   "Senegal",
  //   "Serbia",
  //   "Seychelles",
  //   "Sierra Leone",
  //   "Singapore",
  //   "Slovakia",
  //   "Slovenia",
  //   "Solomon Islands",
  //   "Somalia",
  //   "South Africa",
  //   "South Korea",
  //   "South Sudan",
  //   "Spain",
  //   "Sri Lanka",
  //   "Sudan",
  //   "Suriname",
  //   "Sweden",
  //   "Switzerland",
  //   "Syria",
  //   "Tajikistan",
  //   "Tanzania",
  //   "Thailand",
  //   "Timor-Leste",
  //   "Togo",
  //   "Tonga",
  //   "Trinidad and Tobago",
  //   "Tunisia",
  //   "Turkey",
  //   "Turkmenistan",
  //   "Tuvalu",
  //   "Uganda",
  //   "Ukraine",
  //   "United Arab Emirates",
  //   "United Kingdom",
  //   "United States of America",
  //   "Uruguay",
  //   "Uzbekistan",
  //   "Vanuatu",
  //   "Venezuela",
  //   "Vietnam",
  //   "Yemen",
  //   "Zambia",
  //   "Zimbabwe",
  // ];
  const card = [
    {
      h: "Android App Development",
      p: "Capture a significant share of the Android app market by leveraging our robust, feature-packed, and user-friendly mobile applications. Optimize your business revenue with impactful apps using our Android app development services, backed by the expertise of top-tier app developers.",
    },
    {
      h: "iOS App Development",
      p: "Connect with high-caliber prospects through a scalable, reliable, and comprehensive iOS app crafted by our skilled and professional team. With a decade of expertise and a successful track record in developing iOS apps, we stand as a premier mobile app development company.",
    },
    {
      h: "Hybrid App Development",
      p: "Expand the scope of your digital business presence to reach users across various operating systems. Our team of experts specializes in developing cross-platform apps using the React Native framework. Android and iOS application development services are pivotal for achieving broader traffic reach and increased profitability.",
    },
  ];
  return (
    <Layout title={"Oshn Brand App-Development"}>
      <ChatForm name="App-Development" />
      <div className="container-fluid  landingmain text-light">
        <div className="row">
          <div className="col-md-6 col-sm-12 mt-4">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>{" "}
              -{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                APP-DEVELOPMENT
              </Link>{" "}
            </p>
            <h1 style={{ color: "white", fontSize: "36px" }} className="my-4">
              We Develop Feature Rich Mobile Apps Where Creative Chaos Meets
              Elegant User Experience
            </h1>
            <div className="">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn "
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                High Quality UI App Design
                {/* <br /> */}
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Increase Customer Experience
              </button>
            </div>
            <div className="mt-4">
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Increase Customer Engagement
              </button>{" "}
              <button
                style={{
                  width: "280px",
                  color: "white",
                  fontSize: "11px",
                  padding: "5px 0px",
                  backgroundColor: "#005589",
                  height: "40px",
                }}
                className="svg2 navbuttonn"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Manage Brand Reputation
              </button>
            </div>
            <button
              onClick={() => {
                navigate("/grow-with-us");
              }}
              className="why navbuttonn  mt-5 text-light"
            >
              I Want To Grow
            </button>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "20px",
                boxShadow: "0px 0px 10px #1a1a1a",
              }}
              src={appbg}
              alt="img"
            />
            {/* <div
              style={{
                backgroundColor: "white",
                padding: "10px 20px",
                width: "80%",
              }}
            >
              <input
                type="text"
                placeholder="First Name *"
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="text"
                placeholder="Last Name *"
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="text"
                placeholder="Company *"
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="email"
                placeholder="Business Email"
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <input
                type="number"
                placeholder="Business Number *"
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <select
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              >
                <option selected>Open this select menu</option>
                <option value="1">Sales</option>
                <option value="2">HR</option>
                <option value="3">Billing/Finance</option>
                <option value="3">Other</option>
              </select>
              <select
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <textarea
                type="number"
                placeholder="Message *"
                style={{
                  border: "none",
                  borderBottom: "2px solid skyblue",
                  borderRadius: "5px",
                  width: "100%",
                  margin: "5px 0",
                  padding: "8px",
                  outline: "none",
                  color: "gray",
                }}
              />
              <div className="row">
                <div className="col-sm-1">
                  <input type="checkbox" />
                </div>
                <div className="col-sm-11">
                  <p style={{ fontSize: "10px", color: "gray" }}>
                    Yes, I'd like to receive marketing communications. By opting
                    in, you also agree to the storing and processing of your
                    personal data as described in our Privacy Statement.
                  </p>
                </div>
              </div>
              <button className="landingbtn">LET'S TALK</button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row text-center">
          <div className="col-sm-12">
            <h1 className="repeath1">How can we help you?</h1>
            <p className="repeatp3">
              From app creation to expanding your business, we have all the
              capabilities you need
            </p>
          </div>
        </div>
        <div className="row" style={{ backgroundColor: "#112D52" }}>
          {card.map((e, index) => (
            <>
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 p-2">
                <div className="appdev">
                  <h1
                    className="repeath6 appdevh1"
                    style={{ fontWeight: "700", color: "white" }}
                  >
                    {e.h}
                  </h1>
                  <p className="repeatp1 text-light">{e.p}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container-fluid margin">
        <h1 className="repeath1 text-center">
          Why Choose InstaaCoders for App Development?
        </h1>
        <div
          className="row"
          style={{ backgroundColor: "#112D52", color: "white" }}
        >
          <div className="col-md-6 col-sm-12">
            <img
              style={{ height: "100%", width: "100%" }}
              src={app1}
              alt="img"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="row mt-5">
              <div className="col-md-6 col-sm-12">
                <div className="d-flex gap-3">
                  <img
                    src={app2}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",

                      paddingLeft: "5px",
                    }}
                  >
                    100% own staff, No freelancers
                  </p>
                </div>
                <div className="d-flex gap-3 mt-5">
                  <img
                    src={app3}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    India's top software engineers
                  </p>
                </div>{" "}
                <div className="d-flex gap-3 mt-5">
                  <img
                    src={app4}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",

                      paddingLeft: "5px",
                    }}
                  >
                    Easy communication
                  </p>
                </div>
                <div className="d-flex gap-3 mt-5">
                  <img
                    src={app5}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",

                      paddingLeft: "5px",
                    }}
                  >
                    Reduced TTM (Time to Market)
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="d-flex gap-3">
                  <img
                    src={app6}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Cost-effective
                  </p>
                </div>
                <div className="d-flex gap-3 mt-5">
                  <img
                    src={app7}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Flexible engagement options
                  </p>
                </div>
                <div className="d-flex gap-3 mt-5">
                  <img
                    src={app8}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Ethical & honest approach
                  </p>
                </div>
                <div className="d-flex gap-3 mt-5">
                  <img
                    src={app9}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "24px",
                    }}
                  >
                    Well defined SLAs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid appbgc text-light">
        <div className="row py-5">
          <div className="col-md-6 col-sm-12"></div>
          <div className="col-md-6 col-sm-12 p-4">
            <h1 className="repeath6">Do you have a mobile app idea?</h1>
            <h1 style={{ fontWeight: "700" }} className="repeath4 py-3">
              We have a team of{" "}
              <span style={{ fontWeight: "900" }}>
                120+ Mobile App Developers
              </span>{" "}
              to transform your vision into reality.
            </h1>
            <button
              style={{
                width: "fit-content",
                color: "white",
                padding: "0 10px",
              }}
              className="svg2 navbuttonn"
            >
              Lets Talk
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="repeath1">Technology Stack</h1>
            <p className="repeatp1">
              At InstaaCoders, we pride ourselves on being a top mobile
              application development services company that consistently pushes
              the boundaries of technology. When you partner with InstaaCoders,
              you gain access to a wealth of experience in mobile development
              services.
            </p>
            <p className="repeatp1">
              We've mastered our core technology skills through countless
              successful mobile app projects, making us adept at crafting custom
              mobile app development solutions tailored to your unique business
              requirements
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 p-2">
            <div className="p-3 applast">
              <h1>Mobile</h1>
              <p>
                InstaaCoders has track record of success in building native and
                cross-platform mobile app across all phases from design to
                development.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <div className="text-center">
                  {" "}
                  <img
                    src={app12}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Android</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app13}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Swift</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app14}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>React Native</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app15}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Xamarin</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app16}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Flutter</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app17}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Cross Platform</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 p-2">
            <div className="p-3 applast">
              <h1>Database</h1>
              <p>
                Securing customer databases by regularly reviewing
                well-established backup and recovery procedures.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <div className="text-center">
                  {" "}
                  <img
                    src={app18}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>NOSQL</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app19}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>MSSQL</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app20}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>POSTGRESQL</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app21}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>ORACLE</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app22}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>MYSQL</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app23}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>SQLITE</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 p-2">
            <div className="p-3 applast">
              <h1>QA</h1>
              <p>
                End-to-end testing and quality assurance to ensure reliable and
                glitch-free mobile app solutions for your business.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <div className="text-center">
                  {" "}
                  <img
                    src={app24}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Jmeter</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app25}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Selenium</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app26}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Cucumber</p>
                </div>
                <div className="text-center">
                  {" "}
                  <img
                    src={app27}
                    alt="img"
                    style={{ height: "50px", width: "50px" }}
                  />
                  <p>Postman</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1">Frequently Asked Questions</h1>
            <p className="repeatp3">
              Here are some questions related to app development that our
              clients frequently ask
            </p>
            <div
              class="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Why is a mobile app important for your business?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    As mobile apps drive business and generate revenue, they
                    assist enterprises to survive in a competitive environment,
                    as well as to gain a competitive advantage. With the help of
                    a reliable application development company, businesses can
                    develop a feature-rich, user-friendly, and easy-to-navigate
                    mobile app.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What Types Of Mobile App Solutions are Offered By
                    InstaaCoders
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    We provide mobile app solutions across many business
                    verticals. At InstaaCoders, we provide result-oriented and
                    business-specific mobile app solutions, including native
                    mobile app development, hybrid apps, and app integration.
                    Count on us to meet diverse & customized mobile app
                    development, design, testing, and other such requirements.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Which Framework Is Best For Mobile App development?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Choosing the framework for your mobile app development
                    depends on several factors, including the type of app you
                    want to build, the platform you want to target, and the
                    skills and resources of your development team. There are
                    several popular mobile app development frameworks that can
                    be used for building mobile apps. It includes React Native,
                    Flutter, Ionic, Xamarin, PhoneGap, and more. Depending on
                    the requirement, you can choose to have the best
                    cross-platform mobile app development framework with the
                    help of a reliable app development partner.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    What is the cost of mobile app development in 2023?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    To determine the exact cost of mobile app development in
                    2023, you must consider several factors, including the
                    complexity of your app, the platform it is being developed
                    for, and the location and experience of your development
                    team. However, on average, the cost of developing a mobile
                    app can range from $10,000 to $150,000 or more.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Which programming language is best to develop a mobile app?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    The credibility of the programming language you choose for
                    your mobile app development depends on the platform you are
                    targeting. For instance, to develop iOS apps, Swift is the
                    primary programming language, while for Android apps, Java
                    and Kotlin are the most popular programming languages.
                    Similarly, other languages like React Native and Flutter can
                    be used to build cross-platform apps.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    How long does it take to build a mobile application?
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    The time it takes to build a mobile application depends on
                    several factors, including the complexity of the app, the
                    platform it is being developed for, and the experience of
                    the development team. However, on average, it can take
                    anywhere from 4-6 months to build a mobile app, which
                    further varies based on the specific requirements of the
                    project, and the development team's expertise.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Appdev;
