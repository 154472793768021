import React from "react";
import Layout from "../comp/layout.js";
import { Link } from "react-router-dom";
import hist1 from "../img/hist1.webp";
import hist20 from "../img/hist20.png";
import hist21 from "../img/hist21.webp";
import hist22 from "../img/hist22.png";
import Carousel from "./Crousel.js";

const History = () => {
  const id = "culture";
  const id2 = "career";
  const card = [
    {
      img: "/img/hist2.png",
      p: "A company and leadership team focused on providing a “Best Place to Work” environment",
    },
    {
      img: "/img/hist3.png",
      p: "Employer subsidized health, dental, and vision insurance for employee-only coverage",
    },
    {
      img: "/img/hist4.png",
      p: "100% paid short-term and long-term disability insurance",
    },
    {
      img: "/img/hist5.png",
      p: "401(k) with company matching contributions",
    },
    {
      img: "/img/hist6.png",
      p: "Paid parental leave",
    },
    {
      img: "/img/hist7.png",
      p: "Unlimited vacation days",
    },
    {
      img: "/img/hist8.png",
      p: "2 days off each year to volunteer at a charity of your choice",
    },
    {
      img: "/img/hist9.png",
      p: "Health and wellness reimbursement",
    },
    {
      img: "/img/hist10.png",
      p: "Culture that emphasizes personal and professional growth",
    },
    {
      img: "/img/hist11.png",
      p: "An amazing group of teammates and friends",
    },
  ];
  const card2 = [
    { img: "/img/hist12.webp" },
    { img: "/img/hist13.webp" },
    { img: "/img/hist14.webp" },
    { img: "/img/hist15.webp" },
    { img: "/img/hist16.webp" },
    { img: "/img/hist17.webp" },
    { img: "/img/hist18.webp" },
    { img: "/img/hist19.webp" },
  ];

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const titles = [
    "2014",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2020",
    "2020",
    "2021",
  ];

  const descriptions = [
    `Oshn Brand Founded`,
    `FlipMyFunnel introduced to build the ABM category`,
    `Acquired Brightfunnel to power full-funnel account based analytics`,
    `Introduced the TEAM framework for ABM`,
    `Acquired Sigstr to provide email ads and relationship data`,
    `Surpassed 1,000 customers`,
    `Acquired Ramble to power account-based chat capabilities`,
    `Acquired GrowFlare to provide account intelligence`,
    `Expanded internationally, opening our first overseas office in London`,
  ];

  return (
    <Layout title={"History Oshn Brand"}>
      <div
        className="container-fluid margin"
        style={{ backgroundColor: "#104DB3", color: "white" }}
      >
        <div className="row">
          <div className="col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>
              - HISTORY
            </p>
            <h1 style={{ fontSize: "48px" }}>Our history</h1>
          </div>
        </div>
      </div>
      <p
        className="margin"
        style={{ fontSize: "24px", fontWeight: "300", lineHeight: "34px" }}
      >
        Oshn Brand is the platform that unifies your go-to-market success. As a
        pioneer in account-based marketing space, their mission is to provide
        the integrated data and tools that empower GTM teams to make informed
        decisions and optimize strategies. Customers including Imperial, AR
        Enterprise and IEC University have used Oshn Brand to build a revenue
        growth engine through first-party data, best-in-class advertising and
        multi-channel engagement platform. Oshn Brand has been a G2 leader in
        ABM for 20 consecutive quarters.
      </p>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">Non-stop growth</h1>
            <p className="repeatp3">
              Since we were founded, we’ve been on a tear – and we’re only
              moving faster.
            </p>
            <h6
              className="repeatp3"
              style={{ fontWeight: "500", color: "#005589" }}
            >
              What’s With The Name?
            </h6>
            <p className="repeatp1">
              In that spirit, we named our company Oshn Brand to give our
              customers a single point of origin, no matter where they want to
              go.
            </p>
          </div>
          <div
            className="col-md-6 col-sm-12"
            style={{ backgroundColor: "#E9F5FF", borderRadius: "10px" }}
          >
            <div className="App">
              <Carousel titles={titles} descriptions={descriptions} />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid margin" id={id}>
        <div className="row">
          <div className="col-md-4 col-sm-12 pt-5 mt-5">
            <h1 style={{ color: "#091B31" }} className="repeath1">
              Our core <span style={{ fontWeight: "900" }}>values</span>
            </h1>
            <p className="repeatp1">
              It takes a lot of different people to make the Oshn Brand
              #OneTeam, but we all have one thing in common: we all show up
              every day ready to DRIVE.
            </p>
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="row d-flex align-items-center">
              <div className="col-sm-1">
                <h1 className="megah1">D</h1>
              </div>
              <div className="col-sm-11">
                <h6 className="repeath6" style={{ color: "#005589" }}>
                  Dedicated
                </h6>
                <p className="repeatp2">
                  We show up every day ready to make an impact.
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-sm-1">
                <h1 className="megah1">R</h1>
              </div>
              <div className="col-sm-11">
                <h6 className="repeath6" style={{ color: "#005589" }}>
                  Risk-Taking
                </h6>
                <p className="repeatp2">
                  Category leaders do things only category leaders can do. Be
                  bold and take big risks.
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-sm-1">
                <h1 className="megah1">I</h1>
              </div>
              <div className="col-sm-11">
                <h6 className="repeath6" style={{ color: "#005589" }}>
                  Inventive
                </h6>
                <p className="repeatp2">
                  Our curiosity and creativity keeps us moving forward, always
                  challenging the status quo.
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-sm-1">
                <h1 className="megah1">V</h1>
              </div>
              <div className="col-sm-11">
                <h6 className="repeath6" style={{ color: "#005589" }}>
                  Valuexd
                </h6>
                <p className="repeatp2">
                  We celebrate our individuality and cheer each other on.
                  Together we are greater than the sum of our parts.
                </p>
              </div>
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-sm-1">
                <h1 className="megah1">E</h1>
              </div>
              <div className="col-sm-11">
                <h6 className="repeath6" style={{ color: "#005589" }}>
                  Energized
                </h6>
                <p className="repeatp2">
                  We bring joy and enthusiasm into everything we do. We make
                  everything we touch better.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid margin"
        style={{ backgroundColor: "#112D52", color: "white" }}
      >
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="repeath1">Who We Are</h1>
            <img
              src={hist1}
              alt="hist"
              style={{ height: "60%", width: "60%", margin: "20px 0" }}
            />
            <p className="repeatp3">
              While a lot has changed in the world lately, one thing hasn’t
              changed: our dedication. This is our Oshn Brand promise.
            </p>
          </div>
        </div>
      </div>
      <div id={id2} className="container-fluid margin text-center">
        <div className="row">
          <div className="col-sm-12">
            <h1 style={{ color: "#112D52" }} className="repeath1">
              We Can’t Wait to Work With You
            </h1>
            <p className="repeatp3 py-3">
              We’re always looking for fun, brilliant, and unique individuals to
              join the best places to work.
            </p>
            <button
              className="why navbuttonn  mt-3"
              onClick={() => scrollToElement(id2)}
            >
              View our openings
            </button>
            <div className="map1">
              {card.map((e, index) => (
                <>
                  <div
                    key={index}
                    style={{
                      height: "148px",
                      width: "200px",
                      margin: "20px 0",
                    }}
                  >
                    <img
                      style={{
                        height: "51px",
                        width: "51px",
                        margin: "10px 0",
                      }}
                      src={e.img}
                      alt="img"
                    />
                    <p
                      style={{
                        height: "64px",
                        width: "100%",
                        fontSize: "15px",
                      }}
                    >
                      {e.p}
                    </p>
                  </div>
                </>
              ))}
            </div>
            <h6
              style={{ fontWeight: "500", color: "#005589" }}
              className="repeatp3"
            >
              We love Everybody
            </h6>
            <p className="repeatp1">
              We believe we do our best and most impactful work when we are a
              diverse, inclusive and equitable organization. We’re proud to
              recruit and hire talented people from a wide variety of
              backgrounds and experiences. We do not discriminate on the basis
              of race, color, national origin, religion, age, sex (including
              pregnancy, sexual orientation, gender identity or transgender
              status), marital status, veteran status, or disability.
            </p>
          </div>
        </div>
      </div>
      <div
        className="container-fluid margin text-center"
        style={{ backgroundColor: "#E9F5FF" }}
      >
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1">
              Backed by <span style={{ fontWeight: "900" }}>the best</span>
            </h1>
            <p className="repeatp3">
              We’re proud to be supported by some of the best investors in the
              game.
            </p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {card2.map((e, index) => (
                <div key={index}>
                  <div
                    style={{
                      height: "116px",
                      width: "116px",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 1px #a5d0f3",
                      border: "none",
                    }}
                  >
                    <img
                      src={e.img}
                      alt="img"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <button className="why navbuttonn  mt-3">
              Visit us on Crunchbase
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "94px", margin: "20px 0", width: "121px" }}
                alt="img"
                src={hist20}
              />
              <img
                style={{ height: "94px", margin: "20px 0", width: "94px" }}
                alt="img"
                src={hist21}
              />
              <img
                style={{ height: "94px", margin: "20px 0", width: "94px" }}
                alt="img"
                src={hist22}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default History;
