import React from "react";
import Layout from "../comp/layout";
import { Link } from "react-router-dom";
import part1 from "../img/part1.webp";
import part2 from "../img/part2.webp";
import part3 from "../img/part3.webp";
import part4 from "../img/part4.webp";
import part5 from "../img/part5.gif";
import part6 from "../img/part6.webp";
// import part8 from "../img/part8.webp";
import part7 from "../img/part7.png";

const Partners = () => {
  return (
    <Layout title={"Partners Oshn Brand"}>
      <div
        className="container-fluid margin"
        style={{ backgroundColor: "#104DB3", color: "white" }}
      >
        <div className="row pb-5">
          <div className="col-md-6 col-sm-12">
            <p style={{ fontSize: "12px" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  backgroundColor: "transparent",
                }}
              >
                HOME
              </Link>
              - PARTNERS
            </p>
            <h1 style={{ fontSize: "48px" }}>
              {" "}
              A Partner in
              <span style={{ fontWeight: "900", color: "#FBAD18" }}>
                Your Success
              </span>{" "}
            </h1>
            <p className="repeatp2">
              Since our founding in 2014, we’ve experienced a continuous upward
              trajectory– and we’re only moving faster. We’d love for you to be
              a part of that journey. Here at Oshn Brand, we ensure that your
              success and ours go hand in hand.
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <img
              src={part1}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12 p-4">
            <div style={{ backgroundColor: "#E9F5FF" }} className="p-5">
              <h6
                className="repeath6"
                style={{ fontWeight: "700", color: "#091B31" }}
              >
                Agency Partners
              </h6>
              <p className="repeatp2 py-3">
                At Oshn Brand, our Agency Partners act as an extension of our
                internal team. From support, training, and ongoing resources,
                we’ll ensure you’re equipped with all the best tools to help
                your B2B clients successfully implement and leverage
                multichannel ABM to increase pipeline and revenue.
              </p>
              <img src={part2} alt="img" className="partbtn" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 p-4">
            <div style={{ backgroundColor: "#E9F5FF" }} className="p-5">
              <h6
                className="repeath6"
                style={{ fontWeight: "700", color: "#091B31" }}
              >
                Technology Partners
              </h6>
              <p className="repeatp2 py-3">
                Does your company offer an innovative solution that Oshn Brand
                customers would love to leverage to further their ABM efforts?
                As a Oshn Brand Technology Partner, you’ll gain access to our
                robust APIs as well as exposure to Oshn Brand customers who are
                just as eager to partner with you as we are.
              </p>
              <img src={part3} alt="img" className="partbtn" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row text-center">
          <div className="col-sm-12">
            <h1 className="repeath1">Oshn Brand Equals ABM for Khronos</h1>
            <img
              src={part4}
              alt="img"
              style={{ maxHeight: "383px", maxWidth: "682px" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath2 px-5">
              <span style={{ color: "#FBAD18" }}>More Scale.</span>{" "}
              <span style={{ color: "#6ABDFD" }}>More Channels.</span>{" "}
              <span style={{ color: "#005589" }}>More Results.</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">Native Multi-channel Engagement ABM</h1>
            <p className="repeatp1 py-5">
              Oshn Brand is the only account-based engagement platform built to
              deliver more pipeline and revenue through multi-channel ABM, which
              means we are focused on making both you, our partners, and your
              customers more successful.
            </p>
            <button className="why navbuttonn  mt-3">Tour the platform</button>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              src={part5}
              style={{ width: "100%", height: "100%" }}
              alt="img"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img src={part6} style={{ width: "100%" }} alt="img" />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">MOVE</h1>
            <p className="repeatp1 py-5">
              Oshn Brand Co-Founder and bestselling author, Sangram Vajre, and
              Oshn Brand Chief Strategy Officer, Bryan Brown, launched MOVE, a
              comprehensive framework and blueprint for companies seeking to
              ramp go-to-market strategies and best position teams for their
              success.
            </p>
            <button className="why navbuttonn  mt-3">Learn more</button>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="repeath1">
              The <span style={{ fontWeight: "900" }}>Oshn Brand</span> Way
            </h1>
            <p className="repeatp3">
              With Oshn Brand, you can take action to create pipeline from
              target accounts, leverage multi-channel engagement to drive more
              results and manage ABM at scale for more impactful marketing.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid margin">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img src={part7} style={{ width: "100%" }} alt="img" />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1 className="repeath1">Why Oshn Brand?</h1>
            <h6 className="repeath4 py-3" style={{ color: "#005589" }}>
              Take Action to Create Pipeline from Your Target Accounts
            </h6>
            <p className="repeatp1">
              Proactively engaging your target accounts allows marketers to
              create pipeline.
            </p>
            <h6 className="repeath4 py-3" style={{ color: "#005589" }}>
              Native Multi-Channel Engagement Drives More Results
            </h6>
            <p className="repeatp1">
              Deliver greater performance with connected account experiences.
            </p>
            <h6 className="repeath4 py-3" style={{ color: "#005589" }}>
              ABM at Scale Means Better Marketing
            </h6>
            <p className="repeatp1">
              Execute personalized campaigns with more relevant content,
              messaging and insight.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid margin">
        <div className="row">
          <div
            className="col-sm-12 text-center john"
            style={{ backgroundColor: "rgb(233,245,255)", padding: "30px" }}
          >
            <p className="johnp1">
              Oshn Brand has product capabilities that provide [our agency] with
              multiple channels to engage our client’s target accounts, as well
              as ample opportunity to grow and develop our client’s ABM
              strategy. The partner team at Oshn Brand goes the extra mile to
              keep us up to date on all things ABM, their evolving roadmap, and
              challenge us to keep leveling up so we can best serve our clients.
              For all of these reasons and more, Oshn Brand is the preferred ABM
              vendor for [our agency practice].
            </p>
          </div>
        </div>
      </div> */}
    </Layout>
  );
};

export default Partners;
