import React from "react";
import Layout from "../comp/layout";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import profession1 from "../img/professionalbg.webp";
import pro2 from "../img/pro2.webp";
import pro3 from "../img/pro3.webp";
import pro5 from "../img/pro4.webp";
import pro6 from "../img/pro5.webp";
import pro7 from "../img/pro6.webp";
import why5 from "../img/why5.webp";
import why6 from "../img/why6.webp";
import why7 from "../img/why7.webp";
import pro4 from "../img/personal1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./why.css";

const Personal = () => {
  const navigate = useNavigate();
  return (
    <Layout title={"Professional-Experience"}>
      <div>
        {" "}
        <div
          className="container-fluid margin"
          style={{ backgroundColor: "#104DB3", color: "white" }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <p style={{ fontSize: "12px" }}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    backgroundColor: "transparent",
                  }}
                >
                  HOME
                </Link>{" "}
                - PROFESSIONAL EXPERIENCE
              </p>
              <h1 style={{ fontSize: "38px" }}>
                A team of experts assembled for you
              </h1>
              <p style={{ fontSize: "18px", fontWeight: "300" }}>
                We’re experts whose goal is to help you find the optimal path to
                value with onboarding, strategy, media, and programmatic
                experts.
              </p>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn mt-5"
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                I Want To Grow
              </button>
            </div>
            <div className="col-md-6 col-sm-12">
              <img
                src={profession1}
                alt="img"
                style={{ height: "80%", width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid margin">
          <div className="row">
            <div className="col-sm-12">
              <h1
                style={{
                  fontSize: "48px",
                  fontWeight: "500",
                  lineHeight: "54px",
                }}
              >
                Positively rated
              </h1>
            </div>
          </div>
          <div className="row text-center d-flex align-items-center">
            <div className="col-md-2 col-sm-12 bl">
              <h1>90%</h1>
              <p>Ad Network Integrations</p>
            </div>
            <div className="col-md-2 col-sm-12 bl">
              <h1>89%</h1>
              <p>Campaign Measurement</p>
            </div>
            <div className="col-md-2 col-sm-12 bl">
              <h1>91%</h1>
              <p>Digital Advertising</p>
            </div>
            <div className="col-md-2 col-sm-12 bl">
              <h1>90%</h1>
              <p>Segmentation</p>
            </div>
            <div className="col-md-2 col-sm-12">
              <h1>87%</h1>
              <p>Buyer Intent Data Integration</p>
            </div>
            <div className="col-md-2 col-sm-12">
              <img
                style={{ height: "100%", width: "100%" }}
                src={pro4}
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid margin">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img
                alt="img"
                src={pro2}
                style={{ height: "80%", width: "100%" }}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <h1 className="whyh">We are here to help drive ROI</h1>
              <p className="whyp">
                We empower your team to drive ROI and support you with a team of
                creative humans, not just AI.
              </p>
              <p className="whyp">
                The marketing world is moving and changing faster than ever
                before. With the right people in your corner, you can achieve
                your goals. No matter where you are starting in your ABM
                journey, we have a team of experts to back you up.
              </p>
              <button
                style={{
                  width: "fit-content",
                  color: "white",
                  padding: "0 10px",
                }}
                className="svg2 navbuttonn"
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid margin">
          <div className="row">
            <div
              className="col-sm-12 text-center john"
              style={{ backgroundColor: "rgb(233,245,255)", padding: "30px" }}
            >
              <p className="johnp1">
                Initially operating as a one-person ABM team, and now a
                two-person ABM team, the Oshn Brand Pro-serve team has acted as
                a true extension of our team. We would not have been able to
                scale our campaigns and enable our sales team as quickly as we
                did if it weren’t for this partnership. It’s been absolutely
                fantastic to work with alongside the experts on the pro-serve
                team.
              </p>
              <img alt="img" src={pro3} />
              <p
                style={{
                  fontSize: "13px",
                  lineHeight: "13px",
                  fontWeight: "700",
                }}
              >
                Jennifer Leaver
              </p>
              <p
                style={{
                  fontSize: "13px",
                  lineHeight: "13px",
                  // fontWeight: "700",
                }}
              >
                Director, Integrated and Account-Based Marketing
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid margin ">
          <h1
            style={{
              fontSize: "48px",
              lineHeight: "54px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Empowering and supporting your team is the name of the game.{" "}
          </h1>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img
                style={{ height: "100%", width: "100%" }}
                src={pro5}
                alt="img"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: "300",
                }}
              >
                Our team becomes an extension of your team. And we have the best
                humans in the industry.
              </p>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faPlus} />
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        lineHeight: "24px",

                        paddingLeft: "5px",
                      }}
                    >
                      An{" "}
                      <strong style={{ fontWeight: "700" }}>
                        onboarding team
                      </strong>{" "}
                      built to get you up and running in less than 30 days
                    </p>
                  </div>
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faPlus} />
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      The industry’s only team of B2B{" "}
                      <strong style={{ fontWeight: "700" }}>
                        digital media managers
                      </strong>{" "}
                      and programmatic advertising experts who maximize spend
                      efficiencys
                    </p>
                  </div>{" "}
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faPlus} />
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        lineHeight: "24px",

                        paddingLeft: "5px",
                      }}
                    >
                      An in-house{" "}
                      <strong style={{ fontWeight: "700" }}>
                        creative team
                      </strong>{" "}
                      at your disposal for your design needs
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faPlus} />
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      A{" "}
                      <strong style={{ fontWeight: "700" }}>
                        data services team
                      </strong>{" "}
                      committed to enriching your data, discovering new accounts
                      and persona, keeping it current, and auditing data quality
                    </p>
                  </div>
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faPlus} />
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      A team of{" "}
                      <strong style={{ fontWeight: "700" }}>
                        GTM strategy consultants
                      </strong>{" "}
                      to work alongside you while you grow and scale your
                      campaigns over time
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid margin text-center"
          style={{ backgroundColor: "#091B31", color: "white" }}
        >
          <div className="row p-5">
            <div className="col-sm-12">
              <h1
                style={{
                  fontSize: "48px",
                  lineHeight: "54px",
                  fontWeight: "500",
                }}
              >
                The support you need, when you need it.
              </h1>
              <p
                className="py-5"
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "300",
                }}
              >
                Marketers are under intense pressure to be more efficient with
                every marketing dollar. Because we’re marketers, we’ve built a
                team of human ABM experts to make it happen.
              </p>
              <button className="why navbuttonn  mt-3">Talk to our team</button>
            </div>
          </div>
        </div>
        <div className="p-5" style={{ backgroundColor: "#E9F5FF" }}>
          <div className="container bg-light py-5">
            <div className="row">
              <div className="col-sm-12 text-center john">
                <p className="johnp1">
                  I’ve now been a Terminus customer at three companies,
                  primarily due to their excellent processes, from sales to
                  customer success. Their team does a great job of supporting
                  customers in starting and ramping up ABM and helping ensure
                  we’re getting the most from the platform.
                </p>
                <img alt="img" src={pro6} />
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "13px",
                    fontWeight: "700",
                  }}
                >
                  Vice President of Marketing
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "13px",
                    // fontWeight: "700",
                  }}
                >
                  Verified user
                </p>
              </div>
            </div>
            <div className="row text-center">
              {" "}
              <div className="col-sm-12 py-5">
                <button className="why navbuttonn  mt-3">
                  Read the full review
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid margin ">
          <h1
            style={{
              fontSize: "48px",
              lineHeight: "54px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Why Terminus?
          </h1>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img
                style={{ height: "100%", width: "100%" }}
                src={pro7}
                alt="img"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <p
                style={{
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: "300",
                }}
              >
                We built a B2B go-to-market platform with marketers in mind.
                Here’s how we’re different than the competition:
              </p>
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    lineHeight: "24px",

                    paddingLeft: "5px",
                  }}
                >
                  <strong style={{ fontWeight: "700" }}>
                    Best-in-class advertising
                  </strong>{" "}
                  with 60% lower CPMs and no fixed rates, 85% lower fraud rate
                  and 90% brand safety
                </p>
              </div>
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    lineHeight: "24px",
                  }}
                >
                  <strong style={{ fontWeight: "700" }}>
                    More ad targeting options
                  </strong>
                  , including title, department, and contextual
                </p>
              </div>{" "}
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    lineHeight: "24px",

                    paddingLeft: "5px",
                  }}
                >
                  Privacy compliant and post-cookie proof advertising
                </p>
              </div>
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    lineHeight: "24px",

                    paddingLeft: "5px",
                  }}
                >
                  The most{" "}
                  <strong style={{ fontWeight: "700" }}>
                    native engagement channels
                  </strong>{" "}
                  of any ABM platform, including conversational marketing, web
                  personalization, LinkedIn, connected TV, audio ads, and more
                </p>
              </div>
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    lineHeight: "24px",

                    paddingLeft: "5px",
                  }}
                >
                  Expert{" "}
                  <strong style={{ fontWeight: "700" }}>
                    digital, strategy, and support teams
                  </strong>{" "}
                  with years of experience supporting ABM efforts
                </p>
              </div>
              <div className="d-flex">
                <FontAwesomeIcon icon={faPlus} />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    lineHeight: "24px",

                    paddingLeft: "5px",
                  }}
                >
                  <strong style={{ fontWeight: "700" }}>
                    {" "}
                    Step-by-step playbooks
                  </strong>{" "}
                  for use cases across the entire customer lifecycle
                </p>
              </div>
              <div>
                <button className="why navbuttonn  mt-3">Why Oshn Brand</button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid margin">
          <div className="row">
            <div className="col-sm-12">
              <h4
                style={{
                  fontSize: "48px",
                  lineHeight: "54px",
                  fontWeight: "500",
                  color: "#091B31",
                  textAlign: "center",
                }}
              >
                Dig a little deeper
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 p-2">
              <div
                style={{
                  backgroundColor: "#E9F5FF",
                  padding: "20px",
                  height: "315px",
                  // width: "330px",
                }}
                className="text-center"
              >
                <img
                  style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                  alt="img"
                  src={why5}
                />
                <h6 style={{ height: "120px", paddingTop: "40px" }}>
                  The Forrester Wave™: B2B Advertising Solutions, Q3 2022
                </h6>
                <button
                  style={{
                    width: "fit-content",
                    color: "white",
                    padding: "0 10px",
                  }}
                  className="svg2 navbuttonn"
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 p-2">
              <div
                style={{
                  backgroundColor: "#E9F5FF",
                  padding: "20px",
                  height: "315px",
                }}
                className="text-center"
              >
                <img
                  style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                  alt="img"
                  src={why6}
                />
                <h6 style={{ height: "120px", paddingTop: "40px" }}>
                  ABM Buyers Guide
                </h6>
                <button
                  style={{
                    width: "fit-content",
                    color: "white",
                    padding: "0 10px",
                  }}
                  className="svg2 navbuttonn"
                >
                  Get the E-Book
                </button>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 p-2">
              <div
                style={{
                  backgroundColor: "#E9F5FF",
                  padding: "20px",
                  height: "315px",
                  // width: "330px",
                }}
                className="text-center"
              >
                <img
                  style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                  alt="img"
                  src={why7}
                />
                <h6 style={{ height: "120px", paddingTop: "40px" }}>
                  ABM Benchmark Report
                </h6>
                <button
                  style={{
                    width: "fit-content",
                    color: "white",
                    padding: "0 10px",
                  }}
                  className="svg2 navbuttonn"
                >
                  Leaern More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Personal;
