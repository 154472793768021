import React, { useState } from "react";
import "./headfoot.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../img/logo.jpg";
import head1 from "../img/head1.webp";
import head2 from "../img/head2.webp";
import { ToastContainer, toast } from "react-toastify";
// import feature1 from "../img/feature1.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./auth/Auth";

// import { scrollHandler } from "../pages/organicawareness/LocalSEO";

const Header = () => {
  const [auth, setAuth] = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://oshnbrand.com:8090/api/admin/login",
        {
          email,
          password,
        }
      );
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        setAuth({
          ...auth,
          admin: res.data.admin,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/dashboard/admin");
        window.location.reload();
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error("Please check your login data.");
      console.error(error);
    }
  };

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };
  return (
    <>
      <ToastContainer />
      <div style={{ backgroundColor: "black", zIndex: "100" }}>
        <div style={{ backgroundColor: "#112D52" }} className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-sm-12  topnav"></div>
            <div className="col-md-8 col-sm-12  topnav">
              <p className="topnav1">Email : info.usa@oshnbrand.com</p>
              <p className="topnav1">Email : info.india@oshnbrand.com</p>
              <p className="topnav1">India : +91 9015937199</p>
              <p className="topnav1">|</p>
              <p className="topnav1">Blog</p>
              <p className="topnav1">|</p>
              <p className="topnav1">help center</p>
              <p className="topnav1">|</p>
              {!auth.admin ? (
                <li style={{ listStyle: "none" }} className="topnav1">
                  <div>
                    <div>
                      <p
                        type="button"
                        className="loginbtn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Login
                      </p>
                      {/* Modal */}
                      <div
                        className="modal fade text-center"
                        style={{ backgroundColor: "transparent" }}
                        id="exampleModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <div
                            className="modal-content"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <div
                              className="modal-body text-dark"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div
                                className="container-fluid"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <form action="" className="form">
                                  <input
                                    required
                                    className="input"
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="E-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                  <input
                                    required
                                    className="input"
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                  <span className="forgot-password">
                                    <a href="/">Forgot Password ?</a>
                                  </span>
                                  <input
                                    className="login-button"
                                    type="submit"
                                    value="Sign In"
                                    onClick={submit}
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <li
                  className="topnav1 dropdown"
                  style={{ listStyle: "none", padding: "0 10px" }}
                >
                  <div className="dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      role="button"
                      id="navbarDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {auth?.admin?.name}
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style={{ zIndex: "100000" }}
                    >
                      <li>
                        <NavLink
                          to={"/dashboard/admin"}
                          className="dropdown-item my-3 bg-light"
                        >
                          Dashboard
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="dropdown-item bg-light text-dark"
                          onClick={logout}
                          to="/"
                        >
                          Logout
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </div>
          </div>
        </div>

        <nav
          className="navbar navbar-expand-lg p-0 m-0"
          style={{ backgroundColor: "black", zIndex: "1000" }}
        >
          <div
            className="container-fluid"
            style={{ backgroundColor: "black", zIndex: "1000" }}
          >
            <img
              onClick={() => {
                navigate("/");
              }}
              src={logo}
              alt="logo"
              style={{ height: "100px", width: "300px", cursor: "pointer" }}
            />

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
              style={{ zIndex: "1000" }}
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-end navul">
                <li className="dropp  py-4">
                  <Link className="navli" to="/">
                    {" "}
                    Why Oshn Brand
                  </Link>
                  <div className="container-fluid p-5 drop">
                    <div className="row ">
                      <div className="col-md-6 px-4">
                        <Link
                          style={{
                            fontSize: "24px",
                            lineHeight: "35px",
                            fontWeight: "700",
                          }}
                        >
                          Why Oshn Brand?
                        </Link>
                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/customer-experience");
                          }}
                        >
                          Customer Experience
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>
                        <p>
                          We understand your challenges and have the expertise
                          to help solve them.
                        </p>
                        <h6
                          className="topnavh3"
                          onClick={() => {
                            navigate("/professional-experience");
                          }}
                        >
                          Professional Services
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h6>
                        <p>
                          ABM expertise on strategy, execution, digital media,
                          and creative.
                        </p>
                        <h6
                          onClick={() => {
                            navigate("/data-service");
                          }}
                          className="topnavh3"
                        >
                          Data Services
                        </h6>
                        <p>
                          We make your data accurate, reliable, and actionable.
                        </p>
                      </div>

                      <div
                        className="col-md-3 px-4 pt-4"
                        style={{ backgroundColor: "lightgray" }}
                      >
                        <h1
                          style={{
                            fontSize: "24px",
                            lineHeight: "35px",
                            fontWeight: "700",
                          }}
                        >
                          Featured
                        </h1>
                        <img className="headimg" src={head1} alt="img" />
                        <h3 className="topnavh3">
                          Oshn Brand Named a Leader Among B2B Advertising
                          Solutions
                        </h3>
                        <p>
                          Independent research firm report states that Oshn
                          Brand “is ahead of the B2B curve in its vision to
                          enable audience targeting and […]
                        </p>
                      </div>
                      <div
                        className="col-md-3 px-4 pt-4"
                        style={{ backgroundColor: "lightgray" }}
                      >
                        <h1 className="topnavh3">
                          See more featured content{" "}
                          <FontAwesomeIcon icon={faCaretRight} />
                        </h1>
                        <img
                          style={{ marginTop: "10px" }}
                          className="headimg"
                          src={head2}
                          alt="img"
                        />
                        <h3 className="topnavh3">
                          Oshn Brand Named a Leader Among B2B Advertising
                          Solutions
                        </h3>
                        <p>
                          Independent research firm report states that Oshn
                          Brand “is ahead of the B2B curve in its vision to
                          enable audience targeting and […]
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="dropp2 py-4">
                  <Link className="navli" to="/">
                    {" "}
                    Solutions & Pricing
                  </Link>
                  <div className="container-fluid py-3 px-5 drop2">
                    <div className="row ">
                      <div className="col-md-9 px-4">
                        <div className="row">
                          <div className="col-sm-4">
                            <h4 style={{ color: "black", fontWeight: "500" }}>
                              Organic Awareness
                            </h4>
                          </div>
                          <div className="col-sm-4">
                            <h4 style={{ color: "black", fontWeight: "500" }}>
                              Paid Media Marketing
                            </h4>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h4 style={{ color: "black", fontWeight: "500" }}>
                              Development
                            </h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <h6 className="topnavh3">
                              <Link to="/local-seo"> Local SEO</Link>
                              <FontAwesomeIcon icon={faCaretRight} />
                            </h6>
                            <p className="navp">
                              We Help You to Maximize Local Visibility to
                              Increase Local Conversions with Effective Local
                              SEO Tactics
                            </p>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              className="topnavh3"
                              onClick={() => {
                                navigate("/meta-ads");
                              }}
                            >
                              Meta Ads
                              <FontAwesomeIcon icon={faCaretRight} />
                            </h6>
                            <p className="navp">
                              Facebook Advertising Pricing: How Much Does It
                              Cost in 2024?
                            </p>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              className="topnavh3"
                              onClick={() => {
                                navigate("/website-development");
                              }}
                            >
                              Website Development
                              <FontAwesomeIcon icon={faCaretRight} />
                            </h6>
                            <p className="navp">
                              Get a Professional Design – Not Just a Pretty Face
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              className="topnavh3"
                              onClick={() => {
                                navigate("/enterprise-seo");
                              }}
                            >
                              Enterprise SEO
                              <FontAwesomeIcon icon={faCaretRight} />
                            </h6>
                            <p className="navp">
                              Grow Your Local Presence With Professional Local
                              SEO Services
                            </p>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              className="topnavh3"
                              onClick={() => {
                                navigate("/google-ppc-ads");
                              }}
                            >
                              Google PPC Ads
                              <FontAwesomeIcon icon={faCaretRight} />
                            </h6>
                            <p className="navp">
                              How Much Do Google Ads Services and Management
                              Cost In India?
                            </p>
                          </div>
                          <div className="col-sm-4">
                            <h6
                              className="topnavh3"
                              onClick={() => {
                                navigate("/app-development");
                              }}
                            >
                              App Development
                              <FontAwesomeIcon icon={faCaretRight} />
                            </h6>
                            <p className="navp">
                              We Develop Feature Rich Mobile Apps Where Creative
                              Chaos Meets Elegant User Experience
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              onClick={() => {
                                navigate("/youtube-seo");
                              }}
                              className="topnavh3"
                            >
                              YouTube SEO
                            </h6>
                            <p className="navp">
                              Upsurge your YouTube Viewership
                            </p>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              onClick={() => {
                                navigate("/linkedin-ads");
                              }}
                              className="topnavh3"
                            >
                              LinkedIn Ads
                            </h6>
                            <p className="navp">
                              Help your sales team find more leads faster with a
                              LinkedIn automation tool that boosts their
                              productivity
                            </p>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              onClick={() => {
                                navigate("/uiux-design");
                              }}
                              className="topnavh3"
                            >
                              UI/UX
                            </h6>
                            <p className="navp">
                              UX/UI Design & Consulting Services
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              onClick={() => {
                                navigate("/online-reputation-management");
                              }}
                              className="topnavh3"
                            >
                              ORM
                            </h6>
                            <p className="navp">
                              Online Reputation Management Company
                            </p>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              onClick={() => {
                                navigate("/social-media-marketing");
                              }}
                              className="topnavh3"
                            >
                              Social Media Marketing
                            </h6>
                            <p className="navp">
                              BEST SOCIAL MEDIA MARKETING PACKAGES IN INDIA
                            </p>
                          </div>
                          <div className="col-sm-4">
                            {" "}
                            <h6
                              onClick={() => {
                                navigate("/software-development");
                              }}
                              className="topnavh3"
                            >
                              Software Development
                            </h6>
                            <p className="navp">
                              We make your data accurate, reliable, and
                              actionable.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-3 px-4 pt-4"
                        style={{ backgroundColor: "lightgray" }}
                      >
                        <h1
                          style={{
                            fontSize: "24px",
                            lineHeight: "35px",
                            fontWeight: "700",
                          }}
                        >
                          Blog
                        </h1>
                        <img className="headimg" src={head1} alt="img" />
                        <h3 className="topnavh3">
                          Oshn Brand Named a Leader Among B2B Advertising
                          Solutions
                        </h3>
                        <p>
                          Independent research firm report states that Oshn
                          Brand “is ahead of the B2B curve in its vision to
                          enable audience targeting and […]
                        </p>
                        <button
                          style={{ width: "fit-content", color: "white" }}
                          className="svg2 navbuttonn mt-3"
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </li>

                {/* <li className="dropp4  py-4">
                  <Link className="navli">Case Study</Link>
                  <div className="drop4 p-3">
                    <h1 className="topnavh3">
                      Resource Hub <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <p className="dropp">
                      Get the latest ABM resources to help propel your
                      go-to-market strategy.
                    </p>
                    <h1 className="topnavh3">
                      Past Webinars <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <p className="dropp">View Oshn Brand webinars on-demand.</p>
                    <h1 className="topnavh3">
                      Oshn Brand Blog <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <p className="dropp">
                      The latest from the go-to-market experts.
                    </p>
                    <h1 className="topnavh3">
                      Customer Stories <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <p className="dropp">
                      Find out how our customers are driving value with Oshn
                      Brand.
                    </p>
                  </div>
                </li> */}
                <li className=" dropp5  py-4">
                  <Link className="navli">Our Story</Link>
                  <div className="drop5 p-3">
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/history");
                      }}
                    >
                      History <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    {/* <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/history");
                      }}
                    >
                      Culture & Values <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      className="topnavh3"
                      onClick={() => {
                        navigate("/history");
                      }}
                    >
                      Careers <FontAwesomeIcon icon={faCaretRight} />
                    </h1> */}
                    <h1
                      onClick={() => {
                        navigate("/leadership");
                      }}
                      className="topnavh3"
                    >
                      Leadership <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                    <h1
                      onClick={() => {
                        navigate("/partner");
                      }}
                      className="topnavh3"
                    >
                      Partners <FontAwesomeIcon icon={faCaretRight} />
                    </h1>

                    <h1
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                      className="topnavh3"
                    >
                      Contact Us <FontAwesomeIcon icon={faCaretRight} />
                    </h1>
                  </div>
                </li>
                <li className="nav-item">
                  <button style={{ border: "none" }} className="navbutton svg">
                    +91 9015-93-7199
                  </button>
                  {/* <p style={{ color: "white" }}></p> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
