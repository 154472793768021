import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../img/logo.jpg";
import axios from "axios";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Select,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const AllQueries = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [landingStartDate, setLandingStartDate] = useState("");
  const [landingEndDate, setLandingEndDate] = useState("");
  const [status] = useState([
    "Not Process",
    "Processing",
    "Completed",
    "Cancel",
  ]);

  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [queries, setQueries] = useState([]);
  const [landing, setLanding] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSideNav2, setShowSideNav2] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };

  const fetchQueries = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/query/getquery"
      );
      setQueries(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLanding = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/landing/getData"
      );
      setLanding(data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeLandingStatus = async (id, value) => {
    try {
      await axios.put(
        `https://oshnbrand.com:8090/api/landing/updatestatus/${id}`,
        {
          status: value,
        }
      );
      fetchLanding(); // Update the landing data after changing status
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (id, value) => {
    try {
      await axios.put(
        `https://oshnbrand.com:8090/api/query/updatestatus/${id}`,
        {
          status: value,
        }
      );
      fetchQueries(); // Update queries after changing status
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQueries();
    fetchLanding();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true);
        setDrawerOpen(false);
      } else {
        setShowSideNav2(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleOnExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(queries);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "MyExcel.xlsx");
  };
  const handleOnExcel2 = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(landing);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "MyExcel.xlsx");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredQueries = landing.filter((query) => {
    if (!landingStartDate && !landingEndDate) return true;
    const queryDate = new Date(query.createdAt);
    const start = landingStartDate
      ? new Date(landingStartDate)
      : new Date(-8640000000000000);
    const end = landingEndDate
      ? new Date(landingEndDate)
      : new Date(8640000000000000);
    return queryDate >= start && queryDate <= end;
  });

  const totalPages = Math.ceil(filteredQueries.length / itemsPerPage);
  const paginatedQueries = filteredQueries.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const ordersPerPage = 10;
  const filteredOrders = queries.filter((query) => {
    if (!startDate && !endDate) return true;
    const queryDate = new Date(query.createdAt);
    const start = startDate ? new Date(startDate) : new Date(-8640000000000000);
    const end = endDate ? new Date(endDate) : new Date(8640000000000000);
    return queryDate >= start && queryDate <= end;
  });

  const totalOrderPages = Math.ceil(filteredOrders.length / ordersPerPage);
  const paginatedOrders = filteredOrders.slice(
    (currentOrderPage - 1) * ordersPerPage,
    currentOrderPage * ordersPerPage
  );

  const handleOrderPageChange = (event, value) => {
    setCurrentOrderPage(value);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img src={logo} alt="logo" className="dashlogo" />
          </Typography>
          <div className="ms-auto">
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faPerson}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <div>
                    ADMIN
                    <br />
                    {auth?.admin?.name}
                  </div>
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    navigate(`/dashboard/admin/profile/${auth?.admin?._id}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Profile
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? 250 : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 250 : 50,
            boxSizing: "border-box",
            transition: "width 0.3s",
            paddingTop: "100px",
          },
        }}
      >
        {showSideNav2 ? <SideNav2 /> : <SideNav />}
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, marginTop: "100px" }}
        className="allsales"
      >
        <h3>All Queries</h3>
        <div className="d-flex justify-content-end gap-3 align-items-center mb-4">
          <TextField
            id="start-date"
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnExcel}
            className="text-capitalize"
          >
            Export Order
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Budget</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedOrders.map((query, index) => (
                <TableRow key={query._id}>
                  <TableCell>
                    {(currentOrderPage - 1) * ordersPerPage + index + 1}
                  </TableCell>
                  <TableCell>{formatDate(query.createdAt)}</TableCell>
                  <TableCell>{query.title}</TableCell>
                  <TableCell>{query.price}</TableCell>
                  <TableCell>{query.name}</TableCell>
                  <TableCell>{query.email}</TableCell>
                  <TableCell>{query.phone}</TableCell>
                  <TableCell>{query.company}</TableCell>
                  <TableCell>{query.budget}</TableCell>
                  {/* <TableCell>{query.status}</TableCell> */}
                  <TableCell>
                    <Select
                      value={query.status}
                      onChange={(e) => handleChange(query._id, e.target.value)}
                    >
                      {status.map((stat) => (
                        <MenuItem key={stat} value={stat}>
                          {stat}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={totalOrderPages}
          page={currentOrderPage}
          onChange={handleOrderPageChange}
          className="mt-3"
        />
        <h3>Landing Queries</h3>
        <div className="d-flex justify-content-end gap-3 align-items-center mb-4">
          <TextField
            id="landing-start-date"
            label="Landing Start Date"
            type="date"
            value={landingStartDate}
            onChange={(e) => setLandingStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="landing-end-date"
            label="Landing End Date"
            type="date"
            value={landingEndDate}
            onChange={(e) => setLandingEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnExcel2}
            className="text-capitalize"
          >
            Export Query
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedQueries.map((query, index) => (
                <TableRow key={query._id}>
                  <TableCell>
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </TableCell>
                  <TableCell>{formatDate(query.createdAt)}</TableCell>
                  <TableCell>{query.firstName}</TableCell>
                  <TableCell>{query.lastName}</TableCell>
                  <TableCell>{query.company}</TableCell>
                  <TableCell>{query.email}</TableCell>
                  <TableCell>{query.businessNumber}</TableCell>
                  <TableCell>{query.country}</TableCell>
                  <TableCell>{query.services}</TableCell>
                  <TableCell>
                    <Select
                      value={query.status}
                      onChange={(e) =>
                        handleChangeLandingStatus(query._id, e.target.value)
                      }
                    >
                      {status.map((stat) => (
                        <MenuItem key={stat} value={stat}>
                          {stat}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          className="mt-3"
        />
      </Box>
    </Box>
  );
};

export default AllQueries;
