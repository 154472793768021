import React from "react";
import Layout from "../comp/layout";

const Terms = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h3
              className="my-2"
              style={{
                fontSize: "25px",
                lineHeight: "30px",
                fontWeight: "600",
                color: "rgb(230,57,63)",
              }}
            >
              TERMS OF SERVICE
            </h3>
            <p className="policyp">
              Access to and use of the SstCloud.com website is provided subject
              to the following terms and conditions which may be amended from
              time to time at our sole discretion.
            </p>
            <p className="policyp">
              PLEASE READ THESE TERMS CAREFULLY AS USE OF THIS SITE CONSTITUTES
              ACCEPTANCE OF THESE TERMS AND CONDITIONS.
            </p>
            <h6 className="mt-5">COPYRIGHT</h6>
            <p className="policyp">
              The information, artwork, text, video, audio or pictures
              (collectively "Information") contained on the SstCloud.com Web
              site are the copyrighted works.
            </p>
            <h6 className="mt-5">USE</h6>
            <p className="policyp">
              Except as otherwise indicated, you may view, copy, print and
              distribute the Information available on the SstCloud Web site
              subject to the following conditions:
            </p>
            <p className="policyp">
              The Information may be used solely for personal, non-commercial or
              educational purposes.
            </p>
            <p className="policyp">
              You may not modify or alter the Information in any way without
              express written permission from SstCloud.
            </p>
            <p className="policyp">
              Any copy of any information or any portion thereof must include
              the copyright notice originally included with the Information.
            </p>
            <p className="policyp">
              Any computer software downloadable or otherwise available from
              SstCloud is licensed subject to the terms of the applicable
              license agreement.
            </p>
            <p className="policyp">
              The Information does not include design or layout of the SstCloud
              Web site, elements of which are protected by law and cannot be
              copied or imitated.
            </p>
            <h6 className="mt-5">WARRANTIES AND DISCLAIMERS</h6>
            <p className="policyp">
              EXCEPT AS EXPRESSLY PROVIDED OTHERWISE HEREIN, ALL INFORMATION ON
              THIS WEB SITE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND,
              EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, OR NON-INFRINGEMENT. SstCloud ASSUMES NO RESPONSIBILITY
              FOR ERRORS OR OMISSIONS IN THE INFORMATION OR OTHER DOCUMENTS
              WHICH ARE REFERENCED BY OR LINKED TO THIS WEB SITE. REFERENCES TO
              CORPORATIONS, THEIR SERVICES AND PRODUCTS, ARE PROVIDED "AS IS"
              WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED.
            </p>
            <p className="policyp">
              IN NO EVENT SHALL SstCloud OR ANY OF ITS AFFILIATES, EMPLOYEES,
              OFFICERS, DIRECTORS, AGENTS OR REPRESENTATIVES BE LIABLE FOR ANY
              SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL OR OTHER DAMAGES OF
              ANY KIND INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM
              DAMAGE TO OR LOSS OF USE OF COMPUTER HARDWARE, DATA OR INFORMATION
              OR LOSS OF PROFITS, WHETHER OR NOT ADVISED OF THE POSSIBILITY OF
              DAMAGE, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN
              CONNECTION WITH THE USE OR PERFORMANCE OF THIS INFORMATION OR
              SERVICE.
            </p>
            <h6 className="mt-5">LINKS TO THIRD PARTY SITES</h6>
            <p className="policyp">
              The SstCloud Web site may contain links to third-party Web sites,
              which are not under the control of SstCloud. SstCloud provides
              these links as a convenience only and SstCloud does intend these
              links to be endorsements or referrals of these linked sites.
              SstCloud does not accept any responsibility for the content or
              uses of these linked Web sites.
            </p>
            <h6 className="mt-5">COPYRIGHT POLICY AND COPYRIGHT AGENT</h6>
            <p className="policyp">
              It is SstCloud's policy to respect the copyright and intellectual
              property rights of others. SstCloud may remove content that
              appears to infringe the copyright or other intellectual property
              rights of others. If you believe that SstCloud or any user of our
              site has infringed your copyright in any material way, please
              notify SstCloud's Abuse Department ( abuse@SstCloud.com ), and
              provide the following information:
            </p>
            <p className="policyp">
              A physical or electronic signature of the person authorized to act
              on behalf of the owner of the copyright interest.
            </p>
            <p className="policyp">
              An identification of the copyrighted work claimed to have been
              infringed.
            </p>
            <p className="policyp">
              An identification of the material that you claim is infringing so
              that we may locate it on the site.
            </p>
            <p className="policyp">
              Your address, telephone number and email address.
            </p>
            <p className="policyp">
              A statement by you that you have a good-faith belief that the
              disputed use is not authorized by the copyright owner, its agent
              or the law.
            </p>
            <p className="policyp">
              A statement by you that the above information in your Notice is
              accurate, made under penalty of perjury, and that you are
              authorized to act on behalf of the owner of the copyright interest
              involved.
            </p>
            <h6 className="mt-5">REFUNDS</h6>
            <ul>
              <li className="policyp">
                Domain Name services are not refundable.
              </li>
              <li className="policyp">
                Payment for Web Hosting services will be refunded for unused
                months in case of cancellation.
              </li>
              <li className="policyp">
                Dedicated Servers, Self-Managed VPS, Firewalls, Backup Plans and
                software licences are not refundable.
              </li>
              <li className="policyp">
                Services need to be cancelled from client area as stated in the
                section below so that our system does not charge your Credit
                Card automatically for the next service period.
              </li>
              <li className="policyp">
                Accounts used for fraudelent purposes or flagged for abuse will
                not be refunded.
              </li>
              <li className="policyp">
                The 30-Day Money Back Guarantee Period is only applicable for
                first-time signups. Subsequent sign-ups are not eligible.
              </li>
            </ul>
            <h6 className="mt-5">CANCELLATIONS</h6>
            <p className="policyp">
              Cancellation requests need to be submitted via our Client Area
            </p>
            <p className="policyp">
              {` Select My Services > Select the account to be cancelled > Click on
              Request Cancellation. Cancellation requests via Support Ticket are
              not accepted.`}
            </p>
            <h6 className="mt-5">LATE FEES</h6>
            <p className="policyp">
              Invoices overdue for over 5 days will be charged a late
              fee/interest upto 5% per month.
            </p>
            <h6 className="mt-5">GOVERNING LAW AND JURISDICTION</h6>
            <p className="policyp">
              The SstCloud Web site (excluding linked sites) is controlled by
              SstCloud.com . All matters relating to your access to, or use of,
              this Website shall be governed by the statutes and laws of India
              and the United States of America, without regard to the conflicts
              of laws principles thereof.
            </p>
            <h6 className="mt-5">TRADEMARK INFORMATION</h6>
            <p className="policyp">
              SstCloud and the SstCloud logo are trademarks of SstCloud. Some of
              these trademarks are registered in certain jurisdictions. All
              other brand and product names are trademarks or registered
              trademarks of their respective companies.
            </p>
            <p className="policyp">
              You may not use any of SstClouds trademarks without express
              written permission of SstCloud. If you are interested in using our
              trademarks, please contact us.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
