import React, { useEffect, useState } from "react";
import "./lets.css";
import logo from "../img/logo.jpg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const PopupForm = () => {
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    option: "",
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsActive(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const togglePopup = () => {
    setIsActive(!isActive);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/quote/newquote",
        formData
      );
      toast.success(response?.data?.message);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Oops! Something went wrong. Please try again later.");
    }
  };

  return (
    <>
      <ToastContainer />
      {isActive && (
        <div id="popup-reg" className={`popup ${isActive ? "active" : ""}`}>
          <div className="popup-content">
            <div className="event-header d-flex justify-content-around align-items-center py-4">
              <img
                style={{ height: "60px", width: "100px" }}
                src={logo}
                alt=""
              />
              <h6>ENQUIRY FROM</h6>
            </div>
            <form id="send" className="send-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Enter name..."
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="name">
                  <i className="fa fa-user"></i>
                </label>
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Enter phone..."
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="phone">
                  <i className="fa fa-phone"></i>
                </label>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Enter email..."
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="mail">
                  <i className="fa fa-envelope"></i>
                </label>
              </div>

              {/* Replaced textarea with select */}
              <div className="form-group">
                <select
                  style={{
                    height: "40px",
                    width: "243px",
                    marginBottom: "10px",
                    border: "none",
                    borderBottom: "1px solid black",
                    // padding: "10px 0",
                    outline: "none",
                  }}
                  name="option"
                  id="option"
                  value={formData.option}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a message</option>
                  <option value="Hosting Server">Hosting Server</option>
                  <option value="Virtual Private Server">
                    Virtual Private Server
                  </option>
                  <option value="Dedicated Server">Dedicated Server</option>
                  <option value="GPU server">GPU Server</option>
                  <option value="Security">Security</option>
                </select>
                <label htmlFor="option" className="txt">
                  <i className="fa fa-commenting"></i>
                </label>
              </div>

              <button type="submit" className="main-btn-rect" value="Send">
                <i className="fa fa-paper-plane"></i>Send
              </button>
            </form>
            <span className="fade-out main-btn-circle" onClick={togglePopup}>
              ╳
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupForm;
