import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "../comp/layout";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Myswiper from "./SlideShow";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Youtubeseo = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const [plan, setPlan] = useState([]);
  const getPlans = async () => {
    try {
      const { data } = await axios.get(
        "https://oshnbrand.com:8090/api/plan/singleplan/Youtube-SEO"
      );
      setPlan(data?.data);
      console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPlans();
    //eslint-disable-next-line
  }, []);
  const iconMap = {
    faCheck: faCheck,
    faXmark: faXmark,
  };

  const handleClose = () => setShow(false);
  const handleShow = (pkg) => {
    setSelectedPackage(pkg);
    setShow(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://oshnbrand.com:8090/api/query/addquery",
        {
          title: "Youtube-SEO",
          price: selectedPackage.price,
          name,
          email,
          phone,
          budget,
          company,
        }
      );

      if (response?.data) {
        toast.success(
          "Your query is very important to us. We will contact you as soon as possible."
        );
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        `There was an error submitting the package: ${
          error.response?.data?.message || error.message
        }`
      );
      console.error("There was an error submitting the package:", error);
    }
  };
  return (
    <Layout title={"Oshn Brand YouTube-SEO"}>
      <ToastContainer />
      <div className="container-fluid m-0 p-0" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="col-sm-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 1440 320"
            >
              <path
                fill="#0099ff"
                fill-opacity="1"
                d="M0,160L80,160C160,160,320,160,480,170.7C640,181,800,203,960,202.7C1120,203,1280,181,1360,170.7L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
              ></path>

              <text
                x="50%"
                y="20%"
                fill="white"
                font-size="48"
                text-anchor="middle"
                alignment-baseline="middle"
              >
                YouTube SEO Packages
              </text>
              <text
                x="50%"
                y="40%"
                fill="white"
                font-size="30"
                text-anchor="middle"
                alignment-baseline="middle"
              >
                Upsurge your YouTube Viewership
              </text>
            </svg>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="repeath1">All YouTube SEO Packages</h1>
            <p className="repeatp1 text-center">
              Hover over or tap the below to learn more about our YouTube SEO
              packages.
            </p>
          </div>
        </div>
        <div className="row">
          {plan?.map((e, index) => (
            <>
              <div className="col-lg-3 col-md-6 col-sm-12 p-3 text-center">
                <div key={index} className="youtubeseo button-container">
                  <div>
                    {" "}
                    <h1 className="button2 repeath1">{e.type}</h1>
                    <div
                      style={{
                        backgroundColor: "#112D52",
                        padding: "10px 0",
                        color: "white",
                      }}
                    >
                      <h1>{e.price}</h1>
                      <p>Per Month</p>
                    </div>
                  </div>

                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Videos Per Month Optimized: {e.p1}</b>
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Onboarding Call & Campaign Setup: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p2]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>YouTube Analytics: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p3]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Custom Title, Descriptions, and Tags: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p4]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>YouTube Channel Optimization: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p5]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Playlist Creation and Optimization: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p6]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Video Description CTA Optimization: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p7]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>

                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Tubebuddy Setup: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p8]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Keywords Tracked: </b>
                    {e.p9}
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Competitor Video Tag Research: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p10]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Target Audience Keyword Research: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p11]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Monthly Report & Review Call: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p12]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>High Retention YouTube Views: </b>
                    {e.p13}
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Video Sharing Across Websites: </b>
                    {e.p14}
                  </p>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>YouTube End Card Optimization: </b>
                    <FontAwesomeIcon
                      icon={iconMap[e.p15]}
                      style={{ fontSize: "22px" }}
                    />
                  </p>

                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    <b>Account Manager & Team Support: </b>
                    {e.p16}
                  </p>
                  <button onClick={() => handleShow(e)} className="ytbtn">
                    I want to grow
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
        {selectedPackage && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div className="login-box">
                <div className="d-flex justify-content-around align-items-center my-2">
                  {" "}
                  <img
                    src="https://oshnbrand.com/static/media/logo.fbd43d01225aac439771.jpg"
                    alt="logo"
                    style={{ height: "50px", width: "130px" }}
                  />
                  <h6>YouTube-SEO</h6>
                </div>
                <form>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label>Name</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label>Phone</label>
                  </div>
                  <div className="user-box">
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <label>Company</label>
                  </div>
                  <div className="user-box">
                    <Form.Group controlId="formBudget" className="mt-3">
                      <Form.Control
                        as="select"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      >
                        <option value="">Select your budget</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <center>
                    <button className="querybtn" onClick={handleSubmit}>
                      SEND
                      <span></span>
                    </button>
                  </center>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="repeath1">Frequently Asked Questions</h1>
            <p className="repeatp3">
              Here are some questions related to app development that our
              clients frequently ask
            </p>
            <div
              class="accordion accordion-flush"
              id="accordionFlushExample"
              style={{ border: "none" }}
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Video Optimization
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Creation of search engine friendly content to help boost
                    your rankings and keyword targeting and hashtag targeting to
                    improve discoverability for your videos and channel.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Onboarding Call & Campaign Setup
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    An account manager that is dedicated to your business growth
                    and knowledgeable with the processes is available to create
                    a custom solution for your account and answer all questions
                    that you may have relating to the needs of your business.
                    Includes email support.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    YouTube Analytics
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    We keep track of your key metrics such as viewership,
                    average view duration and, subscribers to uncover key
                    insights and track progress.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Custom Title Tags, Descriptions, and Tags
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Expert title, distraction and tag setup in order to boost
                    your discoverability on YouTube’s search engine.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    YouTube Description CTA Optimization
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Optimized video descriptions to make it easy for your
                    audience to take action on your product.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  style={{ fontWeight: "700", border: "none" }}
                >
                  <button
                    style={{ fontWeight: "700" }}
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    Playlist Creation and Optimization
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Optimize your YouTube channel playlist to boost viewership,
                    increase watch time, and boost discoverability by helping
                    you appear on related search results.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <Myswiper />
      </div>
    </Layout>
  );
};

export default Youtubeseo;
